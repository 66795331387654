import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Row, Col, Button, Table, Space, Popconfirm, Modal, Form, Input, Select } from 'antd';
import { queryAgentLevelList, delAgentLevel, saveAgentLevel, saveAgentLevelUpgradeRule, queryAgentLevelParm, queryRebateParm, saveRebateRule } from '@/api/agent';
import AgentLevelRuleModalForm from './components/AgentLevelRuleModalForm';
import { ContainerStyle, FormLayOut } from '@/utils/common';

const Option = Select.Option;

const firstValues = { id: '', level: '', name: '', count: '', applyCount: '', discount: '' }

const AgentLevel = () => {

  const [form] = Form.useForm();
  const [rebateForm] = Form.useForm();

  const formRef = useRef();
  const rebateFormRef = useRef();

  const [page, setPage] = useState(0);
  const [size] = useState(10);
  const [agentLevelList, setAgentLevelList] = useState([]);
  const [total, setTotal] = useState(0);
  const [visible, setVisible] = useState(false);
  const [agentLevelModalTitle, setAgentLevelModalTitle] = useState();
  const [ruleVisible, setRuleVisible] = useState(false);
  const [ruleValues, setRuleValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [rebateRuleVisible, setRebateRuleVisible] = useState(false);


  const columns = [
    {
      title: '代理等级',
      dataIndex: 'level',
      width: '150px',
      align: 'center'
    },
    {
      title: '等级名称',
      dataIndex: 'name',
      width: '150px',
      align: 'center'
    },
    {
      title: '代理人数',
      dataIndex: 'count',
      width: '150px',
      align: 'center'
    },
    // {
    //   title: '申请代理人数',
    //   dataIndex: 'applyCount',
    //   width: '150px',
    //   align: 'center'
    // },
    // {
    //   title: '折扣',
    //   dataIndex: 'discount',
    //   width: '150px',
    //   align: 'center'
    // },
    {
      title: '操作',
      width: '150px',
      align: 'center',
      render: (o) => {
        return (
          <Space size="middle" className="operationCloumn">
            <Button size="small" style={{ background: '#FF99CC', color: 'white', border: 'none' }} onClick={() => { addRechargeRebateRule(o) }}>充值返利规则</Button>
            <Button size="small" style={{ background: '#9999CC', color: 'white', border: 'none' }} onClick={() => { addAgentLevelRule(o) }}>代理规则</Button>
            <Button type="primary" size="small" onClick={() => { editAgentLevel(o) }}>编辑</Button>
            <Popconfirm title="确认删除?" onConfirm={() => deleteAgentLevel(o)}>
              <Button type="primary" danger size="small">删除</Button>
            </Popconfirm>
          </Space>
        )
      }
    }
  ];

  const closeModal = () => {
    setVisible(false);
  }

  const getAgentLevelList = useCallback(() => {
    setLoading(true);
    queryAgentLevelList({ page: page, size: size }).then(res => {
      let data = res.data;
      data.forEach(o => {
        o.key = o.id;
      })
      setAgentLevelList(data);
      setTotal(data.length);
      setLoading(false);
    })
  }, [page, size]);

  const addAgentLevel = () => {
    console.log(agentLevelList.length);
    firstValues.level = agentLevelList.length + 1;
    form.setFieldsValue(firstValues);
    setAgentLevelModalTitle('新增代理等级')
    setVisible(true);
  }

  const deleteAgentLevel = (o) => {
    console.log(o);
    delAgentLevel({ id: o.id }).then(res => {
      if (res.code === 0) {
        getAgentLevelList();
      }
    })
  }

  const modalOk = () => {
    let data = formRef.current.getFieldsValue();
    saveAgentLevel(data).then(res => {
      if (res.code === 0) {
        getAgentLevelList();
        setVisible(false);
      }
    })
  }

  const editAgentLevel = (o) => {
    // setInitValues(o);
    form.setFieldsValue(o);
    setVisible(true);
  }

  const addAgentLevelRule = (o) => {
    queryAgentLevelParm({ level: o.level }).then(res => {
      if (res.code === 0) {
        if (res.data) {
          setRuleValues(res.data);
        } else {
          setRuleValues({ id: '', level: o.level, ruleId: '', param1: '' });
        }
        setRuleVisible(true);
      }
    })

  }

  const addRechargeRebateRule = o => {
    queryRebateParm({ level: o.level }).then(res => {
      if (res.code === 0) {
        if (res.data) {
          rebateForm.setFieldsValue(res.data);
        } else {
          rebateForm.setFieldsValue({ id: '', level: o.level, type: '', discount: '' })
        }
        setRebateRuleVisible(true);
      }
    })
  }

  const pageChange = (page) => {
    setPage(page - 1);
  }

  const submitRuleForm = (values) => {
    saveAgentLevelUpgradeRule(values).then(res => {
      if (res.code === 0) {
        setRuleVisible(false);
      }
    })
  }

  const rebateOnChange = e => {
    const { value } = e.target;
    let discount = value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '');
    // discount = parseFloat(discount).toFixed(2);
    if (parseInt(discount) > 100) {
      discount = 100;
    }
    if (parseInt(discount) < 0) {
      discount = 0;
    }
    rebateForm.setFieldsValue({ discount: discount })
  }

  const submitRebate = () => {
    console.log(rebateFormRef.current.getFieldsValue())
    let data = rebateFormRef.current.getFieldsValue();
    saveRebateRule(data).then(res => {
      setRebateRuleVisible(false)
    })
  }

  useEffect(() => {
    getAgentLevelList();
  }, [getAgentLevelList])

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col span={6}><Button type="primary" disabled={agentLevelList.length === 5 ? true : false} onClick={addAgentLevel}>新建等级</Button></Col>
      </Row>
      <div style={ContainerStyle}>
        <Table
          columns={columns}
          dataSource={agentLevelList}
          bordered
          loading={loading}
          pagination={{
            pageSize: size,
            defaultPageSize: size,
            total: total,
            showSizeChanger: false,
            onChange: pageChange
          }}
        />
      </div>
      <Modal forceRender visible={visible} title={agentLevelModalTitle} onOk={modalOk} onCancel={closeModal}>
        <Form {...FormLayOut} ref={formRef} form={form}>
          <Form.Item hidden label="id" name="id">
            <Input />
          </Form.Item>
          <Form.Item label="代理等级" name="level">
            <Select disabled>
              <Option key="opKey1" value={1}>1</Option>
              <Option key="opKey2" value={2}>2</Option>
              <Option key="opKey3" value={3}>3</Option>
              <Option key="opKey4" value={4}>4</Option>
              <Option key="opKey5" value={5}>5</Option>
            </Select>
          </Form.Item>
          <Form.Item label="等级名称" name="name">
            <Input />
          </Form.Item>
        </Form>
      </Modal>

      <AgentLevelRuleModalForm
        ruleVisible={ruleVisible}
        ruleValues={ruleValues}
        closeModal={() => setRuleVisible(false)}
        modalOk={submitRuleForm}
      />

      <Modal
        forceRender
        title="充值返利规则"
        visible={rebateRuleVisible}
        onOk={submitRebate}
        onCancel={() => setRebateRuleVisible(false)}
      >
        <Form {...FormLayOut} ref={rebateFormRef} form={rebateForm}>
          <Form.Item hidden label="id" name="id">
            <Input />
          </Form.Item>
          <Form.Item label="代理等级" name="level">
            <Input disabled />
          </Form.Item>
          <Form.Item label="规则类型" name="type" rules={[{ required: true, message: '请选择规则类型' }]}>
            <Select name="type">
              <Select.Option key="rule1" value={1}>首次充值</Select.Option>
              <Select.Option key="rule2" value={2}>每次充值</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="返利力度" name="discount" rules={[{ required: true, message: '请输入返利力度' }]}>
            <Input onChange={rebateOnChange} suffix="%" />
          </Form.Item>
        </Form>
      </Modal>

    </div>
  )
}

export default AgentLevel;