import { Button, Result } from "antd";
import React from "react";

class Exception404 extends React.Component {
  render() {
    return (
      <Result
        status="403"
        title="403"
        subTitle="抱歉 您没有权限访问"
        extra={
          <Button type="primary" >
            Back Home
          </Button>
        }
      />
    );
  }
}

export default Exception404;
