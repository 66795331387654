import { applyMiddleware, createStore, compose } from 'redux';
import storage from 'redux-persist/es/storage'
import thunk from 'redux-thunk';
import resetEnhancer from './enhancer/reset.js';
import originalReducers from './reduces/index'
import {persistCombineReducers} from 'redux-persist';

// const reducer = combineReducers(originalReducers);
const reducer = persistCombineReducers(
  {
    key: 'root',
    storage,
  }, originalReducers);
const win = window;
const middlewares = [thunk];

if (process.env.NODE_ENV !== 'production') {
  middlewares.push(require('redux-immutable-state-invariant').default());
}

const storeEnhancers = compose(
  resetEnhancer,
  applyMiddleware(...middlewares),
  (win && win.devToolsExtension) ? win.devToolsExtension() : (f) => f
);

const initialState = {};
const store = createStore(reducer, initialState, storeEnhancers);
store._reducers = originalReducers;
export default store;