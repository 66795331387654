import React, { useState, useEffect, useRef } from 'react';
import { Table, Row, Col, Button, Popconfirm, Space, Modal, Form, Input, Select, Upload, Radio, message } from 'antd';
import { queryAdminList, changeAdminStatus, saveAdmin, queryCreatedRole } from '@/api/user';
import { getVertifyCode } from '@/api/agent';
import styles from '@/styles/pages/category.module.scss';
import { oss } from '@/utils/publicPath';
import MaterialLibrary from '@/components/MaterialLibrary';
import { FormLayOut } from '@/utils/common';
import copy from 'copy-to-clipboard';

const User = () => {

  const [roleList, setRoleList] = useState([]);
  const [mainPic, setMainPic] = useState([]);
  const [visible, setVisible] = useState(false);
  const [opType, setOpType] = useState();
  const [loading, setLoading] = useState(false);
  const [roleId] = useState(parseInt(localStorage.getItem('roleId')));

  const [form] = Form.useForm();

  const formRef = useRef();


  const [adminList, setAdminList] = useState([]);

  const columns = [
    {
      title: '头像',
      dataIndex: 'icon',
      align: 'center',
      width: '150px',
      render: icon =>
        icon ?
          <img style={{ width: '85px', height: '85px' }} alt="头像" src={oss + icon} className="tableImage" />
          :
          <img style={{ width: '85px', height: '85px' }} alt="头像" src={oss + 'material/on_error.png'} className="tableImage" />
    },
    {
      title: '用户名',
      dataIndex: 'username',
      align: 'center',
      width: '150px'
    },
    {
      title: '昵称',
      dataIndex: 'nickname',
      align: 'center',
      width: '150px'
    },
    {
      title: '角色',
      dataIndex: 'roleId',
      align: 'center',
      width: '150px'
    },
    {
      title: '电话',
      dataIndex: 'phone',
      align: 'center',
      width: '150px'
    },
    {
      title: '状态',
      align: 'center',
      width: '150px',
      render: o =>
        <Popconfirm title="确认修改状态?" onConfirm={() => changeStatus(o)}>
          <Button size="small" className={o.status === 1 ? styles['hideStatus'] : styles['showStatus']}>
            {o.status === 1 ? '有效' : '无效'}
          </Button>
        </Popconfirm>

    },
    {
      title: '操作',
      align: 'center',
      width: '100px',
      render: (o) => {
        return (
          <Space size="middle" className="operationCloumn">
            <Button type="primary" size="small" onClick={() => { editAdmin(o) }}>编辑</Button>
            <Button type="primary" size="small" danger onClick={() => { generateCode(o) }}>生成验证码</Button>
          </Space>
        )
      }
    }
  ]

  const getAdminList = () => {
    setLoading(true);
    queryAdminList().then(res => {
      if (res.code === 0) {
        let data = res.data;
        data.forEach(o => o.key = o.id);
        setAdminList(data);
        setLoading(false);
      }
    })
  }

  const addAdmin = res => {
    if (!(roleId === 1 || roleId === 2)) {
      message.error({
        content: '您没有权限',
        duration: 5
      })
      return;
    }
    form.resetFields(['username', 'password', 'roleId']);
    form.setFieldsValue({ id: '', username: '', password: '', nickname: '', phone: '', status: 1, roleId: '' });
    setMainPic([]);
    setOpType('add');
    setVisible(true);
  }

  const changeStatus = (o) => {
    if (!(roleId === 1 || roleId === 2)) {
      message.error({
        content: '您没有权限',
        duration: 5
      })
      return;
    }
    console.log(o);
    if (o.status === 1) {
      o.status = 0;
    } else {
      o.status = 1;
    }
    changeAdminStatus(o).then(res => {
      if (res.code === 0) {
        getAdminList();
      }
    })
  }

  const editAdmin = o => {
    if (!(roleId === 1 || roleId === 2)) {
      message.error({
        content: '您没有权限',
        duration: 5
      })
      return;
    }
    o.password = '';
    form.setFieldsValue(o);
    console.log(o);
    setMainPic([{ uid: 0, url: oss + o.icon, filename: o.icon }]);
    setOpType('edit');
    setVisible(true);
  }

  const setMainPicture = values => {
    let data = {};
    let imageList = [];
    values.forEach((o, i) => {
      data = {}
      data.uid = o.id;
      data.url = oss + o.url;
      data.filename = o.url;
      imageList.push(data);
    })
    setMainPic(imageList);
  }

  const onRemove = (file) => {
    console.log(file)
    let imageList = [...mainPic];
    imageList.splice(file.uid, 1);
    setMainPic(imageList);
  }

  const getRoleList = () => {
    queryCreatedRole().then(res => {
      if (res.code === 0) {
        let data = res.data;
        data.forEach(o => o.key = o.id);
        setRoleList(data);
      }
    })
  }

  const submitForm = () => {
    let data = formRef.current.getFieldsValue();
    if (mainPic && mainPic.length !== 0) {
      data.icon = mainPic[0].filename
    }
    console.log(JSON.stringify(data));
    form.validateFields().then(res => {
      if (res.password) {
        saveAdmin(data).then(res => {
          if (res.code === 0) {
            getAdminList();
            setVisible(false);
          }
        })
      }
    }).catch();
  }

  const generateCode = o => {
    let { phone } = o;
    if (phone) {
      getVertifyCode({ phone }).then(res => {
        if (res.code === 0) {
          Modal.confirm({
            title: '验证码：一小时后失效',
            content: `验证码为${res.data}`,
            cancelText: '知道了',
            okText: '复制验证码',
            onOk: () => {
              copy(res.data);
            }
          });
        }
      })
    } else {
      Modal.error({ title: '手机号未录入，无法生成验证码', duration: 5 })
    }
  }

  useEffect(() => {
    getAdminList();
    getRoleList();
  }, [])

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <Button type="primary" onClick={() => addAdmin()}>新增管理员</Button>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={adminList}
        bordered
        pagination={false}
        loading={loading}
      />
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        title="管理员"
        onOk={() => submitForm()}
      >
        <Form
          {...FormLayOut}
          form={form}
          ref={formRef}
          initialValues={{ username: '', password: '', status: 1, gender: 0 }}
        >
          <Row gutter={[24, 24]} justify="left">
            <Col span={24} >
              <Form.Item hidden label="id" name="id">
                <Input />
              </Form.Item>
              <Form.Item
                label="用户名"
                name="username"
                rules={[{ required: true, message: '请输入用户名' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="密码"
                name="password"
                rules={[{ required: true, message: '请输入密码' }]}
              >
                <Input.Password />
              </Form.Item>

              {
                opType === 'edit' ?
                  <Form.Item
                    hidden
                    label="角色"
                    name="roleId"
                    rules={[{ required: true, message: '请选择角色' }]}
                  >
                    <Input disabled />
                  </Form.Item>
                  :
                  <Form.Item
                    label="角色"
                    name="roleId"
                    rules={[{ required: true, message: '请选择角色' }]}
                  >
                    <Select name="roleId">
                      {
                        roleList.map(o =>
                          <Select.Option key={o.id} value={o.id} disabled={o.status === 0 ? true : false}>{o.name}</Select.Option>
                        )
                      }
                    </Select>
                  </Form.Item>
              }

              <Form.Item label="头像">
                <div className={styles['display-flex-align-left']}>
                  {
                    mainPic && mainPic.length !== 0 ?
                      <Upload
                        listType="picture-card"
                        fileList={mainPic}
                        onRemove={onRemove}
                      ></Upload>
                      :
                      ''
                  }
                  {
                    mainPic && mainPic.length === 0 ?
                      <div className={mainPic && mainPic.length !== 0 ? styles['materialContainer'] : ''}>
                        {
                          mainPic && mainPic.length === 0 ?
                            <MaterialLibrary maxChooseNum={1} setImages={setMainPicture} />
                            :
                            ''
                        }

                      </div>
                      :
                      ''
                  }
                </div>
                <span style={{ color: '#bbb', fontSize: '12px' }}>提示：上传图片大小不超过3MB，支持jpg/jpeg/png格式，建议尺寸：50*50,1:1亦可</span>
              </Form.Item>

              <Form.Item
                label="昵称"
                name="nickname"
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="手机号"
                name="phone"
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="账号状态"
                name="status"
              >
                <Radio.Group name="isHomeShow">
                  <Radio value={0}>禁用</Radio>
                  <Radio value={1}>启用</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  )
}

export default User;