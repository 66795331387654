import { agentRequestApi, manageRequestApi, oss } from '@/utils/publicPath'

export const setGlobalConfig = () => {
  return {
    type: 'SET_GLOBAL_CONFIG',
    domain: {
      agentRequestApi,
      manageRequestApi,
      oss
    }
  }
}

export const setToken = (token) => {
  return {
    type: 'SET_TOKEN',
    token
  }
}