/**
 * 加个保留两位小数
 * @param {*} price 数字或字符串都行
 */
export const handlePrice = price => {
  if (price === null) price = 0;
  price += ''
  let priceTxt
  let list = price.split('.')
  let startNum = list[0]
  let endNum
  if (list.length > 1) {
    if (list[1].length === 0) { // 没有小数位
      endNum = '00'
    } else if (list[1].length === 1) { // 一个小数
      endNum = list[1] + '0'
    } else {
      endNum = list[1]
    }
    priceTxt = (startNum + '').replace(/\d{1,3}(?=(\d{3})+$)/g, '$&,') + '.' + endNum
  } else {
    priceTxt = startNum !== 0 ? ((startNum + '').replace(/\d{1,3}(?=(\d{3})+$)/g, '$&,')) : 0
    priceTxt += '.00'
  }
  return priceTxt
}

export const handleNumber = number => {
  return number !== 0 ? ((number + '').replace(/\d{1,3}(?=(\d{3})+$)/g, '$&,')) : 0
}
