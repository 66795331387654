import React, { useState, useCallback, useEffect, useRef } from 'react';
import { queryPersonBalance, updateBalance, withdraw } from '@/api/finance';
import { Table, Button, Form, Input, Select, Modal, Upload } from 'antd';
import { NormalTailLayOut } from '@/utils/common';
import styles from '@/styles/pages/product.module.scss';
import MaterialLibrary from '@/components/MaterialLibrary';
import { oss } from '@/utils/publicPath';

const BalanceDetail = (props) => {
  const [page, setPage] = useState(0);
  const [size] = useState(20);
  const [total, setTotal] = useState(0);
  const [balanceDetailList, setBalanceDetailList] = useState([]);
  const [adjustBalanceVisible, setAdjustBalanceVisible] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checkScreenShotVisible, setCheckScreenShotVisible] = useState();
  const [withdrawVisible, setWithdrawVisible] = useState(false);
  const [currentRebate, setCurrentRebate] = useState(0);

  let { id } = props.match.params;

  const [form] = Form.useForm();
  const [withdrawForm] = Form.useForm();

  const formRef = useRef();
  const withdrawFormRef = useRef();

  const columns = [
    {
      title: '代理姓名',
      dataIndex: 'username',
      align: 'center',
      width: '150px'
    },
    {
      title: '充值代理',
      dataIndex: 'rebateNickname',
      align: 'center',
      width: '150px'
    },
    {
      title: '手机号码',
      dataIndex: 'phone',
      align: 'center',
      width: '230px'
    },
    {
      title: '类型',
      dataIndex: 'type',
      align: 'center',
      width: '160px',
      render: item => item === 1 ? '总部手动增加' : item === -1 ? '总部手动减少' : item === 2 ? '订单退款增加' : item === -2 ? '下单支付减少' : item === 3 ? '充值返利' : item === -3 ? '充值返利退款' : item === 4 ? '平推奖励' : item === -4 ? '平推奖励退款' : item === 5 ? '高推低奖励' : item === -5 ? '高推低奖励退款' : item === -6 ? '提现减少' : '未知状态'
    },
    {
      title: '金额',
      dataIndex: 'money',
      align: 'center',
      width: '200px',
      render: (money, o) => o.type > 0 ?
        <span style={{ color: '#ff4d4f' }}>{money}</span>
        :
        <span>{money}</span>
    },
    {
      title: '当前金额',
      dataIndex: 'currentMoney',
      align: 'center',
      width: '200px'
    },
    {
      title: '可提现金额',
      dataIndex: 'currentRebate',
      align: 'center',
      width: '200px'
    },
    {
      title: '调整时间',
      dataIndex: 'createdDate',
      align: 'center',
      width: '300px'
    },
    {
      title: '操作',
      width: '100px',
      align: 'center',
      render: value =>
        value && value.images && JSON.parse(value.images).length > 0 ?
          <Button style={{ color: 'white', background: '#FF99CC', border: 'none', margin: '0px 10px' }} size="small" onClick={() => checkScreenShot(value)}>查看截图</Button>
          :
          ''
    }
  ];

  const adjustBalance = (o) => {
    let amount = 0;
    if (balanceDetailList.length !== 0) {
      amount = balanceDetailList[0].currentMoney;
    }
    form.setFieldsValue({ id: id, amount: amount, type: '', money: '' });
    setImageList([]);
    setAdjustBalanceVisible(true);
  };

  const getPointDetailList = useCallback(() => {
    setLoading(true);
    queryPersonBalance({ page: page, size: size, userId: id }).then(res => {
      if (res.code === 0) {
        let data = res.data.content;
        data.forEach(o => o.key = o.id);
        setTotal(res.data.totalElements);
        setBalanceDetailList(data);
        if (data.length > 0) {
          setCurrentRebate(data[0].currentRebate);
        }
      }
      setLoading(false);
    })
  }, [page, size, id]);

  const pageChange = (page) => {
    setPage(page - 1);
  }

  const submitAdjust = () => {
    let data = formRef.current.getFieldsValue();
    data.userId = id;
    let imageLists = imageList.map(o => o.filename);
    data.images = encodeURIComponent(JSON.stringify(imageLists));
    updateBalance(data).then(res => {
      if (res.code === 0) {
        getPointDetailList();
        setAdjustBalanceVisible(false);
        setImageList([]);
      }
    })
  }

  const imageListOnRemove = file => {
    let imageLists = [...imageList];
    let index = 0;
    imageLists.forEach((o, i) => {
      if (o.uid === file.uid) {
        index = i;
      }
    })
    imageLists.splice(index, 1);
    setImageList(imageLists);
  }

  const setImageLists = values => {
    let imageLists = [...imageList];
    let imageTemp = {};

    values.forEach((o, i) => {
      imageTemp = {};
      imageTemp.uid = o.id;
      imageTemp.url = oss + o.url;
      imageTemp.filename = o.url;
      imageLists.push(imageTemp);
    })
    console.log(imageLists);
    setImageList(imageLists);
  }

  const checkScreenShot = value => {
    console.log(value);
    let iList = JSON.parse(value.images);
    let imageList = [];
    let imageTemp = {}
    iList.forEach((o, i) => {
      imageTemp = {};
      imageTemp.uid = i;
      imageTemp.url = oss + o;
      imageTemp.filename = o;
      imageList.push(imageTemp);
    })
    setImageList(imageList);
    setCheckScreenShotVisible(true);
  }

  const handleWithDraw = () => {
    withdrawForm.setFieldsValue({ userId: id, currentRebate: currentRebate, money: '', remark: '' })
    setWithdrawVisible(true);
  }

  const submitWithdraw = () => {
    let data = withdrawFormRef.current.getFieldsValue();
    let imageLists = imageList.map(o => o.filename);
    data.images = encodeURIComponent(JSON.stringify(imageLists));
    withdrawForm.validateFields().then(result => {
      withdraw(data).then(res => {
        if (res.code === 0) {
          getPointDetailList();
          setWithdrawVisible(false);
          setImageList([]);
        }
      })
    }).catch()

  }

  useEffect(() => {
    getPointDetailList();
  }, [getPointDetailList])

  return (
    <div>
      <div className="top-area">
        <div className="btn-container">
          <Button type="primary" onClick={() => adjustBalance()}>调整余额</Button>
          <Button style={{ marginLeft: '20px' }} type="primary" onClick={() => handleWithDraw()}>提现</Button>
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={balanceDetailList}
        bordered
        pagination={{ pageSize: size, defaultPageSize: size, total: total, showSizeChanger: false, onChange: pageChange }}
        loading={loading}
      />
      <Modal
        visible={adjustBalanceVisible}
        onCancel={() => { setAdjustBalanceVisible(false); setImageList([]); }}
        onOk={() => submitAdjust()}
        title="调整余额"
        width="587px"
      >
        <Form {...NormalTailLayOut} ref={formRef} form={form}>
          <Form.Item hidden label="id" name="id">
            <Input />
          </Form.Item>
          <Form.Item name="amount" label="当前余额">
            <Input disabled />
          </Form.Item>
          <Form.Item name="type" label="调整类型">
            <Select>
              <Select.Option value={1}>增加</Select.Option>
              <Select.Option value={-1}>减少</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="money" label="调整余额">
            <Input />
          </Form.Item>
          <Form.Item label="截图">
            <div className={styles['display-flex-align-left']}>
              <div style={{ width: imageList.length * 112 + 'px' }}>
                {
                  imageList && imageList.length !== 0 ?
                    <Upload
                      listType="picture-card"
                      fileList={imageList}
                      onRemove={imageListOnRemove}
                    ></Upload>
                    :
                    ''
                }
              </div>
              {
                imageList && imageList.length < 4 ?
                  <div style={{ width: '104px', marginLeft: '0px', marginBottom: '10px' }} className={imageList && imageList.length !== 0 ? styles['materialContainer'] : ''}>
                    <MaterialLibrary maxChooseNum={4 - imageList.length} setImages={setImageLists} />
                  </div>
                  :
                  ''
              }
            </div>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        visible={checkScreenShotVisible}
        onCancel={() => { setImageList([]); setCheckScreenShotVisible(false); }}
        title="查看截图"
        width="587px"
        footer={[]}
      >
        <div className={styles['display-flex-align-left']}>
          <div style={{ width: imageList.length * 112 + 'px' }}>
            {
              imageList && imageList.length !== 0 ?
                <Upload
                  listType="picture-card"
                  fileList={imageList}
                  showUploadList={{ showRemoveIcon: false }}
                ></Upload>
                :
                ''
            }
          </div>
        </div>
      </Modal>
      <Modal
        visible={withdrawVisible}
        onCancel={() => { setWithdrawVisible(false); setImageList([]); }}
        onOk={() => submitWithdraw()}
        title="提现"
        width="667px"
      >
        <Form {...NormalTailLayOut} ref={withdrawFormRef} form={withdrawForm}>
          <Form.Item hidden label="userId" name="userId">
            <Input />
          </Form.Item>
          <Form.Item name="currentRebate" label="可提现金额">
            <Input disabled />
          </Form.Item>
          <Form.Item name="money" label="提现金额" rules={[{
            required: true, validator: (rule, value, callback) => {
              /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/g.test(value) ? callback() : callback('提现金额不合法');
            }
          }]}>
            <Input />
          </Form.Item>
          {/* <Form.Item name="remark" label="备注">
            <Input.TextArea rows={4} />
          </Form.Item> */}
          <Form.Item label="截图">
            <div className={styles['display-flex-align-left']}>
              <div style={{ width: imageList.length * 112 + 'px' }}>
                {
                  imageList && imageList.length !== 0 ?
                    <Upload
                      listType="picture-card"
                      fileList={imageList}
                      onRemove={imageListOnRemove}
                    ></Upload>
                    :
                    ''
                }
              </div>
              {
                imageList && imageList.length < 4 ?
                  <div style={{ width: '104px', marginLeft: '0px', marginBottom: '10px' }} className={imageList && imageList.length !== 0 ? styles['materialContainer'] : ''}>
                    <MaterialLibrary maxChooseNum={4 - imageList.length} setImages={setImageLists} />
                  </div>
                  :
                  ''
              }
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default BalanceDetail;