import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import store from '@/Store.js';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import SiderCustom from './SiderCustom'
import ContentCustom from './ContentCustom'
// import BreadcrumbCustom from '../BreadcrumbCustom'
import HeaderCustom from './HeaderCustom'

import '@/styles/layout/AdminLayout.less'

const { Content } = Layout;

class AdminLayout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      collapsed: false,
      user: store.getState().user,
      app: store.getState().app
    };

    store.subscribe(() => {
      this.setState({
        user: store.getState().user
      })
    })
  }

  UNSAFE_componentWillMount(){
    if (!this.state.app.token) {
      this.props.history.push('/login')
    }
  }

  UNSAFE_componentWillUpdate() {
    if (!this.state.app.token) {
      this.props.history.push('/login')
    }
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {
    const { collapsed, user } = this.state
    const { history } = this.props
    return (
      <Layout 
        className="site-layout"
        style={{
          overflow: 'auto',
          height: '100vh',
        }}
      >
        <SiderCustom
          collapsed={collapsed}
          user={user}
        />
        <Layout>
          <HeaderCustom
            collapsed={collapsed}
            toggle={this.toggle}
            history={history}
          />
          {/* <BreadcrumbCustomer/> */}
          <Content style={{ margin: '16px', overflow: 'initial' }}>
            <ContentCustom user={user}/>
          </Content>
          {/* <Footer style={{ textAlign: 'center' }}>沪ICP备16003223号-1 添晴科技 2019-2029</Footer> */}
        </Layout>
      </Layout>
    )
  }
}
export default connect()(withRouter(AdminLayout));