/** *************** 部署环境 *************** */
// const oss = 'http://test.cerurub.vip/';// cerurub development
// const oss = 'http://test.manage.mytianqing.com/';//cerurub testing
const oss = 'https://manage.cerurub.vip/';//cerurub production
const replaceHttp = true


// const manageRequestApi = 'https://test.api.mytianqing.com/manage/';// cerurub development
const manageRequestApi = 'https://api.cerurub.vip/manage/';// cerurub production
// const manageRequestApi = 'http://192.168.1.25/cerurub/manage/'; // cerurub testing

// const agentRequestApi = 'https://test.api.mytianqing.com/sso/';//cerurub development
const agentRequestApi = 'https://api.cerurub.vip/sso/';//cerurub production
// const agentRequestApi = 'http://192.168.1.25/cerurub/sso/'; // cerurub testing

export {
  manageRequestApi,
  agentRequestApi,
  oss,
  replaceHttp
}
