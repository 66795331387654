import React, { useRef, useState, useEffect, useCallback } from "react";
import { Button, Table, Form, Input, Radio, Switch, Col, Upload, Space, Modal, message, Popconfirm } from 'antd';
import { SmallFormLayOut } from '@/utils/common';
// import MaterialLibrary from '@/components/MaterialLibrary';
import { useHistory } from "react-router-dom";
import { queryProductLevelPriceDetail, addProductLevelPrice } from '@/api/product';
import { oss } from '@/utils/publicPath';
import styles from '@/styles/pages/agent.module.scss';

const AgentPriceEdit = (props) => {
  const history = useHistory();

  const [productPic, setProductPic] = useState();
  const [skuFlag, setSkuFlag] = useState({});
  const [agentDefaultPrice, setAgentDefaultPrice] = useState([]);
  const [skuList, setSkuList] = useState([]);
  const [type, setType] = useState();
  const [chooseCartonVisible, setChooseCartonVisible] = useState(false);
  const [showSkuList, setShowSkuList] = useState([]);

  const { id } = props.match.params;

  const [form] = Form.useForm();

  const formRef = useRef();

  const getAgentPriceDetail = useCallback(() => {
    queryProductLevelPriceDetail({ productId: id }).then(res => {
      if (res.code === 0) {
        let data = res.data;
        form.setFieldsValue(data);
        let agentDefaultPriceTemp = data.levelPriceDTOList;
        setType(data.type);
        setAgentDefaultPrice(agentDefaultPriceTemp);
        let skuListTemp = data.skuPriceDTOList;
        let showSkuListTemp = [];
        skuListTemp.forEach(o => {
          o.key = o.skuId;
          o.type = data.type;
          let skuInfo = '';
          o.specDataArr.forEach((p, i) => {
            if (i !== 0) {
              skuInfo = skuInfo + '+';
            }
            skuInfo = skuInfo + p.value;
          })
          o.skuInfo = skuInfo;
          showSkuListTemp.push(o);
        })
        setShowSkuList(showSkuListTemp);
        setSkuList(skuListTemp.filter(o => o.isSetting === 1));
        setProductPic([{ uid: 0, url: oss + data.image, filename: data.image }]);
        setSkuFlag(data.skuFlag);
      }
    })
  }, [id, form])

  const submitForm = (values) => {
    let skuPriceDTOList = showSkuList.filter(o => o.isSetting === 1);
    let data = {
      image: productPic[0].filename,
      levelPriceDTOList: agentDefaultPrice,
      name: values.name,
      productId: values.productId,
      sellingPrice: values.sellingPrice,
      skuFlag: skuFlag,
      type: type,
      skuPriceDTOList: skuPriceDTOList
    }
    if (values.name && values.productId && values.sellingPrice) {
      console.log(JSON.stringify(data));
      addProductLevelPrice(data).then(res => {
        if (res.code === 0) {
          toAgentPriceList();
        }
      })
    } else {
      message.error({
        content: '请完善信息',
        duration: 5
      })
    }

  };

  // const setMainPic = (values) => {
  //   setProductPic(values);
  // };

  const toAgentPriceList = () => {
    history.goBack(-1);
  }

  const columns = [
    {
      title: '规格信息',
      dataIndex: 'skuInfo',
      align: 'center'
    },
    {
      title: '零售价',
      dataIndex: 'sellingPrice',
      align: 'center'
    },
    {
      title: '自定义价格',
      align: 'center',
      render: (t, o, i) =>
        <div>
          {o.levelPriceDTOList.map((p, inputIndex) =>
            type === 1 ?
              <div key={p.levelName} style={{ margin: '20px 0px' }}>
                {p.levelName}：
                <Input style={{ width: '120px' }} value={p.discountPrice} onChange={(e) => { inputLevelPriceList(e, t, o, i, inputIndex) }} />
              </div>
              :
              <div key={p.levelName} style={{ marginBottom: '20px' }}>
                {p.levelName}：
                <Input style={{ width: '120px' }} value={p.ratio} suffix="%" onChange={(e) => { inputLevelPriceList(e, t, o, i, inputIndex) }} />
              </div>
          )}
        </div>
    },
    {
      title: '奖励价格',
      align: 'center',
      render: (t, o, i) =>
        <div>
          {o.levelPriceDTOList.map((p, inputIndex) =>
            <div key={p.levelName} style={{ margin: '20px 0px' }}>
              {p.levelName}：
                <Input style={{ width: '120px' }} value={p.rebatePrice} onChange={(e) => { inputRebatePriceList(e, t, o, i, inputIndex) }} />
            </div>
          )}
        </div>
    },
    {
      title: '操作',
      align: 'center',
      width: '100px',
      render: o =>
        <Popconfirm title="确认删除?" onConfirm={() => delPriceInfo(o)}>
          <Button type="primary" danger size="small">删除</Button>
        </Popconfirm>
    }
  ];

  const chooseCartonColumns = [
    {
      title: '规格信息',
      dataIndex: 'skuInfo',
      align: 'center'
    },
    {
      title: '零售价',
      dataIndex: 'sellingPrice',
      align: 'center'
    },
    {
      title: '操作',
      align: 'center',
      render: o =>
        <Space size="middle" className="operationCloumn">
          {
            o.isSetting === 1 ?
              <Button type="primary" size="small" disabled onClick={() => { pushShowSkuList(o) }}>选择</Button>
              :
              <Button type="primary" size="small" onClick={() => { pushShowSkuList(o) }}>选择</Button>
          }
        </Space>
    }
  ]

  const changeType = (e) => {
    setType(e.target.value);
  }

  const inputAgentDefaultPrice = (e, i) => {
    e.persist();
    let value = e.target.value;
    let val = value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '');
    let agentDefaultPriceTemp = [...agentDefaultPrice];
    if (type === 1) {
      agentDefaultPriceTemp[i].discountPrice = val;
    } else {
      if (val > 100) {
        val = 100;
      }
      if (val < 0) {
        val = 0
      }
      agentDefaultPriceTemp[i].ratio = val;
    }
    setAgentDefaultPrice(agentDefaultPriceTemp);
  }

  const inputAgentDefaultRebatePrice = (e, i) => {
    e.persist();
    let value = e.target.value;
    let rebatePrice = value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '');
    let agentDefaultPriceTemp = [...agentDefaultPrice];
    agentDefaultPriceTemp[i].rebatePrice = rebatePrice;
    setAgentDefaultPrice(agentDefaultPriceTemp);
  }

  const inputLevelPriceList = (e, t, o, i, inputIndex) => {
    e.persist();
    let value = e.target.value;
    let val = value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '');
    let skuListTemp = [...skuList];
    if (type === 1) {
      skuListTemp[i].levelPriceDTOList[inputIndex].discountPrice = val;
    } else {
      skuListTemp[i].levelPriceDTOList[inputIndex].ratio = val;
    }
    setSkuList(skuListTemp);
  }

  const inputRebatePriceList = (e, t, o, i, inputIndex) => {
    e.persist();
    console.log(2)
    let value = e.target.value;
    let rebatePrice = value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '');
    let skuListTemp = [...skuList];
    skuListTemp[i].levelPriceDTOList[inputIndex].rebatePrice = rebatePrice;
    setSkuList(skuListTemp);
  }

  const changeSkuFlag = (e) => {
    if (e) {
      setSkuFlag(1);
    } else {
      setSkuFlag(2)
    }
  }

  const pushShowSkuList = o => {
    let skuListTemp = [...skuList];
    skuListTemp.push(o);
    setSkuList(skuListTemp);

    let showSkuListTemp = [...showSkuList];
    o.isSetting = 1;
    showSkuListTemp.push(o);
    setShowSkuList(showSkuList);

    // setChooseCartonVisible(false);
  }

  const delPriceInfo = o => {
    let skuListTemp = [...skuList];
    let showSkuListTemp = [...showSkuList];
    skuListTemp.forEach((p, i) => {
      if (o.skuId === p.skuId) {
        skuListTemp.splice(i, 1);
      }
    })
    showSkuListTemp.forEach(p => {
      if (o.skuId === p.skuId) {
        p.isSetting = 0;
        p.levelPriceDTOList.forEach(q => {
          q.discountPrice = 0;
          q.ratio = 0;
        })
      }
    })
    setSkuList(skuListTemp);
    setShowSkuList(showSkuListTemp);
  }

  useEffect(() => {
    if (id) {
      getAgentPriceDetail();
    }
  }, [getAgentPriceDetail, id])

  return (
    <div style={{ backgroundColor: '#fff', padding: '30px' }}>
      <Form {...SmallFormLayOut} ref={formRef} form={form} onFinish={submitForm}>
        <Form.Item hidden label="productId" name="productId">
          <Input />
        </Form.Item>
        <Form.Item label="商品名称" name="name">
          <Input disabled style={{ width: '180px' }} />
        </Form.Item>
        <Form.Item label="商品售价" name="sellingPrice">
          <Input disabled style={{ width: '180px' }} />
        </Form.Item>
        <Form.Item label="商品信息">
          <div className={styles['display-flex-align-left']}>
            {
              productPic && productPic.length !== 0 ?
                <Upload
                  listType="picture-card"
                  fileList={productPic}
                  showUploadList={{ showRemoveIcon: false }}
                ></Upload>
                :
                ''
            }
          </div>
        </Form.Item>
        <Form.Item label="代理默认价格" name="type">
          <Radio.Group name="type" onChange={(e) => changeType(e)}>
            <Radio value={1}>固定价格</Radio>
            <Radio value={2}>折扣比例</Radio>
          </Radio.Group>
        </Form.Item>
        <Col span={12} offset={1} style={{ marginBottom: '20px', display: 'flex' }}>
          <div>
            {
              agentDefaultPrice.map((o, i) =>
                type === 1 ?
                  <div style={{ display: 'flex', marginBottom: '20px' }} key={o.levelName}>
                    <div style={{ width: '100px', height: '32px', lineHeight: '32px', textAlign: 'right' }}>{o.levelName}：</div>
                    <Input style={{ width: '150px' }} value={o.discountPrice} onChange={(e) => inputAgentDefaultPrice(e, i)} />
                  </div>
                  :
                  <div style={{ display: 'flex', marginBottom: '20px' }} key={o.levelName}>
                    <div style={{ width: '100px', height: '32px', lineHeight: '32px', textAlign: 'right' }}>{o.levelName}：</div>
                    <Input style={{ width: '150px' }} value={o.ratio} onChange={(e) => inputAgentDefaultPrice(e, i)} />
                    <div style={{ width: '18px', height: '32px', lineHeight: '32px', textAlign: 'right' }}>%</div>
                  </div>
              )
            }
          </div>
          <div style={{ marginLeft: 75, position: 'relative' }}>

            <span style={{ marginLeft: '140px', fontSize: 14, color: 'rgba(0, 0, 0, 0.85)', position: 'absolute', top: '-49px', left: '-50px' }}>奖励价格:</span>
            {
              agentDefaultPrice.map((o, i) =>
                <div style={{ display: 'flex', marginBottom: '20px' }} key={o.levelName}>
                  <div style={{ width: '100px', height: '32px', lineHeight: '32px', textAlign: 'right' }}>{o.levelName}：</div>
                  <Input style={{ width: '150px' }} value={o.rebatePrice} onChange={(e) => inputAgentDefaultRebatePrice(e, i)} />
                </div>
              )
            }
          </div>
        </Col>

        <Form.Item label="按规格自定义">
          <Switch checkedChildren="开" unCheckedChildren="关" onChange={(e) => { changeSkuFlag(e) }} checked={skuFlag === 1 ? true : false} />
        </Form.Item>
        <Col span={20} offset={2} style={{ marginBottom: 30 }}>
          {
            skuFlag === 1 ?
              <Button onClick={() => setChooseCartonVisible(true)}> + 选择规格</Button>
              :
              ''
          }
        </Col>
        <Col span={20} offset={2}>
          {
            skuFlag === 1 && skuList && skuList.length !== 0 ?
              <Table columns={columns} dataSource={skuList} bordered pagination={false} />
              :
              ''
          }
        </Col>

        <div className={styles['bottom-submit-and-back']}>
          <Button type="primary" style={{ marginRight: '20px' }} htmlType="submit">
            提交
          </Button>
          <Button htmlType="button" onClick={() => { toAgentPriceList() }}>
            返回
          </Button>
        </div>
      </Form>
      <Modal
        visible={chooseCartonVisible}
        title="选择规格"
        onCancel={() => setChooseCartonVisible(false)}
        footer={[
          <Button key="closeModal" size="small" onClick={() => setChooseCartonVisible(false)}>关闭窗口</Button>
        ]}
      >
        <Table columns={chooseCartonColumns} dataSource={showSkuList} bordered pagination={false} />
      </Modal>
    </div>
  )
}

export default AgentPriceEdit;
