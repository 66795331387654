import request from '@/utils/manageRequest';

export const queryMaterialList = (data) => {
  return request({
    url: 'materialLibrary/list',
    params: data,
    method: 'get'
  })
}

export const saveMaterial = (data) => {
  return request({
    url: 'materialLibrary/save',
    data,
    method: 'post'
  })
}

export const delMaterial = (data) => {
  return request({
    url: `materialLibrary/delete?idList=${data.idList}`,
    method: 'delete'
  })
}

export const queryMaterialCategoryList = () => {
  return request({
    url: `materialLibrary/category/list`,
    method: 'get'
  })
}

export const saveMaterialCategory = data => {
  return request({
    url: `materialLibrary/category/save`,
    method: 'post',
    data
  })
}

export const deleteMaterialCategory = id => {
  return request({
    url: `materialLibrary/category/delete?categoryId=${id}`,
    method: 'delete'
  })
}