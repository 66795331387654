import React, { useEffect, useState, useCallback, useRef } from "react";
import { queryProductLevelPriceList, deleteProductLevelPrice, queryProductListByPrice } from '@/api/product';
import { oss } from '@/utils/publicPath';
import { Table, Space, Button, Popconfirm, Row, Col, Modal, message } from "antd";
import { useHistory } from "react-router-dom";
import styles from '@/styles/pages/agent.module.scss';


const AgentPrice = () => {

  let history = useHistory();

  const [page, setPage] = useState(0);
  const [size] = useState(20);
  const [total, setTotal] = useState(0);
  const [productPage, setProductPage] = useState(0);
  const [productSize] = useState(3);
  const [productTotal, setProductTotal] = useState(0);
  const [productPriceList, setProductPriceList] = useState([]);
  const [productAgentPriceList, setProductAgentPriceList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [checkedId, setCheckedId] = useState();

  const pageRef = useRef(0);

  const toAgentPriceEdit = (o = {}, opType) => {
    history.push({ pathname: `/admin/agent/priceEdit/${o.productId}/${opType}` });

  }

  const columns = [
    {
      title: '商品信息',
      width: '400px',
      align: 'center',
      render: value =>
        <div className={styles['product-info-container']}>
          <div className={styles['image-info-container']}>
            <img className="tableImage-no-center" alt="example" src={oss + value.image} />
          </div>
          <div className={styles['other-info-container']}>
            <div>{value.name}</div>
            <div>{value.sellingPrice}</div>
          </div>
        </div>
    },
    {
      title: '代理进货价',
      width: '400px',
      align: 'center',
      render: (value, item) => {
        return (
          value.levelPriceDTOList.map(o =>
            <div key={o.id}>
              {o.levelName} :{item.type === 1 ? o.discountPrice : o.ratio + '%'}
            </div>)
        )
      }
    },
    {
      title: '奖励价格',
      width: '400px',
      align: 'center',
      render: (value) => {
        return (
          value.levelPriceDTOList.map(o =>
            <div key={o.id}>
              {o.levelName} :{o.rebatePrice}
            </div>)
        )
      }
    },
    {
      title: '操作',
      width: '200px',
      align: 'center',
      render: value => {
        return (
          <Space size="middle" className="operationCloumn">
            <Button type="primary" size="small" onClick={() => toAgentPriceEdit(value, 'edit')}>编辑</Button>
            <Popconfirm title="确认删除?" onConfirm={() => delproductLevelList(value)}>
              <Button type="primary" danger size="small">删除</Button>
            </Popconfirm>
          </Space>
        )
      }
    }
  ]

  const productColumns = [
    {
      title: '商品信息',
      align: 'center',
      render: value =>
        <div className={styles['product-info-container']}>
          <div className={styles['image-info-container']}>
            <img className="tableImage-no-center" alt="example" src={oss + value.image} />
          </div>
          <div className={styles['other-info-container']}>
            <div>{value.name}</div>
            <div>{value.price}</div>
          </div>
        </div>
      // {
      //   return (<div style={DisplayFlex}>
      //     <div style={DisplayArea}>
      //       <img alt="example" src={oss + value.image} style={DisplayImages} />
      //     </div>
      //     <div>
      //       <div>{value.name}</div>
      //       <div>{value.price}</div>
      //     </div>
      //   </div>)
      // }
    }
  ]

  const getProductPriceList = useCallback(() => {
    setLoading(true);
    queryProductLevelPriceList({ page: page, size: size }).then(res => {
      if (res.code === 0) {
        let data = res.data.content;
        data.forEach(o => o.key = o.productId);
        setTotal(res.data.totalElements);
        setProductPriceList(data);
        setLoading(false);
      }
    })
  }, [page, size])

  const getProductPriceByPrice = () => {

    //本段代码无用 但请不要注释！{
    let productPageTemp = productPage;
    productPageTemp = pageRef.current;
    setProductPage(productPageTemp);
    //}本段代码无用 但请不要注释！

    queryProductListByPrice({ page: pageRef.current, size: productSize }).then(res => {
      if (res.code === 0) {
        let data = res.data.content;
        data.forEach(o => o.key = o.id);
        setProductTotal(res.data.totalElements);
        setProductAgentPriceList(data);
      }
    })
  }

  const delproductLevelList = (o) => {
    deleteProductLevelPrice({ productId: o.productId }).then(res => {
      if (res.code === 0) {
        getProductPriceList();
      }
    })
  }

  const pageChange = (page) => {
    setPage(page - 1);
  }

  const produtPageChange = (page) => {
    setProductPage(page - 1);
    pageRef.current = page - 1;
    getProductPriceByPrice();
  }

  const addAgentPriceProduct = () => {
    getProductPriceByPrice();
    setVisible(true);
  }

  const modalOnOk = () => {
    if (checkedId) {
      toAgentPriceEdit({ productId: checkedId }, 'add');
    } else {
      message.error({
        content: '请选择数据',
        duration: 5
      })
    }

  }

  const checkeProduct = (checkedIds) => {
    setCheckedId(checkedIds[0]);

  }

  useEffect(() => {
    getProductPriceList();
  }, [getProductPriceList])

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col span={6}><Button type="primary" onClick={addAgentPriceProduct}>新增商品</Button></Col>
      </Row>
      <Table
        columns={columns}
        dataSource={productPriceList}
        bordered
        loading={loading}
        pagination={{
          pageSize: size,
          defaultPageSize: size,
          total: total,
          onChange: pageChange,
          showSizeChanger: false,
          showTotal: total => `总数据：${total} 条`
        }}
      />
      <Modal
        visible={visible}
        onOk={modalOnOk}
        onCancel={() => setVisible(false)}
        title="新增商品"
      >
        <Table
          rowSelection={{ type: 'radio', onChange: checkeProduct }}
          columns={productColumns}
          dataSource={productAgentPriceList}
          bordered
          pagination={{
            pageSize: productSize,
            defaultPageSize: productSize,
            total: productTotal,
            onChange: produtPageChange,
            showSizeChanger: false,
            showTotal: total => `总数据：${total} 条`
          }} />
      </Modal>
    </div>
  )
}

export default AgentPrice;
