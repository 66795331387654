import React from 'react';
import { PlusOutlined } from '@ant-design/icons'
import styles from '@/styles/pages/uploadBtn.module.scss';

const UploadBtn = () => {
  return <div className={styles['btn_container']}>
    <div className={styles['upload_button']}>
      <div>
        <PlusOutlined className={styles['upload_icon']} />
      </div>
      <span className={styles['upload_text']}>点击上传</span>
    </div>
  </div>
}

export default UploadBtn;