import React, { useState, useEffect, useRef } from 'react';
import { Table, Row, Col, Button, Modal, Form, Input, Radio, Space, Tree, message } from 'antd';
import { queryCreatedRole, saveRole, deleteRole, queryRoleDetail } from '@/api/user';
import { FormLayOut } from '@/utils/common';
// import { queryMenuList } from '@/api/menu';

const Role = () => {

  const [roleList, setRoleList] = useState([]);
  const [visible, setVisible] = useState(false);
  const [menuList, setMenuList] = useState([]);
  const [treeValue, setTreeValue] = useState();
  const [treeValueHaveParent, setTreeValueHaveParent] = useState([]);

  const [form] = Form.useForm();
  const formRef = useRef();

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '150px',
      align: 'center'
    },
    {
      title: '角色名',
      dataIndex: 'name',
      align: 'center'
    },
    {
      title: '状态',
      dataIndex: 'status',
      align: 'center',
      width: '150px',
      render: status => status === 1 ? '启用' : '禁用'
    },
    {
      title: '操作',
      align: 'center',
      width: '100px',
      render: (o) => {
        return (
          <Space size="middle" className="operationCloumn">
            <Button type="primary" size="small" onClick={() => { editRole(o) }}>编辑</Button>
            <Button type="primary" size="small" danger onClick={() => { delRole(o) }}>删除</Button>
          </Space>
        )
      }
    }
  ]

  const getRoleList = () => {
    queryCreatedRole().then(res => {
      if (res.code === 0) {
        let data = res.data;
        data.forEach(o => o.key = o.id);
        setRoleList(data);
      }
    })
  }

  // const getMenuList = () => {
  //   queryMenuList().then(res => {
  //     if (res.code === 0) {
  //       let data = res.data;
  //       data.forEach(o => {
  //         o.key = o.id
  //         o.value = o.id;
  //         o.children.forEach(p => {
  //           p.key = p.id;
  //           p.value = p.id;
  //         })
  //       });
  //       setMenuList(data);
  //     }
  //   })
  // }

  const addRole = () => {
    if (!(localStorage.getItem('roleId') === '1' || localStorage.getItem('roleId') === '2')) {
      message.warning({
        content: '您没有权限',
        duration: 5
      })
      return;
    }
    queryRoleDetail({ id: '' }).then(res => {
      if (res.code === 0) {
        let data = res.data;
        let menuListTemp = data.menuNodes;
        menuListTemp.forEach(q => {
          q.key = q.id
          q.value = q.id;
          q.children.forEach(p => {
            p.key = p.id;
            p.value = p.id;
          })
        });
        setMenuList(menuListTemp);
        let treeValueTemp = [];
        data.menuNodes.forEach(p => {
          if (p.checked) {
            treeValueTemp.push(p.id)
          }
          p.children.forEach(q => {
            if (q.checked) {
              treeValueTemp.push(q.id)
            }
          })
        })
        setTreeValue(treeValueTemp);
        form.setFieldsValue({ id: '', name: '', sort: 0, status: 1 });
        setVisible(true);
      }
    })
  }

  const emptyAssignZero = e => {
    e.persist();
    if (!e.target.value) {
      form.setFieldsValue({ sort: 0 });
    }
  }

  const submitForm = () => {
    let data = formRef.current.getFieldsValue();
    // let menuNodes = [];
    // let menuNodesTemp = {};
    // let menuChildren = [];
    // let menuChildrenTemp = {};
    if (treeValueHaveParent.length !== 0) {
      console.log(treeValueHaveParent);
      console.log(menuList);
      //递归
      const fn = (treeData) => {
        let nodeData = [];
        let obj = {};
        treeData.forEach(item => {
          obj = {}
          if (treeValueHaveParent.indexOf(item.id) !== -1) {
            obj.id = item.id;
            obj.children = fn(item.children);
            nodeData.push(obj);
          }
        });
        return nodeData;
      };
      data.menuNodes = fn(menuList);
      // console.log(fn(menuList));
      // console.log(JSON.stringify(fn(menuList)));
    }
    // let list = [...menuList];
    // list.forEach(o=>{
    //   if(o.checked) {

    //   }
    // })
    // menuList.forEach(o => {
    //   if (treeValue.indexOf(o.id) !== -1) {
    //     menuChildrenTemp = {};
    //     menuChildrenTemp.id = o.id;
    //     menuChildrenTemp.parentId = o.parentId;
    //     menuChildren.push(menuChildrenTemp);
    //   }
    //   o.children.forEach(p => {
    //     if (treeValue.indexOf(p.id) !== -1) {
    //       menuChildrenTemp = {};
    //       menuChildrenTemp.id = p.id;
    //       menuChildrenTemp.parentId = p.parentId;
    //       menuChildren.push(menuChildrenTemp);
    //     }
    //     p.children.forEach(q => {
    //       if (treeValue.indexOf(q.id) !== -1) {
    //         menuChildrenTemp = {};
    //         menuChildrenTemp.id = q.id;
    //         menuChildrenTemp.parentId = q.parentId;
    //         menuChildren.push(menuChildrenTemp);
    //       }
    //     })
    //   })
    // })
    // console.log(menuChildren);
    // let packageArr = sortArr(menuChildren, 'parentId');
    // console.log(packageArr);
    // let parentIds = [];
    // if (packageArr.length > 0 && packageArr[0].length > 0) {
    //   parentIds = packageArr.map(o => o.map(p => p.parentId)[0]);
    //   console.log(parentIds);
    //   if (parentIds.indexOf(0) !== -1) {
    //     parentIds.splice(parentIds.indexOf(0), 1);
    //   }
    // }
    // // packageArr[0] 肯定全是父节点
    // if (packageArr.length > 0 && packageArr[0].length > 0) {
    //   let forEachData = packageArr[0];
    //   let newData = []
    //   forEachData.forEach(o => {
    //     if (parentIds.indexOf(o.id) === -1) {
    //       newData.push(o);
    //     }
    //   });
    //   packageArr[0] = newData;
    // }
    // packageArr.forEach(o => {
    //   menuNodesTemp = {};
    //   if (o[0].parentId === 0) {
    //     o.forEach((p, pi) => {
    //       menuNodesTemp = {};
    //       menuNodesTemp.id = o[pi].id;
    //       menuNodesTemp.children = [];
    //       menuNodes.push(menuNodesTemp);
    //     })
    //   } else {
    //     menuNodesTemp.id = o[0].parentId;
    //     menuNodesTemp.children = o.map(p => { return { id: p.id } });
    //     menuNodes.push(menuNodesTemp);
    //   }
    // })
    // data.menuNodes = menuList;
    // console.log(JSON.stringify(data));

    //提交
    saveRole(data).then(res => {
      if (res.code === 0) {
        getRoleList();
        setVisible(false);
      }
    })
  }

  const delRole = (o) => {
    if (!(localStorage.getItem('roleId') === '1' || localStorage.getItem('roleId') === '2')) {
      message.warning({
        content: '您没有权限',
        duration: 5
      })
      return;
    }
    deleteRole({ id: o.id }).then(res => {
      if (res.code === 0) {
        getRoleList();
      }
    })
  }

  const setKeyAndValueForEveryChild = data => {
    if (data) {
      data.forEach(o => {
        o.key = o.id;
        o.value = o.id;
        if (o.children) {
          setKeyAndValueForEveryChild(o.children);
        }
      })
    }
  }

  const editRole = (o) => {
    if (!(localStorage.getItem('roleId') === '1' || localStorage.getItem('roleId') === '2')) {
      message.warning({
        content: '您没有权限',
        duration: 5
      })
      return;
    }

    queryRoleDetail({ id: o.id }).then(res => {
      if (res.code === 0) {
        let data = res.data;
        let menuListTemp = data.menuNodes;
        setKeyAndValueForEveryChild(menuListTemp);
        // menuListTemp.forEach(q => {
        //   q.key = q.id
        //   q.value = q.id;
        //   q.children.forEach(p => {
        //     p.key = p.id;
        //     p.value = p.id;
        //   })
        // });
        setMenuList(menuListTemp);
        let roleDetailTemp = {};
        roleDetailTemp.id = data.id;
        roleDetailTemp.name = data.name;
        roleDetailTemp.sort = data.sort;
        roleDetailTemp.status = data.status;
        const treeValueTemp = [];
        // console.log(a);
        function getTreeValue(data) {
          data.forEach(p => {
            if (p.children.length !== 0) {
              getTreeValue(p.children)
            } else {
              if (p.checked) {
                treeValueTemp.push(p.id);
              }
            }
          })
        }
        getTreeValue(menuListTemp);
        // menuListTemp.forEach(p => {
        //   if (p.checked) {
        //     if (p.children.length === 0) {
        //       treeValueTemp.push(p.id)
        //     }
        //   }
        //   p.children.forEach(q => {
        //     if (q.checked) {
        //       if (q.children.length === 0) {
        //         treeValueTemp.push(q.id)
        //       }
        //       q.children.forEach(r => {
        //         if (r.checked) {
        //           if (r.children.length === 0) {
        //             treeValueTemp.push(r.id)
        //           }
        //         }
        //       })
        //     }
        //   })
        // })
        console.log(treeValueTemp);
        setTreeValue(treeValueTemp);
        form.setFieldsValue(roleDetailTemp);
        setVisible(true);
      }
    })
  }

  const onCheck = (checkedKeys, e) => {
    // console.log(checkedKeys);
    // console.log(e);
    setTreeValueHaveParent(checkedKeys.concat(e.halfCheckedKeys));
    setTreeValue(checkedKeys);
  };

  useEffect(() => {
    getRoleList();
    // getMenuList();
  }, [])

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col span={6}><Button type="primary" onClick={() => addRole()}>新增角色</Button></Col>
      </Row>
      <Table
        columns={columns}
        dataSource={roleList}
        bordered
        pagination={false}
      />
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        title="角色"
        onOk={() => submitForm()}
        bodyStyle={{ height: '600px', overflowY: 'auto' }}
        destroyOnClose={true}
      >
        <Form
          {...FormLayOut}
          form={form}
          ref={formRef}
        >
          <Form.Item hidden label="id" name="id">
            <Input />
          </Form.Item>
          <Form.Item
            label="角色名称"
            name="name"
            rules={[{ required: true, message: '请输入角色名' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="角色排序"
            name="sort"
          >
            <Input onBlur={emptyAssignZero} />
          </Form.Item>

          <Form.Item
            label="角色状态"
            name="status"
          >
            <Radio.Group name="status">
              <Radio value={0}>禁用</Radio>
              <Radio value={1}>启用</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label="菜单"
          >
            <Tree
              checkable
              selectable={false}
              multiple
              // defaultExpandParent={false}
              autoExpandParent={true}
              defaultExpandedKeys={[]}
              treeData={menuList}
              onCheck={onCheck}
              checkedKeys={treeValue}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div >
  )
}

export default Role;