import React, { useEffect, useCallback, useState } from "react";
import { Table, Space, Button, Popconfirm, Input, Upload, Modal } from 'antd';
import { queryProductList, delProduct, queryProductDetail } from '@/api/product';
import { useHistory } from "react-router-dom";
import { DisplayImages } from '@/utils/common';
import { oss } from '@/utils/publicPath';
import { beforeOperate } from '@/utils/beforeOperate';
import styles from '@/styles/pages/product.module.scss';

const Product = () => {

  let history = useHistory();
  const [page, setPage] = useState(0);
  const [size] = useState(20);
  const [jancode, setJancode] = useState('');
  const [productName, setProductName] = useState('');
  const [productListTotal, setProductListTotal] = useState(0);
  const [productList, setProductList] = useState([]);
  const [maxHeight] = useState(document.documentElement.clientHeight - 252);
  const [loading, setLoading] = useState(false);
  const [skuColumn, setSkuColumn] = useState([]);
  const [skuDataSource, setSkuDataSource] = useState([]);
  const [visible, setVisible] = useState(false);
  const [productId, setProductId] = useState();

  const columns = [
    {
      title: '商品主图',
      width: '200px',
      align: 'center',
      render: value =>
        <div className={styles['dispaly-flex']}>
          <div className={styles['imageContainer']}>
            {value ?
              <img alt="example" src={oss + value.image} style={DisplayImages} />
              :
              <img alt="" src={oss + 'material/on_error.png'} style={DisplayImages} />
            }
            {/* <img alt="example" src={oss + value.image} style={DisplayImages} /> */}
          </div>
        </div>
    },
    {
      title: '品牌',
      dataIndex: 'brandName',
      align: 'center',
      width: '200px'
    },
    {
      title: '商品名称',
      dataIndex: 'name',
      align: 'center',
      width: '320px'
    },
    {
      title: '条码',
      dataIndex: 'jancode',
      align: 'center',
      width: '200px'
    },
    {
      title: '商品分类',
      dataIndex: 'categoryNameList',
      align: 'center',
      width: '320px',
      render: categories =>
        <div>
          {
            categories.map(o =>
              <div key={o}>{o}</div>
            )
          }
        </div>
    },
    {
      title: '价格',
      dataIndex: 'price',
      align: 'center',
      width: '150px'
    },
    {
      title: '销售量',
      dataIndex: 'sales',
      align: 'center',
      width: '150px'
    },
    {
      title: '是否热门商品',
      dataIndex: 'isHot',
      align: 'center',
      width: '150px',
      render: isHot => isHot === 0 ? '否' : '是'
    },
    {
      title: '库存',
      dataIndex: 'stock',
      align: 'center',
      width: '150px'
    },
    {
      title: '商品排序',
      dataIndex: 'rank',
      align: 'center',
      width: '120px',
      sorter: o => o.rank,
    },
    {
      title: '状态',
      dataIndex: 'status',
      align: 'center',
      width: '150px',
      render: status => status === 1 ? '显示' : '隐藏'
    },
    {
      title: '操作',
      align: 'center',
      width: '240px',
      fixed: 'right',
      render: (o) => {
        return (
          <Space size="middle" className="operationCloumn">
            {
              o.skuCount > 0 ?
                <Button style={{ color: 'white', background: '#FF99CC', border: 'none' }} size="small" onClick={() => showSkuInfo(o)}>sku信息</Button>
                :
                ''
            }
            <Button type="primary" size="small" onClick={() => { toEditProduct(o) }}>编辑</Button>
            <Popconfirm title="确认删除?" onConfirm={() => { deleteProduct(o) }}>
              <Button type="primary" danger size="small">删除</Button>
            </Popconfirm>
          </Space>
        )
      }
    }
  ];

  const getProductList = useCallback(() => {
    setLoading(true);
    let params = { page: page, size: size, jancode: jancode, name: productName };
    queryProductList(params).then(res => {
      if (res.code === 0) {
        let data = res.data.content;
        data.forEach(o => o.key = o.id);
        setProductListTotal(res.data.totalElements);
        setProductList(data);
        setLoading(false);
      }
    })
  }, [page, size, productName, jancode]);

  const toEditProduct = (o = {}) => {
    let { pathname } = history.location;;
    if (beforeOperate(pathname, '/edit')) return;
    if (o.id) {
      history.push({ pathname: `/admin/product/productEdit/${o.id}`, state: { query: o.id } });
    } else {
      history.push({ pathname: `/admin/product/productEdit` });
    }
  }

  const toEditProductFromSku = () => {
    // console.log(history);
    history.push({ pathname: `/admin/product/productEdit/${productId}`, state: { query: productId } });
  }

  const pageChange = (page) => {
    setPage(page - 1);
  }

  const deleteProduct = (o) => {
    delProduct({ id: o.id }).then(res => {
      if (res.code === 0) {
        getProductList();
      }
    })
  }

  const assignProductName = e => {
    e.persist();
    setProductName(e.target.value);
  }

  const assignJancode = e => {
    e.persist();
    setJancode(e.target.value);
  }

  const showSkuInfo = o => {
    setProductId(o.id);
    queryProductDetail({ id: o.id }).then(res => {
      let productAttributeDTOList = res.data.pmsProductAttributeDTOList;
      productAttributeDTOList.forEach(o => {
        o.checkedAttributeId = o.attributeValueDTOList.filter(p => p.isChecked === 1).map(q => q.attributeValueId)
      })
      let skuDataSourceTemp = res.data.pmsProductSkuList;
      console.log(skuDataSourceTemp);
      packageSkuColumn(productAttributeDTOList);
      // setPmsProductAttributeDTOList(productAttributeDTOList);
      skuDataSourceTemp.forEach(o => {
        let specDataArr = o.specDataArr;
        let specIdArr = o.specIdArr;
        o.key = o.id;
        JSON.parse(o.specDataArr).forEach((p, i) => {
          o['item' + i] = p.value;
        })
        o.image = [{ uid: 0, url: oss + o.image, filename: o.image }];
        o.specDataArr = JSON.parse(specDataArr);
        o.specIdArr = JSON.parse(specIdArr);
      })
      setSkuDataSource(skuDataSourceTemp);
      setVisible(true);
    })
  }

  const packageSkuColumn = (productAttributeDTOList) => {
    let skuColumnTemp = {};
    let skuColumns = [];
    console.log(productAttributeDTOList, 'productAttributeDTOList');
    productAttributeDTOList.forEach((o, i) => {
      if (o && o.attributeId !== undefined) {
        skuColumnTemp = {};
        skuColumnTemp.attributeId = o.attributeId;
        skuColumnTemp.title = o.attributeName;
        skuColumnTemp.dataIndex = 'item' + i;
        skuColumnTemp.width = "150px";
        skuColumnTemp.align = "center"
        skuColumns.push(skuColumnTemp);
        o.key = o.attributeId;
        if (o.attributeValueDTOList) {
          o.attributeValueDTOList.forEach(p => {
            p.key = p.attributeValueId;
            p.label = p.attributeValueName;
            p.value = p.attributeValueId;
          })
        }
      }
    })

    let paramsColumn = [
      {
        title: '货号',
        dataIndex: 'jancode',
        width: '200px',
        align: 'center'
      },
      {
        title: '价格',
        dataIndex: 'price',
        width: '130px',
        align: 'center'
      },
      {
        title: '库存',
        dataIndex: 'stock',
        width: '130px',
        align: 'center'
      },
      {
        title: '图片',
        dataIndex: 'image',
        width: '105px',
        align: 'center',
        render: (t, o, i) =>
          <div>
            <div className={styles['display-flex-align-left']}>
              {
                o.image && o.image.length !== 0 ?
                  <Upload
                    listType="picture-card"
                    fileList={o.image}
                    showUploadList={{ showRemoveIcon: false }}
                  ></Upload>
                  :
                  ''
              }
            </div>
          </div >
      }
    ];
    setSkuColumn(skuColumns.concat(paramsColumn));
  }

  useEffect(() => {
    getProductList();
  }, [getProductList])

  return (
    <div>
      <div className="top-area">
        <div className="btn-container">
          <Button type="primary" onClick={toEditProduct}>新增商品</Button>
        </div>
        <div className="search-container">
          <Input style={{ width: '160px', marginLeft: '20px' }} placeholder="请输入商品名称" onBlur={assignProductName} />
          <Input style={{ width: '160px', marginLeft: '20px' }} placeholder="请输入商品条码" onBlur={assignJancode} />
          <Button type="primary" style={{ width: '80px', marginLeft: '20px' }} onClick={() => getProductList()}>搜索</Button>
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={productList}
        bordered
        scroll={productList.length === 0 ? {} : { x: '1650px', y: maxHeight + 'px' }}
        loading={loading}
        pagination={{
          pageSize: size,
          defaultPageSize: size,
          total: productListTotal,
          onChange: pageChange,
          showSizeChanger: false,
          showTotal: total => `总数据：${total} 条`
        }}
      />
      <Modal
        visible={visible}
        title="SKU信息"
        onCancel={() => setVisible(false)}
        width="1200px"
        footer={[
          <Button key="edit" type="primary" onClick={() => toEditProductFromSku()}>编辑</Button>,
          <Button key="cancel" onClick={() => setVisible(false)}>取消</Button>
        ]}
      >
        <Table columns={skuColumn} dataSource={skuDataSource} bordered pagination={false} scroll={{ y: 480, x: 1000 }} />
      </Modal>
    </div>)
}

export default Product;
