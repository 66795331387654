import React, { useState, useEffect, useRef, useCallback } from 'react';
import { queryMenuList, saveMenu, deleteMenu, queryResourceByMenuId, queryAllResource, saveResourceToMenu, deleteResourceFromMenu } from '@/api/menu';
import { Space, Button, Popconfirm, Table, Modal, Form, Input, Radio, Select } from 'antd';
import { FormLayOut } from '@/utils/common';

const Menu = () => {

  const [menuList, setMenuList] = useState([]);
  const [visible, setVisible] = useState(false);
  const [opLabel, setOpLabel] = useState();
  const [menuId, setMenuId] = useState();
  const [resourceList, setResourceList] = useState([]);
  const [resourceVisible, setResourceVisible] = useState(false);
  const [addResourceVisible, setAddResourceVisible] = useState(false);
  const [allResourceList, setAllResourceList] = useState([]);
  const [btnVisible, setBtnVisible] = useState(false);
  const [subMenuList, setSubMenuList] = useState([]);

  const [form] = Form.useForm();
  const [btnForm] = Form.useForm();
  const [resourceForm] = Form.useForm();
  const formRef = useRef();
  const btnFormRef = useRef();
  const resourceFormRef = useRef();

  const columns = [
    {
      title: '菜单名称',
      dataIndex: 'title',
      width: 150,
      align: 'center'
    },
    {
      title: '菜单路由',
      dataIndex: 'name',
      width: 150,
      align: 'center'
    },
    {
      title: '菜单等级',
      dataIndex: 'level',
      width: 100,
      align: 'center'
    },
    {
      title: '菜单排序',
      dataIndex: 'sort',
      width: 100,
      align: 'center'
    },
    {
      title: '是否隐藏',
      dataIndex: 'hidden',
      width: 100,
      align: 'center',
      render: hidden => hidden === 1 ? '显示' : '隐藏'
    },
    {
      title: '操作',
      width: 200,
      align: 'center',
      render: o =>
        <Space size="middle" className="operationCloumn">
          <Button style={{ color: 'white', background: '#FF99CC', border: 'none' }} size="small" onClick={() => { manageResource(o) }}>资源管理</Button>
          <Button type="primary" size="small" onClick={() => { editMenu(o) }}>编辑</Button>
          <Popconfirm title="确认删除?" onConfirm={() => delMenu(o)}>
            <Button type="primary" size="small" danger>删除</Button>
          </Popconfirm>
        </Space>
    }
  ];

  const resourceColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 100,
      align: 'center'
    },
    {
      title: '资源名称',
      dataIndex: 'name',
      width: 200,
      align: 'center'
    },
    {
      title: '路径',
      dataIndex: 'url',
      width: 200,
      align: 'center'
    },
    {
      title: '操作',
      width: 200,
      align: 'center',
      render: o =>
        <Space size="middle" className="operationCloumn">
          <Popconfirm title="确认删除?" onConfirm={() => { delResourceFromMenu(o) }}>
            <Button type="primary" size="small" danger>删除</Button>
          </Popconfirm>
        </Space>
    }
  ];

  const deleteChildrenWhenNotSub = useCallback((data) => {
    if (data) {
      data.forEach(o => {
        o.key = o.id;
        if (o.children && o.children.length === 0) {
          delete o.children;
        } else {
          deleteChildrenWhenNotSub(o.children);
        }
      })
    }
  }, [])

  const getMenuList = useCallback(() => {
    queryMenuList().then(res => {
      if (res.code === 0) {
        let data = res.data;
        deleteChildrenWhenNotSub(data);

        //构造一级菜单
        let firstMenu = [];
        let preFirstData = [...data];
        preFirstData.forEach(o => {
          firstMenu.push(o);
        })

        //无递归
        // data.forEach(o => {
        //   o.key = o.id
        //   if (o.children.length === 0) {
        //     delete o.children;
        //   } else {
        //     o.children.forEach(p => {
        //       p.key = p.id;
        //       if (p.children.length === 0) {
        //         delete p.children;
        //       } else {
        //         p.children.forEach(q => {
        //           q.key = q.id;
        //           if (q.children.length === 0) {
        //             delete q.children;
        //           }
        //         })
        //       }
        //     })
        //   }
        // });

        //构造二级菜单
        let secondMenu = [];
        let preSecondData = data;
        preSecondData.forEach(o => {
          if (o.children) {
            o.children.forEach(p => {
              secondMenu.push(p);
            })
          }
        })
        setMenuList(firstMenu);
        setSubMenuList(secondMenu);
      }
    })
  }, [deleteChildrenWhenNotSub])

  const getAllResource = () => {
    queryAllResource().then(res => {
      if (res.code === 0) {
        let data = res.data;
        data.forEach(o => o.key = o.id);
        // setResourceList(data);
        setAllResourceList(data);
      }
    })
  }

  const addMenu = () => {
    form.setFieldsValue({ id: '', name: '', title: '', level: '', sort: 0, hidden: 1, parentId: '', component: '', icon: '' });
    setOpLabel('新增');
    setVisible(true);
  }

  const editMenu = o => {
    setOpLabel('编辑');
    if (o.level === 3) {
      btnForm.setFieldsValue(o);
      setBtnVisible(true);
    } else {
      form.setFieldsValue(o);
      setVisible(true);
    }
  }

  const delMenu = (o) => {
    deleteMenu({ id: o.id }).then(res => {
      if (res.code === 0) {
        getMenuList();
      }
    })
  }

  const submitForm = () => {
    let data = formRef.current.getFieldsValue();
    saveMenu(data).then(res => {
      if (res.code === 0) {
        getMenuList();
        setVisible(false);
      }
    })
  }

  const manageResource = o => {
    setMenuId(o.id);
    getResourceByMenuId(o.id);
  }

  const getResourceByMenuId = (id) => {
    queryResourceByMenuId({ id: id }).then(res => {
      if (res.code === 0) {
        let data = res.data;
        data.forEach(o => o.key = o.id);
        setResourceList(data);
        setResourceVisible(true);
      }
    })
  }

  const addResource = () => {
    resourceForm.setFieldsValue({ menuId: menuId, resourceId: '' });
    setAddResourceVisible(true);
  }

  const submitResourceToMenu = () => {
    let data = resourceFormRef.current.getFieldsValue();
    saveResourceToMenu(data).then(res => {
      if (res.code === 0) {
        getResourceByMenuId(menuId);
        setAddResourceVisible(false);
      }
    })
  }

  const delResourceFromMenu = (o) => {
    console.log(o);
    deleteResourceFromMenu({ menuId: menuId, resourceId: o.id }).then(res => {
      if (res.code === 0) {
        getResourceByMenuId(menuId);
      }
    })
  }

  const emptyAssignZero = e => {
    e.persist();
    if (!e.target.value) {
      form.setFieldsValue({ sort: 0 });
    }
  }

  const addButton = () => {
    setOpLabel('新增');
    btnForm.setFieldsValue({ id: '', name: '', title: '', level: '', sort: 0, hidden: 0, parentId: '', component: '', icon: '' });
    setBtnVisible(true);
  }

  const submitBtnForm = () => {
    let data = btnFormRef.current.getFieldsValue();
    saveMenu(data).then(res => {
      if (res.code === 0) {
        getMenuList();
        setBtnVisible(false);
      }
    })
  }

  useEffect(() => {
    getMenuList();
    getAllResource();
  }, [getMenuList])

  return (
    <div>
      <div className="top-area">
        <div className="btn-container">
          <Button type="primary" onClick={() => addMenu()}>新增菜单</Button>
          <Button style={{ marginLeft: '20px' }} type="primary" onClick={() => addButton()}>新增按钮</Button>
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={menuList}
        bordered
        pagination={false}
      />
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={() => submitForm()}
        title={opLabel + '菜单'}
      >
        <Form
          {...FormLayOut}
          form={form}
          ref={formRef}
        >
          <Form.Item
            label="菜单名称"
            name="title"
            rules={[{ required: true, message: '请输入菜单标题' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item hidden label="id" name="id">
            <Input />
          </Form.Item>
          <Form.Item
            label="菜单路由"
            name="name"
            rules={[{ required: true, message: '请输入菜单路由' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="菜单等级"
            name="level"
            rules={[{ required: true, message: '请输入菜单等级' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="菜单排序"
            name="sort"
          >
            <Input onBlur={emptyAssignZero} />
          </Form.Item>

          <Form.Item
            label="组件名称"
            name="component"
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="菜单图标"
            name="icon"
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="父菜单"
            name="parentId"
          >
            <Select name="parentId">
              <Select.Option key={0} value={0}>根菜单</Select.Option>
              {
                menuList.map(o =>
                  <Select.Option key={o.id} value={o.id}>{o.title}</Select.Option>
                )
              }
            </Select>
          </Form.Item>

          <Form.Item
            label="是否隐藏"
            name="hidden"
          >
            <Radio.Group name="hidden">
              <Radio value={0}>隐藏</Radio>
              <Radio value={1}>显示</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        visible={resourceVisible}
        title="菜单下资源"
        onCancel={() => setResourceVisible(false)}
        footer={[
          <Button key="close" onClick={() => setResourceVisible(false)}>关闭窗口</Button>
        ]}
        width="1200px"
      >
        <div className="top-area">
          <div className="btn-container">
            <Button type="primary" onClick={() => addResource()}>新增资源</Button></div>
        </div>
        <Table
          columns={resourceColumns}
          dataSource={resourceList}
          bordered
          pagination={false}
        />
      </Modal>
      <Modal
        visible={addResourceVisible}
        title="添加资源到菜单"
        onCancel={() => setAddResourceVisible(false)}
        onOk={() => submitResourceToMenu()}
      >
        <Form
          {...FormLayOut}
          form={resourceForm}
          ref={resourceFormRef}
        >
          <Form.Item hidden label="menuId" name="menuId">
            <Input />
          </Form.Item>
          <Form.Item
            label="资源名称"
            name="resourceId"
            rules={[{ required: true, message: '请输入资源名称' }]}
          >
            <Select>
              {
                allResourceList.map(o =>
                  <Select.Option key={o.id} value={o.id}>{o.name}</Select.Option>
                )
              }
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        visible={btnVisible}
        onCancel={() => setBtnVisible(false)}
        onOk={() => submitBtnForm()}
        title={opLabel + '按钮'}
      >
        <Form
          {...FormLayOut}
          form={btnForm}
          ref={btnFormRef}
        >
          <Form.Item hidden label="id" name="id">
            <Input />
          </Form.Item>
          <Form.Item
            label="按钮名称"
            name="title"
            rules={[{ required: true, message: '请输入按钮名称' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="按钮路由"
            name="name"
            rules={[{ required: true, message: '请输入按钮路由' }]}
          >
            {/* <Input /> */}
            <Select>
              <Select.Option value="/add">/add</Select.Option>
              <Select.Option value="/edit">/edit</Select.Option>
              <Select.Option value="/detail">/detail</Select.Option>
              <Select.Option value="/ship">/ship</Select.Option>
              <Select.Option value="/refund">/refund</Select.Option>
              <Select.Option value="/sureRefund">/sureRefund</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="按钮等级"
            name="level"
            rules={[{ required: true, message: '请输入按钮等级' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="按钮排序"
            name="sort"
          >
            <Input onBlur={emptyAssignZero} />
          </Form.Item>

          <Form.Item
            hidden
            label="组件名称"
            name="component"
          >
            <Input />
          </Form.Item>

          <Form.Item
            hidden
            label="菜单图标"
            name="icon"
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="父菜单"
            name="parentId"
          >
            <Select name="parentId">
              {
                subMenuList.map(o =>
                  <Select.Option key={o.id} value={o.id}>{o.title}</Select.Option>
                )
              }
            </Select>
          </Form.Item>

          <Form.Item
            label="是否隐藏"
            name="hidden"
          >
            <Radio.Group name="hidden">
              <Radio value={0}>隐藏</Radio>
              <Radio value={1}>显示</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
    </div >
  )
}

export default Menu;