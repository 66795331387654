import request from '@/utils/manageRequest';

export const queryProductList = (data) => {
  return request({
    url: 'product/list',
    method: 'get',
    params: data
  })
}

export const saveProduct = (data) => {
  return request({
    url: 'product/save',
    method: 'post',
    data
  })
}

export const queryProductDetail = (data) => {
  return request({
    url: 'product/detail',
    method: 'get',
    params: data
  })
}

export const updateProductStatus = (data) => {
  return request({
    url: 'product/status',
    method: 'post',
    data
  })
}

export const delProduct = (data) => {
  return request({
    url: 'product/delete',
    method: 'delete',
    params: data
  })
}

export const queryProductSkuList = (data) => {
  return request({
    url: 'product/sku/list',
    method: 'get',
    params: data
  })
}

export const getProductCategoryTree = (data) => {
  return request({
    url: 'productCategory/tree',
    method: 'get',
    params: data
  })
}

export const queryProductAttributeList = (data) => {
  return request({
    url: 'productAttribute/list',
    method: 'get'
  })
}

export const addProductAttribute = (data) => {
  return request({
    url: 'productAttribute/save',
    method: 'post',
    data
  })
}

export const deleteProductAttribute = (data) => {
  return request({
    url: 'productAttribute/delete',
    method: 'delete',
    params: data
  })
}

export const queryProductAttributeValueList = (data) => {
  return request({
    url: 'productAttribute/value/list',
    method: 'get',
    params: data
  })
}

export const addProductAttributeValue = (data) => {
  return request({
    url: 'productAttribute/value/save',
    method: 'post',
    data
  })
}

export const deleteProductAttributeValue = (data) => {
  return request({
    url: 'productAttribute/value/delete',
    method: 'delete',
    params: data
  })
}

export const queryProductLevelPriceList = (data) => {
  return request({
    url: 'productLevelPrice/list',
    method: 'get',
    params: data
  })
}

export const addProductLevelPrice = (data) => {
  return request({
    url: 'productLevelPrice/save',
    method: 'post',
    data
  })
}

export const queryProductLevelPriceDetail = (data) => {
  return request({
    url: 'productLevelPrice/detail',
    method: 'get',
    params: data
  })
}

export const querySkuProductLevelPriceList = (data) => {
  return request({
    url: 'productLevelPrice/sku/list',
    method: 'get',
    params: data
  })
}

export const deleteProductLevelPrice = (data) => {
  return request({
    url: 'productLevelPrice/delete',
    method: 'delete',
    params: data
  })
}

export const queryProductListByPrice = (data) => {
  return request({
    url: 'product/listByPrice',
    method: 'get',
    params: data
  })
}

export const queryCarouselList = (data) => {
  return request({
    url: 'carousel/list',
    method: 'get',
    params: data
  })
}

export const saveCarousel = (data) => {
  return request({
    url: 'carousel/save',
    method: 'post',
    data
  })
}

export const deleteCarousel = (data) => {
  return request({
    url: 'carousel/delete',
    method: 'delete',
    params: data
  })
}