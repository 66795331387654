import React, { Component } from "react"
import { Table } from 'antd'
import { queryUserList } from '@/api/user'
import { getLabel } from '@/utils/tools'
import { oss } from '@/utils/publicPath'

class User extends Component {

  constructor(props) {
    super(props)
    this.state = {
      statusDict: ['无效', '有效'],
      userList: [],
      loading: false,
      columns: [
        {
          title: '头像',
          dataIndex: 'avatar',
          align: 'center',
          width: '120px',
          render: avatar =>
            <div>
              {avatar ?
                <img style={{ width: '85px', height: '85px' }} alt="头像" src={avatar} className="tableImage" />
                :
                // <img alt="" src={oss + 'material/on_error.png'} style={DisplayImages} />
                <img style={{ width: '85px', height: '85px' }} alt="头像" src={oss + 'material/on_error.png'} className="tableImage" />
              }
            </div>
        },
        {
          title: '微信昵称',
          dataIndex: 'nickname',
          align: 'center',
          width: '150px'
        },
        {
          title: '代理账号',
          dataIndex: 'phoneNumber',
          align: 'center',
          width: '150px'
        },
        {
          title: '代理姓名',
          dataIndex: 'username',
          align: 'center',
          width: '150px',
        },
        {
          title: '创建时间',
          dataIndex: 'createdDate',
          align: 'center',
          width: '180px',
        },
        {
          title: '状态',
          dataIndex: 'status',
          align: 'center',
          width: '100px',
          render: (val) => getLabel(val, this.state.statusDict)
        },
        {
          title: '备注',
          dataIndex: 'remark',
          align: 'center',
          width: '250px',
        }
      ]
    }
  }



  componentDidMount() {
    this.queryAdminList()
  }

  queryAdminList = (value = '') => {
    this.setState({ loading: true });
    queryUserList({ page: 0, size: 25, id: '', value: '' }).then(res => {
      this.packUser(res.data.content)
      this.setState({ loading: false });

    })
  }

  packUser = (data) => {
    data.forEach(o => o.key = o.id)
    this.setState({ userList: data })
  }

  render() {
    return (
      <div>

        <Table
          columns={this.state.columns}
          dataSource={this.state.userList}
          bordered
          loading={this.state.loading}
          pagination={{
            showSizeChanger: false,
            showTotal: total => `总数据：${total} 条`
          }}
        />
      </div>
    );
  }
}

export default User;
