import React, { useState, useCallback, useEffect, useRef } from "react"
import { Modal, Button, Card, Checkbox, Upload, Pagination, message } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import styles2 from '@/styles/pages/materialLibrary.module.scss'
import { queryMaterialList, saveMaterial, delMaterial } from '@/api/material'
import { oss, manageRequestApi } from "@/utils/publicPath"
import store from '@/Store';
import { Form, Input, Space, Popconfirm } from 'antd';
import { queryMaterialCategoryList, saveMaterialCategory, deleteMaterialCategory, } from '@/api/material';
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import styles from '@/styles/pages/materialLibraryPro.module.scss';
import '@/styles/pages/modalCustom.less';

const MaterialLibrary = ((props) => {
  // const {visible} = props;
  const { checkedIds = [] } = props;
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(30);
  const [visible, setVisible] = useState();
  const [total, setTotal] = useState(0);
  // const [materialList, setMaterialList] = useState([]);
  const [current, setCurrent] = useState(1);
  const [checkedImageIds, setCheckedImageIds] = useState(checkedIds && checkedIds.length > 0 ? checkedIds : []);
  const [btnLoading, setBtnLoading] = useState(false);
  const { maxChooseNum, setImages } = props;

  const closeModal = () => {
    setVisible(false);
    // setCurrent(1);
    // if (checkedImageIds.length !== 0) {
    //   setImages(checkedImageIds);
    // }
  }

  // const cancelChecked = () => {
  //   const checkedList = [...checkedImageIds];
  //   checkedList.splice(0, checkedList.length);
  //   setCheckedImageIds(checkedList);
  // }

  const bodyStyle = { padding: '0' }

  // const getMaterialList = useCallback((page, size) => {
  //   queryMaterialList({ page: page, size: size }).then(res => {
  //     if (res.code === 0) {
  //       setTotal(res.data.totalElements);
  //       let data = res.data.content;
  //       data.forEach(o => {
  //         o.key = o.id;
  //         o.checked = false;
  //       });
  //       setMaterialList(data);
  //     }
  //   })
  // }, [])

  // const queryAllMaterialList = useCallback(() => {
  //   queryMaterialList().then(res => {
  //     if (res.code === 0) {
  //       setTotal(res.data.totalElements);
  //       let data = res.data.content;
  //       data.forEach(o => {
  //         o.key = o.id;
  //         o.checked = false;
  //       });
  //       setMaterialList(data);
  //     }
  //   })
  // }, [])

  // useEffect(() => {
  //   queryAllMaterialList();
  // }, [queryAllMaterialList])

  // const checkOneImage = (e) => {
  //   const checkedList = [...checkedImageIds];
  //   if (checkedList.indexOf(e) >= 0) {
  //     checkedList.splice(checkedList.indexOf(e), 1);
  //   } else {
  //     if (checkedList.length >= maxChooseNum) {
  //       message.error('最多仅可以选择' + maxChooseNum + '张图片');
  //     } else {
  //       checkedList.push(e);
  //     }
  //   }
  //   setCheckedImageIds(checkedList);
  // }

  // const uploadProps = {
  //   action: manageRequestApi + "oss/material",
  //   withCredentials: true,
  //   showUploadList: false,
  //   headers: { Authorization: store.getState().app.tokenHead + store.getState().app.token },
  //   onChange: (info) => {
  //     const event = info.event;
  //     if (event) {
  //       setBtnLoading(true);
  //     }
  //     if (info.file.status === 'done') {
  //       setCurrent(1);
  //       setBtnLoading(false);
  //       console.log(info);
  //       saveMaterial({ name: info.file.name, url: info.file.response.data }).then(res => {
  //         if (res.code === 0) getMaterialList(page, size);
  //       })
  //     } else if (info.file.status === 'error') {
  //       console.log('error')
  //     }
  //   },
  //   beforeUpload: (file) => {
  //     if (file.size > 3 * 1024 * 1024) {
  //       message.error({ content: '上传图片大小不超过3M,请重新选择图片', duration: 5 });
  //       return false;
  //     }
  //   }
  // }

  // const pageChange = (page) => {
  //   setCurrent(page);
  //   getMaterialList(page - 1, size);
  // }

  const openModal = () => {
    setCheckedImageIds([]);
    // getMaterialList(page, size);
    setVisible(true);
  }
  const [editCategoryVisible, setEditCategoryVisible] = useState(false);
  const [categoryOpType, setCategoryOpType] = useState();
  const [materialCategoryList, setMaterialCategoryList] = useState([]);
  const [imageList, setImageList] = useState([]);
  const [categoryId, setCategoryId] = useState(0);
  // const [current, setCurrent] = useState(1);
  // const [page, setPage] = useState(0);
  // const [size, setSize] = useState(30);
  // const [total, setTotal] = useState();
  // const [allImageCount, setAllImageCount] = useState(0);
  // const [btnLoading, setBtnLoading] = useState(false);
  // const [checkedImageIds, setCheckedImageIds] = useState([]);
  const categoryFormRef = useRef();
  const [categoryForm] = Form.useForm();

  const uploadProps = {
    action: `${manageRequestApi}oss/material`,
    withCredentials: true,
    showUploadList: false,
    multiple: true,
    headers: { Authorization: store.getState().app.tokenHead + store.getState().app.token },
    onChange: (info) => {
      const event = info.event;
      if (event) {
        setBtnLoading(true);
      }
      if (info.file.status === 'done') {
        setCurrent(1);
        setBtnLoading(false);
        saveMaterial({ name: info.file.name, url: info.file.response.data, categoryId: categoryId }).then(res => {
          if (res.code === 0) {
            getImageList();
            getCategoryList();
          }
        })
      } else if (info.file.status === 'error') {
        console.log('error')
      }
    },
    beforeUpload: (file) => {
      if (file.size > 3 * 1024 * 1024) {
        message.error({ content: '上传图片大小不超过3M,请重新选择图片', duration: 5 });
        return false;
      }
    }
  }

  // const getALlImagesCount = useCallback(() => {
  //   queryMaterialList().then(res => {
  //     if (res.code === 0) setAllImageCount(res.data.totalElements);
  //   })
  // }, [])

  const getCategoryList = useCallback(() => {
    queryMaterialCategoryList().then(res => {
      if (res.code === 0) {
        let data = res.data;
        data.forEach(o => o.key = o.id);
        setMaterialCategoryList(data)
        if (data.length > 0 && categoryId === 0) {
          setCategoryId(data[0].id);
        }
      }
    })
  }, [categoryId])

  const getImageList = useCallback(() => {
    queryMaterialList({ page, size, categoryId }).then(res => {
      if (res.code === 0) {
        let data = res.data.content;
        data.forEach(o => o.key = o.id);
        setTotal(res.data.totalElements);
        setImageList(data);
      }
    })
  }, [page, size, categoryId])

  const menuClick = data => {
    setPage(0)
    setCurrent(1);
    setCategoryId(data.id);
  }

  const addCategory = () => {
    setEditCategoryVisible(true);
    setCategoryOpType('add');
    categoryForm.setFieldsValue({ id: '', name: '' });
    if (categoryId === 0) setCategoryId(0);
  }

  const submitCategoryForm = () => {
    let data = categoryFormRef.current.getFieldsValue();
    saveMaterialCategory(data).then(res => {
      if (res.code === 0) {
        getCategoryList();
        setEditCategoryVisible(false);
      }
    })
  }

  const editCategory = (e, obj) => {
    e.stopPropagation();//阻止冒泡
    categoryForm.setFieldsValue({ id: obj.id, name: obj.name });
    setCategoryId(obj.id);
    setEditCategoryVisible(true);
  }

  const delCategory = (e, obj) => {
    e.stopPropagation();//阻止冒泡
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      content: `确认删除分类 ${obj.name} 吗？`,
      okType: 'danger',
      centered: true,
      onOk() {
        deleteMaterialCategory(obj.id).then(res => {
          if (res.code === 0) {
            getCategoryList();
            setCategoryId(0);
          }
        })
      }
    });
  }

  const pageChange = page => {
    setPage(page - 1);
    setCurrent(page);
  }

  const pageSizeChange = (current, size) => {
    setCurrent(1);
    setPage(0);
    setSize(size);
  }

  const checkOneImage = e => {
    //如果初始是选中的 不执行任何操作
    if (checkedIds.indexOf(e.id) !== -1) return;
    const checkedList = [...checkedImageIds];
    //在就说明选择过 做取消操作
    //不在 就说明未选择过 做添加操作
    if (checkedList.indexOf(e.id) !== -1) {
      checkedList.splice(checkedList.indexOf(e.id), 1);
    } else {
      if (maxChooseNum && checkedList.length >= maxChooseNum + checkedIds.length) {
        message.error('最多可以选择' + maxChooseNum + '张图片');
      } else {
        checkedList.push(e.id);
      }
    }
    setCheckedImageIds(checkedList);
  }

  const cancelChecked = () => {
    const checkedList = [...checkedImageIds];
    checkedList.splice(0, checkedList.length);
    setCheckedImageIds(checkedList);
  }

  const delImages = () => {
    delMaterial({ idList: checkedImageIds }).then(res => {
      if (res.code === 0) {
        getCategoryList();
        getImageList();
        // getALlImagesCount();
      }
      setCheckedImageIds([]);
      setCurrent(1);
    })
  }

  const sureImages = () => {
    let checkedIdsSet = new Set(checkedIds);
    let checkedImageIdsTemp = [...checkedImageIds.filter(o => !checkedIdsSet.has(o))]
    setImages(imageList.filter(o => checkedImageIdsTemp.indexOf(o.id) !== -1));
    setCheckedImageIds([]);
    closeModal();
  }

  // const setCheckedIds = useCallback(() => {
  //   if (checkedIds && checkedIds.length > 0) {
  //     setCheckedImageIds(checkedIds);
  //   }
  // }, [checkedIds])

  useEffect(() => {
    if (visible) {
      // setCheckedIds()
      getCategoryList();
      getImageList();
      // getALlImagesCount();
    }
  }, [visible, getImageList, getCategoryList])

  return (
    <div className={styles2['material-library']}>
      <div className={styles2['displayFlex']}>
        <div className={styles2['upload-button']} onClick={openModal}>
          <div>
            <PlusOutlined className={styles2['upload-icon']} />
          </div>
          <span className={styles2['upload-text']}>点击上传</span>
        </div>
      </div>

      <Modal
        visible={visible}
        // title='选择图片'
        width='864px'
        bodyStyle={bodyStyle}
        footer={null}
        wrapClassName={'material_pro'}
        onCancel={() => closeModal()}
      >
        <div className={styles['material_top_container']}>
          <div className={styles['material_top_title']}>选择图片</div>
        </div>
        <div className={styles['material_container_main']}>
          <div className={styles['material_left_menu']}>
            {/* <div className={`${styles['menu_item']} ${categoryId === 0 ? styles['active'] : ''}`} onClick={() => menuClick({ id: 0 })}>全部图片({allImageCount})</div> */}
            {
              materialCategoryList.map(o =>
                <div className={`${styles['menu_item']} ${categoryId === o.id ? styles['active'] : ''}`} key={o.id} onClick={() => menuClick(o)}>
                  <div className={styles['menu_info']}>
                    {o.name} ({o.quantity})
                </div>
                  <div className={styles['menu_operate']}>
                    <EditOutlined onClick={(e) => editCategory(e, o)} />
                    <span className={styles['operate_limiter']}>|</span>
                    <DeleteOutlined onClick={(e) => delCategory(e, o)} />
                  </div>
                </div>
              )
            }
            <div className={`${styles['menu_item_add']} ${styles['menu_item']}`} onClick={() => addCategory()}><PlusOutlined />新增分组</div>
          </div>
          <div className={styles['material_right_container']}>
            <div className={styles['material_upload_and_search']}>
              <div className={styles['material_upload']}>
                {
                  categoryId === 0 ?
                    ''
                    :
                    <Upload {...uploadProps}>
                      {
                        btnLoading ?
                          <Button type="primary" loading disabled>正在上传</Button>
                          :
                          <Button type="primary">本地上传</Button>
                      }
                    </Upload>
                }
              </div>
              <div className={styles['material_search']}></div>
            </div>
            <div className={styles['material_images']}>
              <Checkbox.Group style={{ width: '100%' }} value={checkedImageIds}>
                {
                  imageList.map((o) =>
                    <div className={styles['image_container']} key={o.url}>
                      <Card.Grid className={styles['card_container']} key={o.key}>
                        <Checkbox className={styles['checkbox']} value={o.id} />
                        <img alt={o.name} src={oss + o.url} className={styles['images']} onClick={() => { checkOneImage(o) }} />
                      </Card.Grid>
                      {/* <div key={o.name} className={styles['image_name']}>{o.name}</div> */}
                      {/* <div key={o.url}>123</div> */}
                    </div>
                  )
                }
              </Checkbox.Group>
            </div>
            <div className={styles['material_pagination']}>
              {
                imageList.length === 0 ?
                  ''
                  :
                  <Pagination
                    current={current}
                    defaultCurrent={1}
                    defaultPageSize={size}
                    pageSize={size}
                    total={total}
                    showQuickJumper
                    showSizeChanger={true}
                    pageSizeOptions={[30, 60, 100]}
                    showTotal={total => `共${total}张`}
                    onChange={pageChange}
                    onShowSizeChange={pageSizeChange}
                  />
              }
            </div>
          </div>
        </div>
        <div className={styles['footer_container']}>
          <div className={styles['footer_info']}>
            {maxChooseNum ? `已选择${checkedImageIds.length - checkedIds.length}个 ，最多可选择${maxChooseNum}个` : ''}
          </div>
          <div className={styles['footer_operate']}>
            <Space size="middle" className="operationCloumn">
              <Button className={styles['cancel-checked']} key="cancelChecked" onClick={cancelChecked}>取消选中</Button>
              <Popconfirm title={`确认删除图片吗?`} onConfirm={delImages}>
                <Button className={styles['delete']}>删除</Button>
              </Popconfirm>
            </Space>
          </div>
          <div className={styles['footer_sure_or_close']}>
            <Button className={styles['return']} onClick={() => closeModal()}>取消</Button>
            <Button type="primary" className={styles['sure']} key="return" onClick={() => sureImages()}>确定</Button>
          </div>
        </div>
      </Modal>

      <Modal
        title={`${categoryOpType === 'add' ? '新增' : '编辑'}分组`}
        visible={editCategoryVisible}
        onCancel={() => setEditCategoryVisible(false)}
        onOk={() => submitCategoryForm()}
      >
        <Form ref={categoryFormRef} form={categoryForm}>
          {
            categoryId ?
              <Form.Item name="id" hidden>
                <Input />
              </Form.Item>
              :
              ''
          }
          <Form.Item label="分组名" name="name" rules={[{ required: true, message: '请输入分组名' }]}>
            <Input maxLength={10} />
          </Form.Item>
        </Form>
      </Modal>
    </div >
  )
})

export default MaterialLibrary;