import React, { useState, useCallback, useEffect, useRef } from 'react';
import { queryPersonPoint, updatePoint } from '@/api/finance';
import { Table, Button, Form, Input, Select, Modal } from 'antd';
import { FormLayOut } from '@/utils/common';

const PointDetail = (props) => {

  const [page, setPage] = useState(0);
  const [size] = useState(20);
  const [total, setTotal] = useState(0);
  const [pointDetailList, setPointDetailList] = useState([]);
  const [adjustPointVisible, setAdjustPointVisible] = useState(false);

  const { id } = props.match.params;

  const [form] = Form.useForm();

  const formRef = useRef();

  const columns = [
    {
      title: '兑换人',
      dataIndex: 'username',
      width: '180px',
      align: 'center'

    },
    {
      title: '手机号码',
      dataIndex: 'phone',
      width: '180px',
      align: 'center'
    },
    {
      title: '类型',
      dataIndex: 'type',
      width: '100px',
      align: 'center',
      render: item => item === 1 ? '增加' : '减少'
    },
    {
      title: '积分',
      dataIndex: 'point',
      width: '180px',
      align: 'center'
    }
  ];

  const adjustPoint = () => {
    form.setFieldsValue({ id: id });
    setAdjustPointVisible(true);
  };

  const getPointDetailList = useCallback(() => {
    queryPersonPoint({ page: page, size: size, userId: id }).then(res => {
      if (res.code === 0) {
        let data = res.data.content;
        data.forEach(o => o.key = o.id);
        setTotal(res.data.totalElements);
        setPointDetailList(data);
      }
    })
  }, [page, size, id]);

  const pageChange = (page) => {
    setPage(page - 1);
  }

  const submitAdjust = () => {
    let data = formRef.current.getFieldsValue();
    data.userId = id;
    updatePoint(data).then(res => {
      if (res.code === 0) {
        getPointDetailList();
        setAdjustPointVisible(false);
      }
    })
  }

  useEffect(() => {
    getPointDetailList()
  }, [getPointDetailList])

  return (
    <div>
      <div className="top-area">
        <div className="btn-container">
          <Button type="primary" onClick={() => adjustPoint()}>调整积分</Button>
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={pointDetailList}
        bordered
        pagination={{ pageSize: size, defaultPageSize: size, total: total, onChange: pageChange, showSizeChanger: false, }}
      />
      <Modal
        visible={adjustPointVisible}
        onCancel={() => setAdjustPointVisible(false)}
        onOk={submitAdjust}
        title="调整积分"
      >
        <Form {...FormLayOut} ref={formRef} form={form}>
          <Form.Item hidden label="id" name="id">
            <Input />
          </Form.Item>
          {/* <Form.Item name="name" label="当前余额">
            <Input />
          </Form.Item> */}
          <Form.Item name="type" label="调整类型">
            <Select>
              <Select.Option value={1}>增加</Select.Option>
              <Select.Option value={-1}>减少</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="point" label="调整积分">
            <Input />
          </Form.Item>
          <Form.Item name="remark" label="备注">
            <Input.TextArea />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default PointDetail;