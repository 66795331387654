export const ContainerStyle = {
  height: '100%',
  width: '100%',
  top: '0px',
  bottom: '0px'
}

export const SmallFormLayOut = {
  labelCol: {
    xs: { span: 6 },
    sm: { span: 6 },
    md: { span: 6 },
    lg: { span: 6 },
    xl: { span: 2 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
    md: { span: 10 },
  },
}

export const FormLayOut = {
  labelCol: { span: 5 },
  wrapperCol: { span: 18 },
};

export const TailLayOut = {
  wrapperCol: { offset: 5, span: 24 },
}

export const SmallTailLayOut = {
  wrapperCol: { offset: 2, span: 24 },
}

export const NormalTailLayOut = {
  labelCol: { span: 3 },
  wrapperCol: { span: 20 },
}

export const BigFormLayOut = {
  labelCol: { span: 6 },
  wrapperCol: { span: 20 },
};

export const DisplayArea = {
  width: '75px',
  height: '75px',
  margin: '0px auto',
  boxSizing: 'border-box',
  display: 'table-cell',
  border: '1px solid #d9d9d9',
  backgroundColor: '#f5f5f6',
  borderStyle: 'dashed',
  verticalAlign: 'middle',
  textAlign: 'center',
  cursor: 'pointer'
}

export const DisplayImages = {
  width: '100px%',
  height: '100px',
  objectFit: 'cover'
}

export const DisplayFlex = { display: 'flex' }