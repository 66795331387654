import React, { useEffect, useRef, useState } from 'react';
import { Modal, Form, Input, Select } from 'antd';
import { FormLayOut } from '@/utils/common';
import { queryUserUpgradeRule } from '@/api/agent';

const AgentLevelRuleModalForm = (props) => {

  const { ruleVisible, ruleValues, closeModal, modalOk } = props;
  const [agentRuleList, setAgentRuleList] = useState([]);

  const [form] = Form.useForm();

  const formRef = useRef();

  const submitForm = () => {
    form.validateFields().then(() => {
      let data = formRef.current.getFieldsValue();
      modalOk(data);
    }).catch();
  }

  const getAgentRuleList = () => {
    queryUserUpgradeRule().then(res => {
      if (res.code === 0) {
        let data = res.data;
        data.forEach(o => o.key = o.id);
        setAgentRuleList(data);
      }
    })
  }


  useEffect(() => {
    getAgentRuleList();
    form.resetFields(['ruleId']);
    form.setFieldsValue(ruleValues);
  }, [form, ruleValues])

  return (
    <div>
      <Modal
        forceRender
        title="添加代理规则"
        visible={ruleVisible}
        onOk={submitForm}
        onCancel={closeModal}
      >
        <Form {...FormLayOut} ref={formRef} form={form}>
          <Form.Item hidden label="id" name="id">
            <Input />
          </Form.Item>
          <Form.Item label="代理等级" name="level">
            <Input disabled />
          </Form.Item>
          <Form.Item label="规则类型" name="ruleId" rules={[{ required: true, message: '请选择规则类型' }]}>
            <Select name="ruleId">
              {
                agentRuleList.map(o =>
                  <Select.Option key={o.name} value={o.id}>{o.name}</Select.Option>
                )
              }
            </Select>
          </Form.Item>
          <Form.Item label="金额" name="param1">
            <Input suffix="元" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default AgentLevelRuleModalForm;