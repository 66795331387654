import React from "react";
import { Result } from 'antd';
import { SmileOutlined } from '@ant-design/icons';
// import{ queryMenuList } from '@/api/menu.js'


const DashBoard = () => {

  return (
    <div>
      <Result
        icon={<SmileOutlined />}
        title="仪表盘功能正在完善，敬请期待!"
      />
      <div style={{ width: '200px', margin: '0px auto', display: 'flex', justifyContent: 'center' }}>
        <span style={{ fontSize: 18 }}>用户使用手册</span>
        <span style={{ fontSize: 18, margin: '0px 20px' }}>|</span>
        <span style={{ fontSize: 18, color: '#00CCFF', cursor: 'pointer' }}><a href="https://manage.cerurub.vip/pdf/%E9%9B%B6%E5%94%AE%E7%AE%A1%E7%90%86%E7%B3%BB%E7%BB%9F%E6%93%8D%E4%BD%9C%E6%89%8B%E5%86%8C.pdf">下载</a></span>
      </div>
    </div>
  )
}

export default DashBoard;
