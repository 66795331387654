/**
 * 处理路由数据
 */

// import routerConfig from './router'
import { PieChartOutlined, BarChartOutlined, AppstoreOutlined, OrderedListOutlined, UserOutlined, MoneyCollectOutlined, CarOutlined, MenuOutlined, TrademarkOutlined, DotChartOutlined } from '@ant-design/icons';

const router = [
  {
    key: '/admin',
    layout: 'AdminLayout'
  }
]

export const routerConfig = () => {
  let menuNodes = JSON.parse(localStorage.getItem('menuNodes'));
  /**
   * 递归实现路由
   * 当前版本无用 勿删
   */
  // const packRoute = (data, path = '') => {
  //   if (data && data.length > 0) {
  //     let obj = {};
  //     let menuNodeData = [];
  //     data.forEach(o => {
  //       obj = {};
  //       obj.key = o.name;
  //       obj.title = o.title;
  //       if (o.component) {
  //         obj.component = o.component
  //       }
  //       obj.icon = getIcon(o.icon);
  //       obj.path = '/admin' + path + o.name;
  //       obj.accessFlag = true;
  //       obj.hideInMenu = o.hidden === 1 ? false : true;
  //       o.subPath = path + o.name
  //       if (o.children.length !== 0) {
  //         obj.children = packRoute(o.children, o.subPath)
  //       }
  //       menuNodeData.push(obj)
  //     })
  //     return menuNodeData;
  //   }
  // }
  // let nodeData = packRoute(menuNodes);
  // console.log(nodeData);
  // router[0].children = nodeData;

  /**
   * 解析所有路由（含按钮） 放入localStorage
   * 在需要跳转的页面中拿出来匹配path
   * 匹配上了就是有权限
   * 没匹配上就是没权限
  */
  let allRoutes = [];
  const getAllRoutes = (data = [], path = '') => {
    let obj = {};
    data.forEach(o => {
      obj = {};
      obj.key = o.name;
      obj.title = o.title;
      if (o.component) {
        obj.component = o.component
      }
      obj.icon = getIcon(o.icon);
      obj.path = '/admin' + path + o.name;
      obj.accessFlag = true;
      obj.hideInMenu = o.hidden === 1 ? false : true;
      o.subPath = path + o.name
      if (o.children.length !== 0) {
        obj.children = getAllRoutes(o.children, o.subPath)
      }
      allRoutes.push(obj);
    })
  }
  getAllRoutes(menuNodes);
  localStorage.setItem("allRoutes", JSON.stringify(allRoutes));

  /**
   * 只解析前二级 必定为页面路由
   * :) 狗屎代码
   */
  let parentChildrenItem = {};
  let children = [];
  let childrenItem = {};
  let parentChildren = [];
  if (menuNodes && menuNodes.length > 0) {
    menuNodes.forEach(o => {
      parentChildrenItem = {};
      children = [];
      parentChildrenItem.key = o.name;
      parentChildrenItem.title = o.title;
      if (o.component) {
        parentChildrenItem.component = o.component;
      }
      parentChildrenItem.icon = getIcon(o.icon);
      parentChildrenItem.path = '/admin' + o.name;
      parentChildrenItem.accessFlag = true;
      parentChildrenItem.hideInMenu = o.hidden === 1 ? false : true;
      o.children.forEach(p => {
        childrenItem = {};
        childrenItem.key = p.name;
        childrenItem.title = p.title;
        // childrenItem.icon = PieChartOutlined;
        childrenItem.component = p.component;
        childrenItem.path = '/admin' + o.name + p.name;
        childrenItem.accessFlag = true;
        childrenItem.hideInMenu = p.hidden === 1 ? false : true;
        children.push(childrenItem);
      })
      if (children.length !== 0) {
        parentChildrenItem.children = children;
      }
      parentChildren.push(parentChildrenItem);
    })
    router[0].children = parentChildren;
  }
  return router;
}

export const handleJoinPath = (router, path, role) => {
  router.children && router.children.forEach((item) => {
    handleJoinPath(item, path + item.key, role)
  })
  router.accessFlag = true
  if (router.access) {
    if (router.access.indexOf(role) === -1) {  // 有权限
      router.accessFlag = false
    }
  }
  router.path = path
}

export const filterLayout = (type) => {
  let router;
  for (let i = 0; i < routerConfig().length; i++) {
    const item = routerConfig()[i];
    if (item.layout === type) {
      router = item
      break;
    }
  }
  return router
}

export const getAllRoute = (role) => {
  let layout_list = [] // 所有 layout 的集合
  let route_list = [] // 所有 route 的集合
  routerConfig().forEach((item) => {
    if (item.layout) {
      handleJoinPath(item, item.key, role)
      layout_list.push(item);
    }
  })
  getRoute(layout_list, route_list)

  routerConfig().forEach((item) => {
    if (!item.layout) {
      item.path = item.key
      route_list.push(item)
    }
  })
  return route_list
}

export const getRoute = (routes, list) => {
  for (const k in routes) {
    const item = routes[k];
    if (item.children) {
      getRoute(item.children, list)
    } else {
      document.title = item.title
      list.push(item)
    }
  }
}

export const getIcon = icon => {
  if (icon === 'PieChartOutlined') {
    return PieChartOutlined;
  } else if (icon === 'BarChartOutlined') {
    return BarChartOutlined;
  } else if (icon === 'AppstoreOutlined') {
    return AppstoreOutlined;
  } else if (icon === 'OrderedListOutlined') {
    return OrderedListOutlined;
  } else if (icon === 'UserOutlined') {
    return UserOutlined;
  } else if (icon === 'MoneyCollectOutlined') {
    return MoneyCollectOutlined;
  } else if (icon === 'CarOutlined') {
    return CarOutlined;
  } else if (icon === 'MenuOutlined') {
    return MenuOutlined;
  } else if (icon === 'TrademarkOutlined') {
    return TrademarkOutlined;
  } else if (icon === 'DotChartOutlined') {
    return DotChartOutlined;
  }
}

