import React, { Component } from 'react'
import { Switch, Route, withRouter } from 'react-router-dom';
import { getAllRoute } from '@/router'
import AllComponents from '@/views';
import Exception404 from '../exception/404'
import DocumentTitle from 'react-document-title';


class ContentCustom extends Component {

  constructor() {
    super()
    this.state = {}
  }

  render() {
    const { user } = this.props
    const pathname = this.props.location.pathname
    const routers = getAllRoute(user.role)
    let title = '零售后台系统-'

    for (let i = 0; i < routers.length; i++) {
      const item = routers[i]
      if (pathname.indexOf(item.path) !== -1) {
        title += item.title
        break;
      }
    }

    return (
      <div style={{ minHeight: 280 }}>
        <DocumentTitle title={title}>
          <Switch>
            <Route path="/user/editAdmin/:id?" key='editxx' component={AllComponents['AdminEdit']} />
            {
              routers.map(item => (
                item.accessFlag ? <Route path={item.path} key={item.path} component={AllComponents[item.component]} /> : null
              ))
            }
            <Route component={Exception404} exact />
          </Switch>
        </DocumentTitle>
      </div>
    )
  }
}

export default withRouter(ContentCustom)