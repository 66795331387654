import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Form, Button, Input, Upload } from 'antd';
import { saveAdmin } from '@/api/user';
import MaterialLibrary from '@/components/MaterialLibrary';
import styles from '@/styles/pages/product.module.scss';
import { oss } from '@/utils/publicPath';
import { useHistory } from "react-router-dom";

const AdminEdit = (props) => {

  let history = useHistory();

  const [form] = Form.useForm();

  const formRef = useRef();

  const [mainPic, setMainPic] = useState([{ uid: 0, url: localStorage.getItem('icon') && localStorage.getItem('icon') !== 'undefined' ? oss + localStorage.getItem('icon') : oss + 'material/on_error.png', filename: localStorage.getItem('icon') }]);

  const ContainerStyle = {
    backgroundColor: '#fff',
    padding: '30px 0px'
  }

  const Layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  }
  const TailLayout = {
    wrapperCol: { offset: 8, span: 16 }
  }

  const onFinish = values => {
    if (mainPic && mainPic.length !== 0) {
      values.icon = mainPic[0].filename
    }
    saveAdmin(values).then(res => {
      if (res.code === 0) {
        history.goBack(-1);
        localStorage.setItem('icon', res.data.icon)
        localStorage.setItem('nickname', res.data.nickname)
        localStorage.setItem('phone', res.data.phone)
        localStorage.setItem('username', res.data.username)
      }
    })
  };

  const setMainPicture = values => {
    let data = {};
    let imageList = [];
    values.forEach((o, i) => {
      data = {}
      data.uid = o.id;
      data.url = oss + o.url;
      data.filename = o.url;
      imageList.push(data);
    })
    setMainPic(imageList);
  }

  const onRemove = (file) => {
    console.log(file)
    let imageList = [...mainPic];
    let index = 0;
    imageList.forEach((o, i) => {
      if (o.uid === file.uid) {
        index = i;
      }
    })
    imageList.splice(index, 1);
    setMainPic(imageList);
  }

  useEffect(() => {
    form.setFieldsValue({
      id: localStorage.getItem('id'),
      username: localStorage.getItem('username'),
      nickname: localStorage.getItem('nickname'),
      phone: localStorage.getItem('phone')
    })
  }, [form])

  return (
    <div style={ContainerStyle}>
      <Form
        {...Layout}
        form={form}
        ref={formRef}
        initialValues={{ username: '', password: '', status: 1, gender: 0 }}
        onFinish={onFinish}
      >
        <Row gutter={[24, 24]} justify="left">
          <Col span={6} >
            <Form.Item hidden label="id" name="id">
              <Input />
            </Form.Item>
            <Form.Item
              label="用户名"
              name="username"
              rules={[{ required: true, message: '请输入用户名' }]}
            >
              <Input disabled />
            </Form.Item>

            <Form.Item
              label="密码"
              name="password"
              rules={[{ required: true, message: '请输入密码' }]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item label="头像">
              <div className={styles['display-flex-align-left']}>
                {
                  mainPic && mainPic.length !== 0 ?
                    <Upload
                      listType="picture-card"
                      fileList={mainPic}
                      onRemove={onRemove}
                    ></Upload>
                    :
                    ''
                }
                {
                  mainPic && mainPic.length === 0 ?
                    <div className={mainPic && mainPic.length !== 0 ? styles['materialContainer'] : ''}>
                      {
                        mainPic && mainPic.length === 0 ?
                          <MaterialLibrary maxChooseNum={1} setImages={setMainPicture} />
                          :
                          ''
                      }

                    </div>
                    :
                    ''
                }
              </div>
              <span style={{ color: '#bbb', fontSize: '12px' }}>提示：上传图片大小不超过3MB，支持jpg/jpeg/png格式，建议尺寸：200*200,1:1亦可</span>
            </Form.Item>

            <Form.Item
              label="昵称"
              name="nickname"
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="手机号"
              name="phone"
            >
              <Input />
            </Form.Item>
            {/* <Form.Item
              label="角色"
              name="roleId"
            >
              <Select name="roleId">
                {
                  roleList.map(o =>
                    <Select.Option key={o.id} value={o.id}>{o.name}</Select.Option>
                  )
                }
              </Select>
            </Form.Item>
            <Form.Item
              label="账号状态"
              name="status"
            >
              <Switch checkedChildren="启用" unCheckedChildren="禁用" onChange={accountStatusOnChange} checked={accountStatus} />
            </Form.Item> */}
            <Form.Item {...TailLayout}>
              <Button type="primary" htmlType="submit">
                提交
                  </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default AdminEdit;
