export const inArrayIndexOf = (arr, e) => {
  let index = -1
  try {
    arr.forEach((id, i) => {
      if (Number(e) === Number(id)) {
        index = i
        throw new Error("EndIterative");
      }
    })
  } catch (error) {
    throw e;
  } finally {
    return index
  }
}

export const getLabel = (val, dict) => {
  return val !== undefined ? dict[val] : ''
}

export const getColumns = (titleDict, dataIndexDict) => {
  if(titleDict.length === dataIndexDict.length){
    let length = titleDict.length;
    let columns = [];
    let temp = {};
    for(let i=0;i<length;i++){
      temp = Object.assign({},{});
      temp.title = titleDict[i];
      temp.dataIndex = dataIndexDict[i];
      columns.push(temp);
    }
    console.log(columns)
    return getColumns;
  }
}