import React, { useState, useEffect, useRef } from 'react';
import { Modal, Form, Input, Radio, message, Upload } from 'antd';
import { FormLayOut, DisplayFlex } from '@/utils/common';
import MaterialLibraryPro from '@/components/MaterialLibraryPro';
import UploadBtn from '@/components/UploadBtn';
import { oss } from '@/utils/publicPath';
import styles from '@/styles/pages/brand.module.scss';

const BrandModalForm = (props) => {

  const [form] = Form.useForm();

  const formRef = useRef();

  const [brandModalTitle, setBrandModalTitle] = useState();
  const [brandLogo, setBrandLogo] = useState([]);
  const [brandImages, setBrandImages] = useState([]);
  const [materialVisible, setMaterialVisible] = useState(false);
  const [materialVisible2, setMaterialVisible2] = useState(false);


  const { visible, initValues, closeModal, modalOk, actionType } = props;


  // simulation componentDidMount
  useEffect(() => {
    form.setFieldsValue(initValues);
    if (actionType === '新增') {
      setBrandLogo([]);
      setBrandImages([]);
    } else {
      setBrandLogo([{ uid: 0, url: oss + initValues.logo, filename: initValues.logo }]);
      if (initValues.image) {
        setBrandImages([{ uid: 0, url: oss + initValues.image, filename: initValues.image }]);
      }
    }
  }, [actionType, form, initValues]);

  const submitForm = () => {
    let data = formRef.current.getFieldsValue();
    if (brandLogo && brandImages && brandLogo.length !== 0 && brandImages.length !== 0) {
      data.logo = brandLogo[0].filename;
      data.image = brandImages[0].filename;
      modalOk(data)
    } else {
      message.error({ content: '请选择或上传图片', duration: 5 });
    }
  }

  const setLogo = (values) => {
    let data = {};
    let imageList = [];
    values.forEach((o, i) => {
      data = {}
      data.uid = o.id;
      data.url = oss + o.url;
      data.filename = o.url;
      imageList.push(data);
    })
    setBrandLogo(imageList);
  }

  const setImages = (values) => {
    let data = {};
    let imageList = [];
    values.forEach((o, i) => {
      data = {}
      data.uid = o.id;
      data.url = oss + o.url;
      data.filename = o.url;
      imageList.push(data);
    })
    setBrandImages(imageList);
  }

  const brandLogoOnRemove = (file) => {
    let imageList = [...brandLogo];
    const spliceIndex = (imageList || []).findIndex(o => o.id = file.uid);
    imageList.splice(spliceIndex, 1);
    setBrandLogo(imageList);
  }

  const brandImagesOnRemove = (file) => {
    console.log(file);
    let imageList = [...brandImages];
    imageList.splice(file.uid, 1);
    setBrandImages(imageList);
  }

  useEffect(() => { setBrandModalTitle(actionType + '品牌') }, [actionType]);

  return (
    <Modal forceRender visible={visible} title={brandModalTitle} onOk={submitForm} onCancel={closeModal}>
      <Form {...FormLayOut} ref={formRef} form={form}>
        <Form.Item hidden label="id" name="id">
          <Input />
        </Form.Item>
        <Form.Item label="品牌logo">
          <div className={styles['display-flex-align-left']}>
            {
              brandLogo && brandLogo.length !== 0 ?
                <Upload
                  listType="picture-card"
                  fileList={brandLogo}
                  onRemove={brandLogoOnRemove}
                ></Upload>
                :
                ''
            }

            {
              brandLogo && brandLogo.length === 0 ?
                <>
                  <div onClick={() => setMaterialVisible(true)}>
                    <UploadBtn />
                  </div>
                  <MaterialLibraryPro
                    visible={materialVisible}
                    closeModal={() => setMaterialVisible(false)}
                    maxChooseNum={1}
                    setImages={setLogo}
                  />
                </>
                :
                ''
            }
          </div>
          <span style={{ color: '#bbb', fontSize: '12px' }}>提示：上传图片大小不超过3MB，支持jpg/jpeg/png格式，建议尺寸：750*320,2.3:1亦可</span>
        </Form.Item>
        <Form.Item label="品牌图片" style={DisplayFlex}>
          <div className={styles['display-flex-align-left']}>
            {
              brandImages && brandImages.length !== 0 ?
                <Upload
                  listType="picture-card"
                  fileList={brandImages}
                  onRemove={brandImagesOnRemove}
                ></Upload>
                :
                ''
            }
            {
              brandImages && brandImages.length === 0 ?
                <>
                  <div onClick={() => setMaterialVisible2(true)}>
                    <UploadBtn />
                  </div>
                  <MaterialLibraryPro
                    visible={materialVisible2}
                    closeModal={() => setMaterialVisible2(false)}
                    maxChooseNum={1}
                    setImages={setImages}
                  />
                </>
                :
                ''
            }
          </div>
          <span style={{ color: '#bbb', fontSize: '12px' }}>提示：上传图片大小不超过3MB，支持jpg/jpeg/png格式，建议尺寸：200*200,1:1亦可</span>
        </Form.Item>
        <Form.Item label="品牌名称" name="name" rules={[{ required: true, message: '请输入品牌名称' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="品牌条码" name="code" rules={[{ required: true, message: '请输入品牌条码' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="关于品牌" name="about">
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item label="状态" name="status">
          <Radio.Group name="status">
            <Radio value={0}>隐藏</Radio>
            <Radio value={1}>显示</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal >
  )
}

export default BrandModalForm;