import DashBoard from './dashboard/DashBoard';
import Product from './product/Product';
import Category from './product/Category';
import MainOrder from './order/MainOrder';
import CheckOrder from './order/CheckOrder';
import MainAgent from './agent/AgentPriceEdit';
import AgentPrice from './agent/AgentPrice';
import AgentPriceEdit from './agent/AgentPriceEdit';
import ProductEdit from './product/ProductEdit';
import Access from './access/Access';
import Brand from './product/Brand';
import User from './user/User';
import Admin from './user/Admin';
import AdminEdit from './user/AdminEdit';
import AgentLevel from './agent/AgentLevel';
import AgentList from './agent/AgentList';
import PersonAccount from './finance/PersonAccount';
import BalanceDetail from './finance/BalanceDetail';
import PointDetail from './finance/PointDetail';
import ReceiveInfo from './user/ReceiveInfo';
import OrderDetail from './order/OrderDetail';
import Logistics from './logistics/Logistics';
import AgentRule from './agent/AgentRule';
import Role from './user/Role';
import Modular from './Modular/index';
import Menu from './menu/menu';
import Carousel from './product/Carousel';
import SelledProductCount from './report/SelledProductCount';
import SelledProductPrice from './report/SelledProductPrice';
import AgentAmountRecharge from './report/AgentAmountRecharge';
import AgentOrderPrice from './report/AgentOrderPrice';
import AgentSubList from './agent/AgentSubList';
import DarkLevelSelledProductCount from './report/DarkLevelSelledProductCount';
// 请保持 异常页 在最下面
import Exception404 from './exception/404';
import Exception500 from './exception/500';

export default {
  DashBoard,
  Product,
  Category,
  MainOrder,
  CheckOrder,
  MainAgent,
  AgentPrice,
  AgentPriceEdit,
  ProductEdit,
  Access,
  Brand,
  User,
  Admin,
  AdminEdit,
  AgentLevel,
  AgentList,
  PersonAccount,
  BalanceDetail,
  PointDetail,
  ReceiveInfo,
  OrderDetail,
  Logistics,
  AgentRule,
  Role,
  Modular,
  Menu,
  Carousel,
  SelledProductCount,
  SelledProductPrice,
  AgentAmountRecharge,
  AgentOrderPrice,
  AgentSubList,
  DarkLevelSelledProductCount,
  // 请保持 异常页 在最下面
  Exception404,
  Exception500
};
