import request from '@/utils/manageRequest'

export const saveBrand = (data) => {
  return request({
    url: 'productBrand/save',
    data,
    method: 'post'
  })
}

export const delBrand = (data) => {
  return request({
    url: `productBrand/delete?id=${data.id}`,
    method: 'delete'
  })
}

export const queryBrandList = (data) => {
  return request({
    url: 'productBrand/list',
    params: data,
    method: 'get'
  })
}

export const updateBrandStatus = (data) => {
  return request({
    url: `productBrand/status`,
    params: data,
    method: 'post'
  })
}

export const queryAllBrandList = () => {
  return request({
    url: 'productBrand/listByAll',
    method: 'get'
  })
}