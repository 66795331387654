import React, { useRef, useState, useEffect } from 'react';
import { Form, Input, Modal, Radio, Select, message, Upload } from 'antd';
import { FormLayOut } from '@/utils/common';
import MaterialLibrary from '@/components/MaterialLibrary';
import { oss } from '@/utils/publicPath';
import styles from '@/styles/pages/category.module.scss';
const { Option } = Select;

const CategoryModalFrom = (props) => {
  const [form] = Form.useForm();

  const formRef = useRef();

  const [brandModalTitle, setBrandModalTitle] = useState();
  const [categoryImage, setCategoryImage] = useState([]);
  const [categoryLogo, setCategoryLogo] = useState([]);
  const [level, setLevel] = useState(0);

  const { visible, initValues, closeModal, modalOk, actionType, categoryList, setDefaultCategory } = props;

  const submitForm = () => {
    let data = formRef.current.getFieldsValue();
    if (categoryImage && categoryImage.length !== 0) {
      data.image = categoryImage[0].filename;
      if (categoryLogo && categoryLogo.length !== 0) {
        data.logo = categoryLogo[0].filename;
      }
      modalOk(data);
    } else {
      message.error({ content: '请上传或选择图片', duration: 5 });
    }
  }

  const setImages = (values) => {
    let data = {};
    let imageList = [];
    values.forEach((o, i) => {
      data = {}
      data.uid = o.id;
      data.url = oss + o.url;
      data.filename = o.url;
      imageList.push(data);
    })
    setCategoryImage(imageList);
  }

  const setLogo = (values) => {
    let data = {};
    let imageList = [];
    values.forEach((o, i) => {
      data = {}
      data.uid = o.id;
      data.url = oss + o.url;
      data.filename = o.url;
      imageList.push(data);
    })
    setCategoryLogo(imageList);
  }

  const changeLevel = (e) => {
    setLevel(e.target.value);
    setDefaultCategory(e.target.value);
  }

  const categoryImageOnRemove = file => {
    console.log(file)
    let imageList = [...categoryImage];
    let index = 0;
    imageList.forEach((o, i) => {
      if (o.uid === file.uid) {
        index = i;
      }
    })
    imageList.splice(index, 1);
    setCategoryImage(imageList);
  }

  const categoryLogoOnRemove = file => {
    console.log(file)
    let imageList = [...categoryImage];
    let index = 0;
    imageList.forEach((o, i) => {
      if (o.uid === file.uid) {
        index = i;
      }
    })
    imageList.splice(index, 1);
    setCategoryLogo(imageList);
  }

  const emptyAssignZero = e => {
    e.persist();
    if (!e.target.value) {
      form.setFieldsValue({ rank: 0 });
    }
  }

  useEffect(() => {
    form.setFieldsValue(initValues);
    let title = '';
    if (actionType === 'add') {
      title = '新增';
      setCategoryImage([]);
      setCategoryLogo([]);
    } else if (actionType === 'edit') {
      title = '编辑';
      setCategoryImage([{ uid: 0, url: oss + initValues.image, filename: initValues.image }]);
      setCategoryLogo([{ uid: 0, url: oss + initValues.logo, filename: initValues.logo }]);
    }
    setBrandModalTitle(title + '分类');
    setLevel(initValues.level);
  }, [form, initValues, actionType]);


  return (
    <Modal forceRender visible={visible} title={brandModalTitle} onOk={submitForm} onCancel={closeModal}>
      <Form {...FormLayOut} ref={formRef} form={form}>
        <Form.Item hidden label="id" name="id">
          <Input />
        </Form.Item>
        <Form.Item hidden={actionType === 'add' ? false : true} label="设置级别" name="level">
          <Radio.Group name="level" onChange={changeLevel}>
            <Radio value={0}>一级</Radio>
            <Radio value={1}>二级</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="name" label="类目名称" rules={[{ required: true, message: '请输入类目名称' }]}>
          <Input />
        </Form.Item>
        <Form.Item name="rank" label="排序号">
          <Input onBlur={emptyAssignZero} />
        </Form.Item>
        {
          level === 0 ?
            <Form.Item hidden label="parentId" name="parentId">
              <Input />
            </Form.Item> :
            <Form.Item name="parentId" label="上级类目" rules={[{ required: true, message: '请选择上级类目' }]}>
              <Select>
                {
                  categoryList.map(o =>
                    <Option key={o.id} value={o.id}>{o.name}</Option>
                  )
                }
              </Select>
            </Form.Item>
        }
        <Form.Item label="类目图片">
          <div className={styles['display-flex-align-left']}>
            {
              categoryImage && categoryImage.length !== 0 ?
                <Upload
                  listType="picture-card"
                  fileList={categoryImage}
                  onRemove={categoryImageOnRemove}
                ></Upload>
                :
                ''
            }
            {
              categoryImage && categoryImage.length === 0 ?
                <div className={categoryImage && categoryImage.length !== 0 ? styles['materialContainer'] : ''}>
                  <MaterialLibrary maxChooseNum={1} setImages={setImages} />
                </div>
                :
                ''
            }
          </div>
          <span style={{ color: '#bbb', fontSize: '12px' }}>提示：上传图片大小不超过3MB，支持jpg/jpeg/png格式，建议尺寸：200*200,1:1亦可</span>
        </Form.Item>
        {
          level === 0 ?
            <Form.Item label="类目logo">
              <div className={styles['display-flex-align-left']}>
                {
                  categoryLogo && categoryLogo.length !== 0 ?
                    <Upload
                      listType="picture-card"
                      fileList={categoryLogo}
                      onRemove={categoryLogoOnRemove}
                    ></Upload>
                    :
                    ''
                }
                {
                  categoryLogo && categoryLogo.length === 0 ?
                    <div className={categoryLogo && categoryLogo.length !== 0 ? styles['materialContainer'] : ''}>
                      <MaterialLibrary maxChooseNum={1} setImages={setLogo} />
                    </div>
                    :
                    ''
                }
              </div>
              <span style={{ color: '#bbb', fontSize: '12px' }}>提示：上传图片大小不超过3MB，支持jpg/jpeg/png格式，建议尺寸：750*320,2.3:1亦可</span>
            </Form.Item>
            :
            ''
        }
        <Form.Item name="isHomeShow" label="首页展示">
          <Radio.Group name="isHomeShow">
            <Radio value={0}>隐藏</Radio>
            <Radio value={1}>显示</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="status"
          label="状态"
        >
          <Radio.Group name="isHomeShow">
            {/* <Radio value={-1}>删除</Radio> */}
            <Radio value={0}>隐藏</Radio>
            <Radio value={1}>显示</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default CategoryModalFrom;