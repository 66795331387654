import React, { Component } from 'react'
import { Layout, Menu, Dropdown, Avatar, Button } from 'antd'
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  LoginOutlined
} from '@ant-design/icons';
import { oss } from '@/utils/publicPath';
import { setToken } from '@/actions/app'

const { Header } = Layout;

class HeaderCustom extends Component {

  constructor(props) {
    super(props)
    this.state = {
    };
  }

  componentWillMount() { }

  handleClickMenuItem(e) {
    console.log(e, 'header-user-item');
    switch (e.key) {
      case "user":
        console.log(this.props);
        // window.location.replace('http://localhost:3000/#/admin/user/editAdmin')
        window.location.replace(oss + '#/admin/user/editAdmin')
        break;
      case "logout":
        this.outLogin()
        break;
      default:
        break;
    }
  }

  outLogin = () => {
    localStorage.clear()
    setToken();
    const { host, protocol } = window.location
    console.log(protocol + '//' + host + '/#/login')
    window.location.replace(protocol + '//' + host + '/#/login');

    // window.location.href = oss + '#/login'
    // window.location.reload();
    // window.location.href()
    // window.location.reload();
  }

  render() {
    const { collapsed, toggle } = this.props

    const menu = (
      <Menu onClick={this.handleClickMenuItem.bind(this)}>
        <Menu.Item key="user" icon={<UserOutlined />}>
          个人中心
        </Menu.Item>
        <Menu.Item key="logout" icon={<LoginOutlined />}>
          退出登录
        </Menu.Item>
      </Menu>
    );


    return (
      <Header className="site-layout-background site-layout-header-customer" style={{ padding: 0 }} >
        <div className="site-left-layout-header">
          {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: 'trigger',
            onClick: toggle,
          })}
          <div className="site-layout-header-customer-user">
            <Dropdown overlay={menu}>
              <Button type="text" className="site-layout-header-customer-user-button">
                <span className="username">{localStorage.getItem('nickname')}</span><Avatar size={32} src={oss + localStorage.getItem('icon')} />
              </Button>
            </Dropdown>
          </div>
        </div>
      </Header>
    )
  }
}

export default HeaderCustom