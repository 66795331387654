import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import './styles/common.css';
import './styles/index.css';
import App from './App';
import { Provider } from 'react-redux';
import {persistStore} from 'redux-persist';
import { PersistGate } from 'redux-persist/es/integration/react'
import store from './Store.js';
import * as serviceWorker from './serviceWorker';

import { ConfigProvider } from 'antd';
// 由于 antd 组件的默认文案是英文，所以需要修改为中文
import zhCN from 'antd/es/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
import '@/styles/App.less';
moment.locale('zh-cn');

let persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <HashRouter>
        {/* <React.StrictMode> */}
        <ConfigProvider locale={zhCN}>
          <App />
        </ConfigProvider>
        {/* </React.StrictMode> */}
      </HashRouter>
    </PersistGate>
  </Provider>
  ,document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
