import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom';
import { Layout, Menu } from 'antd'
// import { UserOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons';
import { handleJoinPath, filterLayout } from '@/router'
import cerurub from '@/assets/img/ceruru.b.jpg';

const { SubMenu } = Menu;
const { Sider } = Layout

class SiderCustome extends Component {

  constructor() {
    super()
    this.state = {
      routers: {},
      openKey: [],
      selectKey: []
    }
  }

  componentDidMount() {
    this.handleRouters()
  }

  handleRouters() {
    const user = this.props.user
    let routers = filterLayout('AdminLayout')
    handleJoinPath(routers, routers.key, user.role)
    let pathname = this.props.location.pathname
    let openKey = [pathname.substring(0, pathname.lastIndexOf('/'))]
    this.setState({
      routers,
      openKey,
      selectKey: [pathname]
    })
  }

  handleClick = (e) => {
    let key = e.key
    let openKey = [key.substring(0, key.lastIndexOf('/'))]
    this.setState({
      openKey,
      selectKey: e.keyPath
    })
  }

  renderSubMenu(item) {
    // console.log(item);
    return (
      <SubMenu key={item.path} data-test={item.path}
        title={
          <span>
            <Icon component={item.icon} className='sider-menu-icon' />
            <span className="nav-text">{item.title}</span>
          </span>
        }
      >
        {item.children && item.children.map((sub) => (
          sub.children ? this.renderSubMenu(sub) : (
            (sub.hideInMenu || !sub.accessFlag) ? null : this.renderMenuItem(sub)
          )
        ))}
      </SubMenu>
    )
  }

  renderMenuItem(item) {
    return (
      <Menu.Item
        data-tset1={item.path}
        key={item.path}
      >
        <Link to={item.path}>{item.icon ? <Icon component={item.icon} className='sider-menu-icon' /> : null}<span>{item.title}</span></Link>
      </Menu.Item>
    )
  }

  render() {
    let { routers, openKey, selectKey } = this.state
    const { collapsed } = this.props

    if (openKey.length === 0) {
      let pathname = this.props.location.pathname
      openKey = [pathname.substring(0, pathname.lastIndexOf('/'))]
      selectKey = [pathname]
    }
    return (
      <Sider
        // width={150}
        trigger={null}
        collapsible
        collapsed={collapsed}
        className="site-layout-background"
        style={{
          overflow: 'auto',
          height: '100vh'
        }}
        theme="light"
      >
        <div className="sider-menu-item-logo">
          <img alt="logo" src={cerurub} />
        </div>
        <Menu
          mode="inline"
          theme="light"
          defaultOpenKeys={openKey}
          selectedKeys={selectKey}
          onClick={this.handleClick}
        >
          {
            routers.key && routers.children.map((item) => {
              return item.children ? this.renderSubMenu(item) : (
                (item.accessFlag && !item.hideInMenu) ? this.renderMenuItem(item) : null
              )
            })
          }
        </Menu>
      </Sider>
    )
  }
}

export default withRouter(SiderCustome)