import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Form, Input, Button, Table, Timeline, Upload } from 'antd';
import { SmallFormLayOut, DisplayFlex, DisplayImages } from '@/utils/common';
import styles from '@/styles/pages/product.module.scss';
import { oss } from '@/utils/publicPath';
import { useHistory } from 'react-router-dom';
import { queryOrderDetail, queryExpress } from '@/api/order';

const OrderDetail = (props) => {

  const { id } = props.match.params;

  const BasicInfo = {
    color: '#000',
    fontSize: '14px',
    padding: '0 24px',
    lineHeight: '50px',
    fontWeight: '700',
    background: 'rgb(249, 249, 249)',
    paddingLeft: '30px',
    marginBottom: '20px'
  }

  let history = useHistory();

  const formRef = useRef();

  const [form] = Form.useForm();

  const [orderDetailInfo, setOrderDetailInfo] = useState();

  const [omsOrderDetailList, setOmsOrderDetailList] = useState([]);

  const [expressInfo, setExpressInfo] = useState([]);

  const columns = [
    {
      title: '商品图片',
      width: '200px',
      align: 'center',
      render: value =>
        <div className={styles['dispaly-flex']}>
          <div className={styles['imageContainer']}>
            <img alt="example" src={oss + value.image} style={DisplayImages} />
          </div>
        </div>
    },
    {
      title: '商品名称',
      dataIndex: 'productName',
      align: 'center',
      width: '320px'
    },
    {
      title: '品牌名称',
      dataIndex: 'brandName',
      align: 'center',
      width: '320px'
    },
    {
      title: '条码',
      dataIndex: 'jancode',
      align: 'center',
      width: '200px'
    },
    {
      title: '价格',
      dataIndex: 'price',
      align: 'center',
      width: '150px'
    },
    {
      title: '数量',
      dataIndex: 'quantity',
      align: 'center',
      width: '150px'
    },
  ]

  const toOrderlist = () => {
    history.goBack(-1);
  }

  const getOrderDetail = useCallback(() => {
    queryOrderDetail({ id: id }).then(res => {
      if (res.code === 0) {
        let data = res.data;
        let omsOrder = data.omsOrder;
        let omsOrderContact = data.omsOrderContact;
        let omsOrderDetailList = data.omsOrderDetailList;
        let omsRefundImageList = data.omsRefundImageList;
        omsRefundImageList.forEach((o, i) => {
          o.uid = i;
          o.url = oss + o.image;
          o.filename = o.image;
        });
        let orderDetail = {};
        setOrderDetailInfo(res.data);
        orderDetail.integral = omsOrder.integral;
        orderDetail.num = omsOrder.num;
        orderDetail.payAmount = omsOrder.payAmount;
        orderDetail.statusNum = omsOrder.status;
        orderDetail.status = omsOrder.status === -3 ? '退款负冲单'
          : omsOrder.status === -2 ? '已退款'
            : omsOrder.status === -1 ? '退款中'
              : omsOrder.status === 0 ? '已取消'
                : omsOrder.status === 1 ? '待付款'
                  : omsOrder.status === 2 ? '待发货'
                    : omsOrder.status === 3 ? '待收货'
                      : omsOrder.status === 4 ? '已完成'
                        : '状态异常';
        orderDetail.total = omsOrder.total;
        orderDetail.totalAmount = omsOrder.totalAmount;
        orderDetail.userId = omsOrder.userId;
        orderDetail.deliveryDate = omsOrder.deliveryDate ? omsOrder.deliveryDate : '';
        orderDetail.expressCompanyName = omsOrder.expressCompanyName ? omsOrder.expressCompanyName : '';
        orderDetail.expressCode = omsOrder.expressCode ? omsOrder.expressCode : '';
        if (omsOrder.expressCompanyId) {
          let data = { expressCompanyId: omsOrder.expressCompanyId, num: omsOrder.expressCode };
          queryExpress(data).then(res => {
            if (res.code === 0) {
              if (res.data.status === '200') {
                setExpressInfo(res.data.data);
              }
              // else {
              //   message.error({
              //     content: res.data.message,
              //     duration: 5
              //   })
              // }
            }
          })
        }
        orderDetail.addressArea = omsOrderContact ? omsOrderContact.addressArea : '';
        orderDetail.addressCity = omsOrderContact ? omsOrderContact.addressCity : '';
        orderDetail.addressDetailed = omsOrderContact ? omsOrderContact.addressDetailed : '';
        orderDetail.addressProvince = omsOrderContact ? omsOrderContact.addressProvince : '';
        orderDetail.phoneNumber = omsOrderContact ? omsOrderContact.phoneNumber : '';
        orderDetail.userName = omsOrderContact ? omsOrderContact.userName : '';
        orderDetail.omsRefundImageList = omsRefundImageList;
        omsOrderDetailList.forEach(o => o.key = o.brandName);
        setOrderDetailInfo(orderDetail);
        setOmsOrderDetailList(omsOrderDetailList);
      }
    })
  }, [id])

  useEffect(() => {
    getOrderDetail();
  }, [getOrderDetail])

  return (
    <div style={{ backgroundColor: '#fff', padding: '0px 0px 30px' }}>
      {
        orderDetailInfo !== undefined ?
          <Form {...SmallFormLayOut} ref={formRef} form={form}>
            <div style={BasicInfo}>
              订单信息
            </div>
            <Form.Item hidden label="id" name="id">
              <Input />
            </Form.Item>
            <Form.Item label="订单编号">
              <span>{orderDetailInfo.num}</span>
            </Form.Item>
            <Form.Item label="订单状态">
              <span>{orderDetailInfo.status}</span>
            </Form.Item>
            <Form.Item label="商品数量">
              <span>{orderDetailInfo.total}</span>
            </Form.Item>
            <Form.Item label="订单金额">
              <span>{orderDetailInfo.payAmount}</span>
            </Form.Item>
            <Form.Item label="付款金额">
              <span>{orderDetailInfo.payAmount}</span>
            </Form.Item>
            <div style={BasicInfo}>
              收货信息
            </div>
            <Form.Item label="收货人">
              <span>{orderDetailInfo.userName}</span>
            </Form.Item>
            <Form.Item label="手机号码">
              <span>{orderDetailInfo.phoneNumber}</span>
            </Form.Item>
            <Form.Item label="用户地址">
              <span>{orderDetailInfo.addressProvince} {orderDetailInfo.addressCity} {orderDetailInfo.addressArea} {orderDetailInfo.addressDetailed}</span>
            </Form.Item>
            {
              orderDetailInfo.statusNum === 3 || orderDetailInfo.statusNum === 4 ?
                <div>
                  <div style={BasicInfo}>
                    物流信息
                  </div>
                  <Form.Item label="快递公司">
                    <span>{orderDetailInfo.expressCompanyName}</span>
                  </Form.Item>
                  <Form.Item label="快递单号">
                    <span>{orderDetailInfo.expressCode}</span>
                  </Form.Item>
                  <Form.Item label="发货时间">
                    <span>{orderDetailInfo.deliveryDate}</span>
                  </Form.Item>
                  <Form.Item label="物流信息">
                    <Timeline mode={'left'}>
                      {
                        expressInfo.map(o =>
                          <Timeline.Item>{o.time} - {o.context}</Timeline.Item>
                        )
                      }
                    </Timeline>
                  </Form.Item>
                </div>
                :
                ''
            }
            {
              orderDetailInfo.statusNum === -3 || orderDetailInfo.statusNum === -2 || orderDetailInfo.statusNum === -1 ?
                <div>
                  <div style={BasicInfo}>
                    退款截图
                  </div>
                  <Form.Item label="退款图片" style={DisplayFlex}>
                    <div className={styles['display-flex-align-left']}>
                      {
                        orderDetailInfo.omsRefundImageList && orderDetailInfo.omsRefundImageList.length !== 0 ?
                          <Upload
                            listType="picture-card"
                            fileList={orderDetailInfo.omsRefundImageList}
                            showUploadList={{ showRemoveIcon: false }}
                          ></Upload>
                          :
                          ''
                      }
                    </div>
                  </Form.Item>
                </div>
                :
                ''
            }
            <div style={BasicInfo}>
              商品信息
            </div>
            <Form.Item label="订单商品" >
              <div style={{ width: '1000px', marginTop: '10px' }}>
                <Table
                  columns={columns}
                  dataSource={omsOrderDetailList}
                  pagination={false}
                  bordered
                />
              </div>
            </Form.Item>
            <div className={styles['bottom-submit-and-back']}>
              <Button htmlType="button" onClick={() => { toOrderlist() }}>
                返回
          </Button>
            </div>
          </Form>
          :
          ''
      }
    </div>
  )
}

export default OrderDetail;