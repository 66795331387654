const app = (state = {}, action) => {
  switch (action.type) {
    case 'SET_GLOBAL_CONFIG':
      return Object.assign({}, state, action.domain)    
    case 'SET_TOKEN':
      return Object.assign({}, state, action.token)
    default:
      return state
  }
}

export default app