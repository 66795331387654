import React from "react";
import { connect } from 'react-redux';
import { Button, Result } from "antd";

class Exception404 extends React.Component {

  goBackHome = () => {
    this.props.history.push('/admin/dashboard')
  }

  render() {
    return (
      <Result
        status="403"
        title="403"
        subTitle="抱歉，您没有权限访问"
        extra={
          <Button type="primary" onClick={this.goBackHome}>
            Back Home
          </Button>
        }
      />
    );
  }
}

export default connect()(Exception404);
