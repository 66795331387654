import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Table, Button, Form, Input, Modal, message } from 'antd';
import { queryReceiveInfoList, saveReceiveInfo } from '@/api/user';
import { FormLayOut } from '@/utils/common';

const ReceiveInfo = () => {

  const formRef = useRef();

  const [form] = Form.useForm();

  const [receiveInfoList, setReceIveInfoList] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const getReceiveInfoList = useCallback(() => {
    setLoading(true);
    queryReceiveInfoList().then(res => {
      if (res.code === 0) {
        let data = res.data;
        data.forEach(o => o.key = o.id);
        setReceIveInfoList(data);
        setLoading(false);
      }
    })
  }, [])

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      align: 'center',
      width: '100px'
    }, {
      title: '银行账号',
      dataIndex: 'account',
      align: 'center',
      width: '200px'
    }, {
      title: '银行名称',
      dataIndex: 'bank',
      align: 'center',
      width: '200px'
    },
    {
      title: '公司名称',
      dataIndex: 'company',
      align: 'center',
      width: '200px'
    },
    {
      title: '操作',
      align: 'center',
      width: '200px',
      render: o =>
        <Button type="primary" size="small" onClick={() => editReceiveInfo(o)}>编辑</Button>
    }
  ];

  const editReceiveInfo = o => {
    if (localStorage.getItem('roleId') !== '2') {
      message.warning({
        content: '您没有权限',
        duration: 5
      })
      return;
    }
    form.setFieldsValue(o);
    setVisible(true);
  }

  const submitReceiveInfo = () => {
    let data = formRef.current.getFieldsValue();
    saveReceiveInfo(data).then(res => {
      if (res.code === 0) {
        getReceiveInfoList();
        setVisible(false);
      }
    })
  }

  const addReceiveInfo = () => {
    if (localStorage.getItem('roleId') !== '2') {
      message.warning({
        content: '您没有权限',
        duration: 5
      })
      return;
    }
    form.setFieldsValue({ id: '', account: '', bank: '', company: '' });
    setVisible(true);
  }


  useEffect(() => {
    getReceiveInfoList();
  }, [getReceiveInfoList])

  return (
    <div>
      <div className="top-area">
        <div className="btn-container">
          <Button type="primary" onClick={addReceiveInfo}>新增收款信息</Button>
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={receiveInfoList}
        pagination={false}
        bordered
        loading={loading}
      />
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={() => submitReceiveInfo()}
        title="公司收款信息"
      >
        <Form {...FormLayOut} ref={formRef} form={form}>
          <Form.Item hidden label="id" name="id">
            <Input />
          </Form.Item>
          <Form.Item name="account" label="账户">
            <Input />
          </Form.Item>
          <Form.Item name="bank" label="银行">
            <Input />
          </Form.Item>
          <Form.Item name="company" label="公司">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default ReceiveInfo;