import request from '@/utils/manageRequest';

export const querySelledProductCount = data => {
  return request({
    url: `report/productQuantity?page=${data.page}&size=${data.size}&jancode=${data.jancode}&username=${data.username}&year=${data.year}`,
    method: 'get'
  })
}

export const querySelledProductAmount = data => {
  return request({
    url: `report/productAmount?page=${data.page}&size=${data.size}&jancode=${data.jancode}&username=${data.username}&year=${data.year}`,
    method: 'get'
  })
}

export const queryUserOrderAmount = data => {
  return request({
    url: `report/userOrderAmount?page=${data.page}&size=${data.size}&username=${data.username}&year=${data.year}&inviteId=${data.inviteId}&categoryId=${data.categoryId}`,
    method: 'get'
  })
}

export const queryRecharge = data => {
  return request({
    url: `report/recharge?page=${data.page}&size=${data.size}&username=${data.username}&year=${data.year}&inviteId=${data.inviteId}`
  })
}

export const queryDarkSelledProductAmount = data =>
  request({
    url: `report/productQuantityDark?page=${data.page}&size=${data.size}&agentId=${data.agentId}&jancode=${data.jancode}&year=${data.year}`,
    method: 'get'
  })
