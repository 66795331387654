import request from '@/utils/manageRequest';

export const queryOrderList = (data) => {
  return request({
    url: 'order/list',
    method: 'get',
    params: data
  })
}

export const queryOrderDetail = (data) => {
  return request({
    url: `order/detail?id=${data.id}`,
    method: 'get'
  })
}

export const confirmRefund = (data) => {
  return request({
    url: `order/confirmRefund?id=${data.id}`,
    method: 'post'
  })
}

export const refundOk = (data) => {
  return request({
    url: `order/refund?id=${data.id}&imageList=${data.imageList}`,
    method: 'post'
  })
}

export const orderShip = (data) => {
  return request({
    url: 'order/ship',
    method: 'post',
    params: data
  })
}

export const queryExpressCompanyList = (data) => {
  return request({
    url: 'expressCompany/list',
    method: 'get'
  })
}

export const deleteExpressCompany = (data) => {
  return request({
    url: `expressCompany/delete/${data.id}`,
    method: 'post'
  })
}

export const saveExpressCompany = (data) => {
  return request({
    url: `expressCompany/save`,
    method: 'post',
    data
  })
}

export const queryExpressConpanyDetail = (data) => {
  return request({
    url: `expressCompany/detail/${data.id}`,
    method: 'get'
  })
}

export const updateExpressCompanyStatus = (data) => {
  return request({
    url: `expressCompany/status/${data.id}?status=${data.status}`,
    method: 'post'
  })
}

export const queryExpress = (data) => {
  return request({
    url: `expressQuery?expressCompanyId=${data.expressCompanyId}&num=${data.num}`,
    method: 'get'
  })
}

export const exportOrder = (data) => {
  return request({
    // url: `order/export/list?idList=${data.idList}&num=${data.num}&status=${data.num}`,
    url: data.url,
    method: 'get',
    responseType: 'blob'
  })
}