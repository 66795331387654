import { setToken } from '@/actions/app'
// import { saveUserInfo } from '@/actions/user'
import { loginIn } from '@/api/login'
import { message } from 'antd'

let hide = () => { };
export const loginAction = (formVal, history) => {
  return (dispatch) => {
    hide = message.loading('登录中..', 0);
    loginIn(formVal).then((res) => {
      console.log(res)
      if (res.code === 0) {
        // dispatch(saveUserInfo(res.data))
        dispatch(setToken(res.data))
        localStorage.setItem('id', res.data.admin.id)
        localStorage.setItem('nickname', res.data.admin.nickname)
        localStorage.setItem('phone', res.data.admin.phone)
        localStorage.setItem('roleId', res.data.admin.roleId)
        localStorage.setItem('status', res.data.admin.status)
        localStorage.setItem('username', res.data.admin.username)
        localStorage.setItem('icon', res.data.admin.icon)
        localStorage.setItem('token', res.data.token)
        localStorage.setItem('menuNodes', JSON.stringify(res.data.menuNodes))
        // 没有发生异常，跳转至主页
        history.push('/admin/dashboard');
      }
      hide()
    }).catch(err => {
      console.log(err, 'err')
      hide()
    })
  };
};
