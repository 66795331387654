import React, { Component } from 'react';
import { Form, Input } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { loginAction } from './actions';
import logo from '@/assets/img/logo.png';
import styles from '@/styles/pages/login.module.scss'
// import store from '@/Store.js';
const FormItem = Form.Item;

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  UNSAFE_componentWillMount() {
    // let { app } = store.getState()
    // console.log(app);
    // console.log(app.token ? '1111111' : '222222222');
    if (localStorage.getItem('token')) { // 有user 则已登录
      this.props.history.push('/admin/dashboard')
    }
  }

  gotoLogin = (values) => {
    this.props.login(values);
  };

  render() {
    return (
      <div className={styles.login}>
        <div className={styles.header}>
          <div className={styles['header-wrapper']}>
            <header>
              <a href="/">
                <img src={logo} alt="" />
                零售后台管理系统
              </a>
            </header>
          </div>
        </div>
        <div className={styles["login-title"]}>登录</div>
        <div className={styles.content}>
          <Form onFinish={this.gotoLogin} className={styles['login-form']}>
            <span className={styles['input-tips']}>请输入用户名</span>
            <FormItem hasFeedback
              name="username"
              rules={[{ required: true, message: '请输入用户名!' }]}
            >
              <Input prefix={<UserOutlined className="site-form-item-icon" />} />
            </FormItem>
            <span className={styles['input-tips']}>请输入密码</span>
            <FormItem hasFeedback
              name="password"
              rules={[{ required: true, message: '请输入密码!' }]}
            >
              <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} />
            </FormItem>
            <FormItem >
              <button type="submit" className={styles['login-form-button']}>立即登录</button>
            </FormItem>
          </Form>
        </div>
        <div className={styles['footer']}> <a href="http://beian.miit.gov.cn" target="blank">粤ICP备20001682号 美黛生物科技 2020-2030</a> </div>
      </div>
    );
  }

}

const mapDispachToProps = (dispatch, props) => ({
  login: (formValue) => {
    // 等待
    dispatch(loginAction(formValue, props.history));
  }
});

export default connect(null, mapDispachToProps)(Login)