import React, { useState, useEffect, useCallback, useRef } from "react";
import { Button, Table, Input, Modal, Form, Select, message, Timeline, Upload, Popconfirm } from 'antd';
import { queryOrderList, queryOrderDetail, refundOk, orderShip, queryExpressCompanyList, confirmRefund, queryExpress } from '@/api/order';
import { queryUserList } from '@/api/user';
import { FormLayOut } from '@/utils/common';
import MaterialLibrary from '@/components/MaterialLibrary';
import styles from '@/styles/pages/product.module.scss';
import { oss, manageRequestApi } from '@/utils/publicPath';
import { useHistory } from "react-router-dom";
// import { beforeOperate } from '@/utils/beforeOperate';
import axios from "axios";
import store from '@/Store';
const Option = Select.Option;

const MainOrder = () => {

  let history = useHistory()

  const shipFormRef = useRef();
  const refundFormRef = useRef();
  const checkImageFormRef = useRef();

  const [shipForm] = Form.useForm();
  const [refundForm] = Form.useForm();
  const [checkImageForm] = Form.useForm();

  const [page, setPage] = useState(0);
  const [size] = useState(20);
  const [total, setTotal] = useState(0);
  const [orderList, setOrderList] = useState([]);
  const [num, setNum] = useState('');
  const [refundVisible, setRefundVisible] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [shipVisible, setShipVisible] = useState(false);
  const [expressList, setExpressList] = useState([]);
  const [expressInfo, setExpressInfo] = useState([]);
  const [expressVisible, setExpressVisible] = useState(false);
  const [orderStatus, setOrderStatus] = useState();
  const [loading, setLoading] = useState(false);
  const [checkImageVisible, setCheckImageVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [excelOrderStatus, setExcelOrderStatus] = useState('');
  const [agentName, setAgentName] = useState('')
  const [agentList, setAgentList] = useState([])

  const columns = [
    {
      title: '订单状态',
      dataIndex: 'status',
      width: '120px',
      align: 'center',
      render: item =>
        item === -3 ? '退款负冲单'
          : item === -2 ? '已退款'
            : item === -1 ? '退款中'
              : item === 0 ? '已取消'
                : item === 1 ? '待付款'
                  : item === 2 ? '待发货'
                    : item === 3 ? '待收货'
                      : item === 4 ? '已完成'
                        : '状态异常',
      filters: [
        { text: '待付款', value: 1 },
        { text: '待发货', value: 2 },
        { text: '待收货', value: 3 },
        { text: '已完成', value: 4 },
        { text: '退款中', value: -1 },
        { text: '已退款', value: -2 },
        { text: '退款负冲单', value: -3 },
        { text: '已取消', value: 0 },
      ],
      filterMultiple: false,
    },
    {
      title: '订单编号',
      dataIndex: 'num',
      width: '300px',
      align: 'center'
    },
    {
      title: '下单时间',
      dataIndex: 'createdDate',
      width: '180px',
      align: 'center'
    },
    {
      title: '订单价格',
      dataIndex: 'payAmount',
      width: '120px',
      align: 'center'
    },
    {
      title: '联系人',
      dataIndex: 'contactName',
      width: '150px',
      align: 'center'
    },
    {
      title: '下单代理',
      dataIndex: 'userName',
      width: '120px',
      align: 'center'
    },
    {
      title: '操作',
      width: '250px',
      align: 'center',
      render: value =>
        <div>
          <Button type="primary" size="small" onClick={() => { toOrderDetail(value) }} style={{ margin: '0px 10px' }}>详情</Button>
          {
            value.status === -1 ?
              <Button style={{ color: 'white', background: '#FF99CC', border: 'none', margin: '0px 10px' }} size="small" onClick={() => updateImage(value)}>已传图</Button>
              :
              ''
          }
          {
            value.status === -1 ?
              <Popconfirm title="确认退款?" onConfirm={() => confirmRefundPk(value)}>
                <Button type="primary" size="small" danger style={{ margin: '0px 10px' }}>确认退款</Button>
              </Popconfirm>
              :
              ''
          }
          {
            value.status === 3 || value.status === 4 ?
              <Button size="small" onClick={() => getExpressInfo(value)} style={{ margin: '0px 10px', color: 'white', background: '#FF99CC', border: 'none' }}>查看物流</Button>
              :
              ''
          }
          {
            value.status === 2 ?
              <Button type="primary" danger size="small" style={{ margin: '0px 10px' }} onClick={() => ship(value)} >发货</Button>
              :
              ''
          }
          {
            value.status === 2 || value.status === 3 || value.status === 4 ?
              <Button type="primary" size="small" style={{ color: 'white', background: '#669999', border: 'none', margin: '0px 10px' }} onClick={() => refund(value)}>退款</Button>
              :
              ''
          }
        </div>
    }
  ]

  const onSelectChange = selectedRowKeys => {
    setSelectedRowKeys(selectedRowKeys);
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const toOrderDetail = (o) => {
    // let { pathname } = history.location;
    // if (beforeOperate(pathname, '/detail')) return;
    history.push({ pathname: `/admin/order/orderDetail/${o.id}` });
  }

  const getOrderList = useCallback(() => {
    setLoading(true);
    queryOrderList({ page: page, size: size, num: num, userName: agentName, status: orderStatus }).then(res => {
      if (res.code === 0) {
        let data = res.data.content;
        data.forEach(o => o.key = o.id);
        setOrderList(data);
        setTotal(res.data.totalElements);
      }
      setLoading(false);
    })
  }, [page, size, orderStatus, agentName, num]);

  const pageChange = (page) => {
    setPage(page - 1);
  }

  const refund = (o) => {
    // let { pathname } = history.location;
    // if (beforeOperate(pathname, '/refund')) return;
    refundForm.setFieldsValue({ id: o.id });
    queryOrderDetail({ id: o.id }).then(res => {
      if (res.code === 0) {
        setImageList(res.data.omsRefundImageList.map(o => o.image));
        setRefundVisible(true);
      }
    })
  }

  const updateImage = o => {
    checkImageForm.setFieldsValue({ id: o.id });
    queryOrderDetail({ id: o.id }).then(res => {
      if (res.code === 0) {
        let imageListTemp = [];
        let data = {};
        res.data.omsRefundImageList.map(o => o.image).forEach((p, i) => {
          data = {}
          data.uid = i;
          data.url = oss + p;
          data.filename = p;
          imageListTemp.push(data);
        })
        setImageList(imageListTemp);
        setCheckImageVisible(true);
      }
    })
  }

  const imageListOnRemove = file => {
    let imageListTemp = [...imageList];
    let index = 0;
    imageListTemp.forEach((o, i) => {
      if (o.uid === file.uid) {
        index = i;
      }
    })
    imageListTemp.splice(index, 1);
    setImageList(imageListTemp);
  }

  const setImageLists = values => {
    let imageListTemp = [...imageList];
    let imageTemp = {};
    values.forEach((o, i) => {
      imageTemp = {}
      imageTemp.uid = o.id;
      imageTemp.url = oss + o.url;
      imageTemp.filename = o.url;
      imageListTemp.push(imageTemp);
    })
    setImageList(imageListTemp);
  }

  const confirmRefundPk = (o) => {
    // let { pathname } = history.location;
    // if (beforeOperate(pathname, '/sureRefund')) return;
    confirmRefund({ id: o.id }).then(res => {
      if (res.code === 0) {
        message.success({
          content: '退款成功',
          duration: 5
        })
        getOrderList();
      }
    })
  }

  const assignNum = (e) => {
    setNum(e.target.value);
  }

  const submitRefundInfo = () => {
    let data = refundFormRef.current.getFieldsValue();
    data.imageList = imageList.map(o => o.filename);
    refundOk(data).then(res => {
      if (res.code === 0) {
        getOrderList();
        setRefundVisible(false);
      }
    })
  }

  const submitReImageInfo = () => {
    let data = checkImageFormRef.current.getFieldsValue();
    data.imageList = imageList.map(o => o.filename);
    refundOk(data).then(res => {
      if (res.code === 0) {
        getOrderList();
        setCheckImageVisible(false);
      }
    })
  }

  const setRefundImageList = (values) => {
    let imageListTemp = [...imageList];
    let imageTemp = {};
    values.forEach((o, i) => {
      imageTemp = {};
      imageTemp.uid = o.id;
      imageTemp.url = oss + o.url;
      imageTemp.filename = o.url;
      imageListTemp.push(imageTemp);
    })
    setImageList(imageListTemp);
  }

  const ship = (values) => {
    // let { pathname } = history.location;;
    // if (beforeOperate(pathname, '/ship')) return;
    shipForm.setFieldsValue({ id: values.id, code: '', expressCompanyId: '' });
    setShipVisible(true);
  }

  const submitShipInfo = () => {
    let data = shipFormRef.current.getFieldsValue();
    if (data.code.length < 5) {
      message.error({
        content: '快递单号太短',
        duration: 5
      })
      return;
    }
    orderShip(data).then(res => {
      if (res.code === 0) {
        message.success({ content: '订单已发货', duration: 5 });
        getOrderList();
        setShipVisible(false);
      }
    })
  }

  const getExpressList = useCallback(() => {
    queryExpressCompanyList().then(res => {
      if (res.code === 0) {
        let data = res.data;
        data.forEach(o => o.key = o.id);
        setExpressList(data)
      }
    })
  }, [])

  const getExpressInfo = o => {
    let data = { expressCompanyId: o.expressCompanyId, num: o.expressCode };
    queryExpress(data).then(res => {
      if (res.code === 0) {
        if (res.data.status === '200') {
          setExpressVisible(true);
          setExpressInfo(res.data.data);
        } else {
          message.error({
            content: res.data.message,
            duration: 5
          })
        }
      }
    })
  }

  const tableOnchange = (pagination, filters) => {
    if (filters.status) {
      let str = '';
      for (var i = 0; i < filters.status.length; i++) {
        str += filters.status[i] + ',';
      }
      if (str.length > 0) {
        str = str.substr(0, str.length - 1);
      }
      setOrderStatus(str);
      setExcelOrderStatus(str);
    } else {
      setOrderStatus();
      setExcelOrderStatus();
    }
  }

  const exportOrderToExcel = () => {
    let ids = '';
    // ids=selectedRowKeys.join(',').toString();
    selectedRowKeys.forEach(o => ids = ids + '&idList=' + o);
    let status = excelOrderStatus ? excelOrderStatus : '';
    let url = manageRequestApi + `order/export/list?&num=${num}&status=${status}&userName=${agentName}${ids}`;
    let token = '';
    if (store.getState().app.token) {
      token = store.getState().app.tokenHead + store.getState().app.token
    }
    axios({
      url: url,
      method: 'GET',
      headers: {
        'Authorization': token
      },
      'responseType': 'blob'
    }).then(res => {
      const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
      //下载文件的名称
      let filename = 'order.xls';
      // const url = window.URL.createObjectURL(new Blob([res.data],
      //   // 设置该文件的mime类型，这里对应的mime类型对应为.xlsx格式                                                   
      //   { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
      // const link = document.createElement('a');
      // link.href = url;
      // // 从header中获取服务端命名的文件名
      // // const fileName = decodeURI(res.headers['filename']);
      // const fileName = 'Order.xls';
      // link.setAttribute('download', fileName);
      // document.body.appendChild(link);
      // link.click()
      let downloadElememt = document.createElement('a');
      let href = '';
      if (window.URL) {
        href = window.URL.createObjectURL(blob);
      } else {
        href = window.webkitURL.createObjectURL(blob);
      }
      downloadElememt.href = href;
      downloadElememt.download = filename;
      document.body.appendChild(downloadElememt);
      downloadElememt.click();
      if (window.URL) {
        window.URL.revokeObjectURL(href);
      } else {
        window.webkitURL.revokeObjectURL(href);
      }
    })
  }

  // const statusSelectOnChange = info => {
  //   setExcelOrderStatus(info);
  //   setOrderStatus(info);
  // }

  const uploadShipProps = {
    action: manageRequestApi + "excel/importShip",
    withCredentials: true,
    showUploadList: false,
    headers: { Authorization: store.getState().app.tokenHead + store.getState().app.token },
    onChange: (info) => {
      if (info.file.status === 'done') {
        if (info.file.response.code === 0) {
          getOrderList();
          message.success('导入成功');
        } else if (info.file.response.code === 1) {
          message.error({ content: info.file.response.msg, duration: 5 })
        }
      }
      // const event = info.event;
      // if (event) {
      //   setBtnLoading(true);
      // }
      // if (info.file.status === 'done') {
      //   setCurrent(1);
      //   setBtnLoading(false);
      //   console.log(info);
      //   saveMaterial({ name: info.file.name, url: info.file.response.data }).then(res => {
      //     if (res.code === 0) getMaterialList(page, size);
      //   })
      // } else if (info.file.status === 'error') {
      //   console.log('error')
      // }
    },
    beforeUpload: (file) => {
      // if (file.size > 3 * 1024 * 1024) {
      //   message.error({ content: '上传图片大小不超过3M,请重新选择图片', duration: 5 });
      //   return false;
      // }
    }
  }

  const changeAgent = info => {
    setAgentName(info)
  }

  const getAgentList = useCallback((value = '') => {
    setLoading(true);
    queryUserList({ page: 0, size: 1000000, id: '', value: value }).then(res => {
      if (res.code === 0) {
        let data = res.data.content;
        data.forEach(o => o.key = o.id);
        setAgentList(data);
      }
    })
  }, [])

  useEffect(() => {
    getOrderList();
    getExpressList();
    getAgentList();
  }, [getOrderList, getExpressList, getAgentList])

  return (
    <div>
      <div className="top-area">
        <div className="btn-container">
          <Input allowClear style={{ width: '160px', marginRight: '20px' }} placeholder="请输入订单编号" onBlur={assignNum} />
          <Select
            allowClear
            showSearch
            style={{ width: 140, marginRight: 20 }}
            onChange={changeAgent}
            placeholder="选择代理人"
          >
            {
              agentList.map(o => <Option key={o.id} value={o.username}>{o.username}</Option>)
            }
          </Select>
          <Button type="primary" onClick={() => getOrderList()}>搜索</Button>
        </div>
        <div className="search-container">
          <Button style={{ marginRight: '20px' }} type="primary" onClick={() => exportOrderToExcel()}>导出订单</Button>
          <Upload {...uploadShipProps}>
            <Button type="primary" style={{ color: 'white', background: '#6699FF', border: 'none' }}>导入发货单</Button>
          </Upload>
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={orderList}
        bordered
        onChange={(pagnation, filter) => tableOnchange(pagnation, filter)}
        loading={loading}
        rowSelection={rowSelection}
        pagination={{
          pageSize: size,
          defaultPageSize: size,
          total: total,
          showSizeChanger: false,
          onChange: pageChange,
          showTotal: (total, range) => `订单总数：${total}条`
        }}
      />
      <Modal
        forceRender
        getContainer
        visible={refundVisible}
        onCancel={() => setRefundVisible(false)}
        onOk={() => submitRefundInfo()}
        title="退款截图"
        okText="确认退款"
        width="735px"
      >
        <Form {...FormLayOut} ref={refundFormRef} form={refundForm}>
          <Form.Item hidden label="id" name="id">
            <Input />
          </Form.Item>
          <div className={styles['display-flex-align-left']}>
            <div style={{ width: imageList.length * 112 + 'px' }}>
              {
                imageList && imageList.length !== 0 ?
                  <Upload
                    listType="picture-card"
                    fileList={imageList}
                    onRemove={imageListOnRemove}
                  ></Upload>
                  :
                  ''
              }
            </div>
            {
              imageList && imageList.length < 6 ?
                <div className={imageList && imageList.length !== 0 ? styles['materialContainer'] : ''}>
                  <MaterialLibrary maxChooseNum={6 - imageList.length} setImages={setRefundImageList} />
                </div>
                :
                ''
            }
          </div>
        </Form>
      </Modal>
      <Modal
        forceRender
        getContainer
        visible={checkImageVisible}
        onCancel={() => setCheckImageVisible(false)}
        onOk={() => submitReImageInfo()}
        title="退款截图"
        width="735px"
      >
        <Form {...FormLayOut} ref={checkImageFormRef} form={checkImageForm}>
          <Form.Item hidden label="id" name="id">
            <Input />
          </Form.Item>
        </Form>
        <div className={styles['display-flex-align-left']}>
          <div style={{ width: imageList.length * 112 + 'px' }}>
            {
              imageList && imageList.length !== 0 ?
                <Upload
                  listType="picture-card"
                  fileList={imageList}
                  onRemove={imageListOnRemove}
                ></Upload>
                :
                ''
            }
          </div>
          {
            imageList && imageList.length < 6 ?
              <div className={imageList && imageList.length !== 0 ? styles['materialContainer'] : ''}>
                <MaterialLibrary maxChooseNum={6 - imageList.length} setImages={setImageLists} />
              </div>
              :
              ''
          }
        </div>
      </Modal>
      <Modal
        visible={shipVisible}
        onCancel={() => setShipVisible(false)}
        onOk={() => submitShipInfo()}
        title="订单发货"
        okText="确认发货"
      >
        <Form {...FormLayOut} ref={shipFormRef} form={shipForm}>
          <Form.Item hidden label="id" name="id">
            <Input />
          </Form.Item>
          <Form.Item label="快递单号" name="code">
            <Input />
          </Form.Item>
          <Form.Item label="快递公司" name="expressCompanyId">
            <Select>
              {
                expressList.map(o =>
                  o.status !== 0 ?
                    <Select.Option key={o.id} value={o.id}>{o.name}</Select.Option>
                    :
                    <Select.Option key={o.id} value={o.id} disabled>{o.name}</Select.Option>
                )
              }
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        visible={expressVisible}
        title="快递信息"
        onCancel={() => setExpressVisible(false)}
        footer={[
          <Button key="sure" onClick={() => setExpressVisible(false)}>确认</Button>
        ]}
      >
        <Timeline mode={'left'}>
          {
            expressInfo.map(o =>
              <Timeline.Item key={o.time}>{o.time} - {o.context}</Timeline.Item>
            )
          }
        </Timeline>
      </Modal>
    </div>
  )
}

export default MainOrder;
