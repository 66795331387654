import React, { useState, useEffect, useCallback } from 'react';
import { queryUserOrderAmount } from '@/api/report';
import { Table, Input, Button, Select } from 'antd';
import { toExcel } from '@/utils/toExcel';
import { queryUserList } from '@/api/user';
import { queryAllListByChildren } from '@/api/category'
import { handlePrice } from '@/utils/priceFormat';
const Option = Select.Option;

const AgentAmountRecharge = () => {

  const [list, setList] = useState([]);
  const [page, setPage] = useState(0);
  const [size] = useState(50);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState('');
  let [year, setYear] = useState('全部');
  const [inviteId, setInviteId] = useState('');
  const [agentList, setAgentList] = useState([]);
  const [maxHeight] = useState(document.documentElement.clientHeight - 252);
  const yearList = ['全部', '2020', '2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029'];
  const [categoryId, setCategoryId] = useState('');
  const [category, setCategory] = useState([]);

  const columns = [
    {
      dataIndex: 'username',
      title: '代理人',
      align: 'center',
      width: 200,
      fixed: 'left'
    },
    {
      dataIndex: 'invitePeople',
      title: '邀请人',
      align: 'center',
      width: 200
    },
    {
      dataIndex: 'one',
      title: '一月',
      align: 'center',
      width: 150,
      render: one => <div className="text-align-right">¥{handlePrice(one)}</div>,
      sorter: (a, b) => a.one - b.one,
    },
    {
      dataIndex: 'two',
      title: '二月',
      align: 'center',
      width: 150,
      render: two => <div className="text-align-right">¥{handlePrice(two)}</div>,
      sorter: (a, b) => a.two - b.two,
    },
    {
      dataIndex: 'three',
      title: '三月',
      align: 'center',
      width: 150,
      render: three => <div className="text-align-right">¥{handlePrice(three)}</div>,
      sorter: (a, b) => a.three - b.three,
    },
    {
      dataIndex: 'four',
      title: '四月',
      align: 'center',
      width: 150,
      render: four => <div className="text-align-right">¥{handlePrice(four)}</div>,
      sorter: (a, b) => a.four - b.four,
    },
    {
      dataIndex: 'five',
      title: '五月',
      align: 'center',
      width: 150,
      render: five => <div className="text-align-right">¥{handlePrice(five)}</div>,
      sorter: (a, b) => a.five - b.five,
    },
    {
      dataIndex: 'six',
      title: '六月',
      align: 'center',
      width: 150,
      render: six => <div className="text-align-right">¥{handlePrice(six)}</div>,
      sorter: (a, b) => a.six - b.six,
    },
    {
      dataIndex: 'seven',
      title: '七月',
      align: 'center',
      width: 150,
      render: seven => <div className="text-align-right">¥{handlePrice(seven)}</div>,
      sorter: (a, b) => a.seven - b.seven,
    },
    {
      dataIndex: 'eight',
      title: '八月',
      align: 'center',
      width: 150,
      render: eight => <div className="text-align-right">¥{handlePrice(eight)}</div>,
      sorter: (a, b) => a.eight - b.eight,
    },
    {
      dataIndex: 'nine',
      title: '九月',
      align: 'center',
      width: 150,
      render: nine => <div className="text-align-right">¥{handlePrice(nine)}</div>,
      sorter: (a, b) => a.nine - b.nine,
    },
    {
      dataIndex: 'ten',
      title: '十月',
      align: 'center',
      width: 150,
      render: ten => <div className="text-align-right">¥{handlePrice(ten)}</div>,
      sorter: (a, b) => a.ten - b.ten,
    },
    {
      dataIndex: 'eleven',
      title: '十一月',
      align: 'center',
      width: 150,
      render: eleven => <div className="text-align-right">¥{handlePrice(eleven)}</div>,
      sorter: (a, b) => a.eleven - b.eleven,
    },
    {
      dataIndex: 'twelve',
      title: '十二月',
      align: 'center',
      width: 150,
      render: twelve => <div className="text-align-right">¥{handlePrice(twelve)}</div>,
      sorter: (a, b) => a.twelve - b.twelve,
    },
    {
      dataIndex: 'total',
      title: '当年合计',
      align: 'center',
      width: 150,
      fixed: 'right',
      render: total => <div className="text-align-right">¥{handlePrice(total)}</div>,
      sorter: (a, b) => a.total - b.total,
    }
  ]

  const getList = useCallback((username = '', year = '', inviteId = '', categoryId = '') => {
    if(year === '全部') year = ''
    queryUserOrderAmount({ page, size, username, year, inviteId, categoryId }).then(res => {
      if (res.code === 0) {
        let data = res.data.content;
        data.forEach(o => o.key = o.id);
        setList(data);
        setTotal(res.data.totalElements);
        setLoading(false);
      }
    })
  }, [page, size])

  const getAgentList = useCallback((value = '') => {
    setLoading(true);
    queryUserList({ page: '', size: '', id: '', value }).then(res => {
      if (res.code === 0) {
        let data = res.data.content;
        data.forEach(o => o.key = o.id);
        setLoading(false);
        setAgentList(data);
      }
    })
  }, [])

  const exportReport = () => {
    if(year === '全部') year = ''
    let url = `report/userOrderAmount/export?page=${page}&size=${size}&username=${username}&year=${year}&inviteId=${inviteId}&categoryId=${categoryId}`;
    let fileName = `代理订单金额报表.xls`;
    toExcel(url, fileName);
  }

  const queryAllCategory = () => {
    queryAllListByChildren().then(res => {
      setCategory(res.data)
    })
  }

  const assignAgentName = e => {
    setUsername(e.target.value);
  }

  const assignYear = val => {
    setYear(val);
  }

  const assignInviteId = val => {
    console.log(val)
    setInviteId(val);
  }

  const pageChange = page => {
    setPage(page - 1)
  }

  const changeCategoryId = info => {
    console.log(info)
    setCategoryId(info);
  }

  useEffect(() => {
    getList();
    getAgentList();
    queryAllCategory();
  }, [getList, getAgentList])

  return (
    <>
      <div className="top-area">
        <div className="btn-container">
          <Button type="primary" onClick={() => exportReport()}>导出报表</Button>
        </div>
        <div className="search-container">
          <Select allowClear style={{ width: 140, marginRight: 20 }} onChange={changeCategoryId} placeholder="选择类目">
            {
              category.map(o => <Option key={o.id} value={o.id}>{o.name}</Option>)
            }
          </Select>
          <Input allowClear style={{ width: '160px', marginRight: '20px' }} placeholder="请输入代理人名称" onBlur={assignAgentName} />
          <Select allowClear style={{ width: '160px', marginRight: '20px' }} placeholder="请选择邀请人" onChange={assignInviteId} >
            <Select.Option key={0} value={0}>总部邀请</Select.Option>
            {
              agentList.map(o => <Select.Option key={o.id} value={o.id}>{o.username}</Select.Option>)
            }
          </Select>
          <Select placeholder="请选择年份" style={{ width: '160px', marginRight: '20px' }} onChange={assignYear} defaultValue={year}>
            {
              yearList.map((o, i) => <Select.Option key={i} value={o === '全部' ? '' : o}>{o}</Select.Option>)
            }
          </Select>
          <Button type="primary" onClick={() => getList(username, year, inviteId, categoryId)}>搜索</Button>
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={list}
        bordered
        loading={loading}
        scroll={{ x: '1650px', y: maxHeight + 'px' }}
        pagination={{
          pageSize: size,
          defaultPageSize: size,
          total: total,
          showSizeChanger: false,
          onChange: pageChange,
          showTotal: total => `数据总数：${total}条`
        }}
      />
    </>
  )
}

export default AgentAmountRecharge;