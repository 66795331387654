import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Table } from 'antd';
import { queryUserList } from '@/api/user';
import { getLabel } from '@/utils/tools';

const AgentSubList = () => {

  const { id } = useParams();
  const [page, setPage] = useState(0);
  const [size] = useState(20);
  const [loading, setLoading] = useState(false);
  const [agentList, setAgentList] = useState([]);
  const [total, setTotal] = useState(0);
  const agentStatus = ['锁定', '正常'];

  const columns = [
    {
      title: '个人信息',
      align: 'center',
      width: '200px',
      render: o => <div><div>{o.username}</div><div>{o.phoneNumber}</div><div>{o.createdDate}</div></div>
    },
    {
      title: '等级信息',
      align: 'center',
      width: '180px',
      render: o =>
        <div>
          <div>代理等级：{o.level}</div>
          <div>代理名称：{o.levelName}</div>
        </div>
    },
    {
      title: '数据',
      align: 'center',
      width: '180px',
      render: o => <div><div>当前金额：{o.balance}</div><div>订单业绩：{o.achievement}</div></div>
    },
    {
      title: '状态',
      align: 'center',
      width: '150px',
      render: o => getLabel(o.status, agentStatus)
      // <div>
      //   <Button onClick={() => chagneAgentStatus(o)}>{getLabel(o.status, agentStatus)}
      //   </Button>
      // </div>
    },
    // {
    //   title: '操作',
    //   align: 'center',
    //   width: '250px',
    //   render: (o) => {
    //     return (
    //       <Space size="middle" className="operationCloumn">
    //         <Button style={{ color: 'white', background: '#FF99CC', border: 'none' }} type="primary" size="small" onClick={() => { querySubList(o) }}>下级列表</Button>
    //         <Button type="primary" size="small" onClick={() => { editAgent(o) }}>编辑</Button>
    //         <Button type="primary" size="small" danger onClick={() => { generateCode(o) }}>生成验证码</Button>
    //         {/* <Popconfirm title="确认删除?" onConfirm={() => deleteAgent(o)}>
    //           <Button>删除</Button>
    //         </Popconfirm> */}
    //       </Space>
    //     )
    //   }
    // }
  ];

  const getAgentList = useCallback(() => {
    setLoading(true);
    queryUserList({ page, size, id }).then(res => {
      if (res.code === 0) {
        setTotal(res.data.totalElements);
        let data = res.data.content;
        data.forEach(o => o.key = o.id);
        setLoading(false);
        setAgentList(data);
      }
    })
  }, [page, size, id]);

  const pageChange = (page) => {
    setPage(page - 1);
  }

  useEffect(() => {
    getAgentList();
  }, [getAgentList])

  return <>
    <Table
      loading={loading}
      columns={columns}
      dataSource={agentList}
      bordered
      pagination={{ pageSize: size, defaultPageSize: size, total: total, showSizeChanger: false, onChange: pageChange }}
    />
  </>
}

export default AgentSubList;