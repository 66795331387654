import request from '@/utils/manageRequest';

export const saveMenu = (data) => {
  return request({
    url: `menu/super/save`,
    method: 'post',
    data
  })
}

export const deleteMenu = (data) => {
  return request({
    url: `menu/super/delete`,
    params: data,
    method: 'post'
  })
}

export const queryMenuList = (data) => {
  return request({
    url: `menu/super/treeList`,
    params: data,
    method: 'get'
  })
}

export const queryAllResource = () => {
  return request({
    url: `resource/super/findAll`,
    method: 'get'
  })
}

export const queryResourceByMenuId = (data) => {
  return request({
    url: `resource/super/findByMenuId?menuId=${data.id}`,
    method: 'get'
  })
}

export const saveResource = (data) => {
  return request({
    url: `resource/super/save`,
    method: 'post',
    data
  })
}

export const saveResourceToMenu = (data) => {
  return request({
    url: `resource/super/addResourceToMenu?menuId=${data.menuId}&resourceId=${data.resourceId}`,
    method: 'post'
  })
}

export const deleteResource = (data) => {
  return request({
    url: `resource/super/delete`,
    method: 'post',
    params: data
  })
}

export const deleteResourceFromMenu = (data) => {
  return request({
    url: `resource/super/deleteResourceFromMenu`,
    method: 'post',
    params: data
  })
}