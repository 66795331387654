import React, { useState, useEffect, useRef } from 'react';
import { queryAllResource, saveResource, deleteResource } from '@/api/menu';
import { Table, Button, Modal, Form, Input, message, Space, Popconfirm } from 'antd';
import { FormLayOut } from '@/utils/common';

const Modular = () => {

  const [resourceList, setResourceList] = useState([]);
  const [visible, setVisibile] = useState(false);
  const [opLabel, setOpLabel] = useState();

  const [form] = Form.useForm();
  const formRef = useRef();

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 100,
      align: 'center'
    },
    {
      title: '资源名称',
      dataIndex: 'name',
      width: 200,
      align: 'center'
    },
    {
      title: '路径',
      dataIndex: 'url',
      width: 200,
      align: 'center'
    },
    {
      title: '操作',
      width: 200,
      align: 'center',
      render: o =>
        <Space size="middle" className="operationCloumn">
          <Button type="primary" size="small" onClick={() => { editResource(o) }}>编辑</Button>
          <Popconfirm title="确认删除?" onConfirm={() => delResource(o)}>
            <Button type="primary" size="small" danger>删除</Button>
          </Popconfirm>
        </Space>
    }
  ];

  const getAllResource = () => {
    queryAllResource().then(res => {
      if (res.code === 0) {
        let data = res.data;
        data.forEach(o => o.key = o.id);
        setResourceList(data);
      }
    })
  }

  const addRerouce = () => {
    form.setFieldsValue({ id: '', name: '', url: '', description: '' });
    form.resetFields(['id', 'name', 'url', 'description']);
    setOpLabel('新增');
    setVisibile(true);
  }

  const submitForm = () => {
    // let data = formRef.current.getFieldsValue();
    form.validateFields().then(data => {
      saveResource(data).then(res => {
        if (res.code === 0) {
          getAllResource();
          setVisibile(false);
        }
      })
    }).catch(err => {
      message.error({
        content: '请补全信息',
        duration: 5
      })
    })
  }

  const editResource = o => {
    form.setFieldsValue(o);
    setOpLabel('编辑');
    setVisibile(true);
  }

  const delResource = o => {
    deleteResource({ id: o.id }).then(res => {
      if (res.code === 0) {
        getAllResource();
      }
    })
  }

  useEffect(() => {
    getAllResource();
  }, [])

  return (
    <div>
      <div className="top-area">
        <div className="btn-container">
          <Button type="primary" onClick={() => addRerouce()}>新增资源</Button></div>
      </div>
      <Table
        columns={columns}
        dataSource={resourceList}
        bordered
      />
      <Modal
        visible={visible}
        onCancel={() => setVisibile(false)}
        onOk={() => submitForm()}
        title={opLabel + '资源'}
      >
        <Form
          {...FormLayOut}
          form={form}
          ref={formRef}
        >
          <Form.Item hidden label="id" name="id">
            <Input />
          </Form.Item>
          <Form.Item
            label="资源名称"
            name="name"
            rules={[{ required: true, message: '请输入资源名称' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="资源路径"
            name="url"
            rules={[{ required: true, message: '请输入资源路径' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="资源描述"
            name="description"
          >
            <Input.TextArea rows={4} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default Modular;