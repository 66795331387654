import request from '@/utils/manageRequest'

export const saveCategoryList = (data) => {
  return request({
    url: `productCategory/save`,
    data,
    method: 'post'
  })
}

export const delCategoryList = (data) => {
  return request({
    url: `productCategory/delete`,
    params: data,
    method: 'delete'
  })
}

export const queryCategoryList = (data) => {
  return request({
    url: `productCategory/list`,
    method: 'get',
    params: data
  })
}

export const queryCategoryChildList = (data) => {
  return request({
    url:`productCategory/childList?id=${data.id}`,
    method: 'get'
  })
}

export const updateCategoryStatus = (data) => {
  return request({
    url:`productCategory/status`,
    params: data,
    method: 'post'
  })
}

export const queryAllParentCategories = (data) => {
  return request({
    url: 'productCategory/listByParent',
    params: data,
    method: 'get'
  })
}

export const queryAllListByChildren = () =>
  request({
    url: 'productCategory/listByChildren',
    method: 'get'
  })