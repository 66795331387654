import request from '@/utils/manageRequest'

export const login = (username, password) => {
  return request({
    url: 'admin/signIn',
    params: {
      username,
      password
    },
    method: 'post'
  })
}

export const updateAdmin = (data) => {
  return request({
    url: `admin/update`,
    method: 'post',
    data
  })
}

export const saveAdmin = (data) => {
  return request({
    url: `admin/save`,
    method: 'post',
    data
  })
}

export const queryUserList = (data) => {
  return request({
    url: `user/findList?id=${data.id}&page=${data.page}&size=${data.size}&value=${data.value}`,
    data,
    method: 'post'
  })
}

export const queryReceiveInfoList = () => {
  return request({
    url: 'companyReceiveInfo',
    method: 'get'
  })
}

export const saveReceiveInfo = (data) => {
  return request({
    url: 'companyReceiveInfo',
    method: 'post',
    data
  })
}

export const queryAdminList = (data) => {
  return request({
    url: 'admin/list',
    method: 'get'
  })
}

export const changeAdminStatus = (data) => {
  return request({
    url: `admin/changeStatus?userId=${data.id}&status=${data.status}`,
    method: 'post'
  })
}

export const queryCreatedRole = () => {
  return request({
    url: `role/findCreatedRole`,
    method: 'post'
  })
}

export const saveRole = (data) => {
  return request({
    url: 'role/save',
    method: 'post',
    data
  })
}

export const deleteRole = (data) => {
  return request({
    url: `role/delete?id=${data.id}`,
    method: 'post'
  })
}

export const queryRoleDetail = (data) => {
  return request({
    url: `role/roleEdit?roleId=${data.id}`,
    method: 'get'
  })
}

export const queryUserByName = (data) => {
  return request({
    url: `user/findUserByName?name=${data.name}`,
    method: 'get'
  })
}

export const queryUserByPhone = (data) => {
  return request({
    url: `user/findUserByPhone?phone=${data.phone}`,
    method: 'get'
  })
}