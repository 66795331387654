import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Button, Table, Space, Popconfirm } from 'antd';
import { ContainerStyle } from '@/utils/common';
import { queryBrandList, delBrand, saveBrand } from '@/api/brand';
import BrandModalForm from './components/BrandModalForm';
import { getLabel } from '@/utils/tools';
import { oss } from '@/utils/publicPath';
import styles from '@/styles/pages/product.module.scss';
import { DisplayImages } from '@/utils/common';

const Brand = () => {
  const firstValues = { id: '', name: '', code: '', logo: '', image: '', about: '', status: 1 }

  // set state
  const [page, setPage] = useState(0);
  const [size] = useState(20);
  const [total, setTotal] = useState(0);
  const [brandList, setBrandList] = useState([]);
  const [actionType, setActionType] = useState('');
  const [visible, setVisible] = useState(false);
  const [initValues, setInitValues] = useState(firstValues);
  const [loading, setLoading] = useState(false)

  const statusDict = ['隐藏', '显示'];
  const BrandColumns = [
    {
      title: '品牌主图',
      dataIndex: 'image',
      width: '150px',
      align: 'center',
      render: value =>
        <div className={styles['dispaly-flex']}>
          <div className={styles['imageContainer']}>
            {/* <img alt="example" src={oss + value} style={DisplayImages} /> */}
            {value ?
              <img alt="example" src={oss + value} style={DisplayImages} />
              :
              <img alt="" src={oss + 'material/on_error.png'} style={DisplayImages} />
            }
          </div>
        </div>
    },
    {
      title: '品牌名称',
      dataIndex: 'name',
      width: '200px',
      align: 'center'
    },
    {
      title: '品牌logo',
      dataIndex: 'logo',
      width: '150px',
      align: 'center',
      render: value =>
        <div className={styles['dispaly-flex']}>

          {value ?
            <div className={styles['logoContainer']}>
              <img alt="example" src={oss + value} />
            </div>
            :
            <div className={styles['imageContainer']}>
              <img alt="" src={oss + 'material/on_error.png'} />
            </div>
          }
        </div>
    },
    {
      title: '品牌code',
      dataIndex: 'code',
      width: '200px',
      align: 'center',
    },
    {
      title: '关于品牌',
      dataIndex: 'about',
      width: '300px',
      align: 'center',
      render: o =>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', wordBreak: 'break-word', height: '100px', overflow: 'auto', flexWrap: 'wrap' }}>{o}</div>
    },
    {
      title: '状态',
      dataIndex: 'status',
      width: '100px',
      align: 'center',
      render: val => getLabel(val, statusDict)
    },
    {
      title: '操作',
      width: '120px',
      align: 'center',
      render: (o) => {
        return (
          <Space size="middle" className="operationCloumn">
            <Button type="primary" size="small" onClick={() => editBrand(o)}>编辑</Button>
            <Popconfirm title="确认删除?" onConfirm={() => delBrands(o)}>
              <Button type="primary" danger size="small">删除</Button>
            </Popconfirm>
          </Space>
        )
      }
    }
  ]

  // get Brand List
  const queryBrand = useCallback(() => {
    setLoading(true);
    queryBrandList({ page: page, size: size }).then(res => {
      if (res.code === 0) {
        let data = res.data.content;
        setTotal(res.data.totalElements);
        data.map(o => o.key = o.id);
        setBrandList(data);
        setLoading(false);
      }
    });
  }, [page, size]);

  // control Modal visible
  const chageModal = (status, actionType = '') => { setVisible(status); setActionType(actionType) }

  const modalOk = (values) => {
    let data = values;
    if (!data.id) data.id = null;
    setLoading(true);
    saveBrand(data).then(res => {
      if (res.code === 0) {
        queryBrand();
        setVisible(false);
        setLoading(false);
      }
    })
  }

  const addBrand = () => {
    setInitValues(firstValues);
    chageModal(true, '新增');
  }

  //edit brand
  const editBrand = (o) => {
    console.log(o);
    setInitValues(o);
    chageModal(true, '编辑');
  }

  //del brand
  const delBrands = (o) => {
    console.log(o)
    delBrand({ id: o.id }).then(res => {
      if (res.code === 0) {
        queryBrand()
      }
    })
  }

  const pageChange = (page) => {
    setPage(page - 1);
  }

  // simulation componentDidMount
  useEffect(() => {
    queryBrand();
  }, [queryBrand]);


  // render
  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col span={6}><Button type="primary" onClick={addBrand}>新增品牌</Button></Col>
      </Row>
      <div style={ContainerStyle}>
        <Table
          columns={BrandColumns}
          dataSource={brandList}
          bordered
          loading={loading}
          pagination={{
            pageSize: size,
            defaultPageSize: size,
            total: total,
            onChange: pageChange,
            showSizeChanger: false,
            showTotal: total => `总数据：${total} 条`
          }}
        />
      </div>
      <BrandModalForm visible={visible} initValues={initValues} actionType={actionType} modalOk={modalOk} closeModal={() => chageModal(false)} queryBrandList={() => queryBrand()} closeLoading={() => setLoading(false)} openLoading={() => setLoading(true)} />
    </div>
  )
}

export default Brand;
