import React, { useState, useEffect } from 'react';
import { Modal, Table, Popconfirm, Button, Space } from 'antd';
import { DisplayImages } from '@/utils/common';
import { oss } from '@/utils/publicPath';
import { getLabel } from '@/utils/tools';
import { delCategoryList, updateCategoryStatus, queryCategoryChildList, saveCategoryList, queryAllParentCategories } from '@/api/category';
import CategoryModalForm from './CategoryModalForm';
import styles from '@/styles/pages/category.module.scss';

const statusDict = ['隐藏', '显示'];

const homeShowDict = ['否', '是'];

const ChildCategoryTableModal = (props) => {

  var firstValues = { name: '', image: '', rank: '', isHomeShow: 1, level: 0, parentId: '', status: 1 };

  const [childCategoryList, setChildCategoryList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [actionType, setActionType] = useState('');
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initValues, setInitValues] = useState(firstValues);

  const { childCategoryVisible, closeModal, childCategoryId } = props;

  const columns = [
    {
      title: '分类图片',
      dataIndex: 'image',
      width: '150px',
      align: 'center',
      className: 'imageStyle',
      render: value =>
        <div className={styles['dispaly-flex']}>
          <div className={styles['imageContainer']}>
            <img alt="example" src={oss + value} style={DisplayImages} />
          </div>
        </div>
    },
    {
      title: '分类名称',
      dataIndex: 'name',
      width: '200px',
      align: 'center'
    },
    {
      title: '分类排序',
      dataIndex: 'rank',
      width: '100px',
      align: 'center',
      sorter: o => o.rank
    },
    {
      title: '首页显示',
      dataIndex: 'isHomeShow',
      width: '100px',
      align: 'center',
      render: (val) => getLabel(val, homeShowDict)
    },
    {
      title: '状态',
      dataIndex: 'status',
      width: '100px',
      align: 'center',
      render: (val, item) => {
        return (
          <Popconfirm title="确认修改状态?" onConfirm={() => changeCategoryStatus(item)}>
            <Button size="small" className={val === 1 ? styles['hideStatus'] : styles['showStatus']}>{getLabel(val, statusDict)}</Button>
          </Popconfirm >
        )
      }
    },
    {
      title: '操作',
      width: '150px',
      align: 'center',
      render: (o) => {
        return (
          <Space size="middle" className="operationCloumn">
            <Button type="primary" size="small" onClick={() => { editCategory(o) }}>编辑</Button>
            <Popconfirm title="确认删除?" onConfirm={() => delCategory(o)}>
              <Button type="primary" size="small" danger>删除</Button>
            </Popconfirm>
          </Space>
        )
      }
    }
  ];

  const getChildCategoryList = (id) => {
    setLoading(true);
    queryCategoryChildList({ id: id }).then(res => {
      let data = res.data;
      data.forEach(o => o.key = o.id);
      setLoading(false);
      setChildCategoryList(data);
    })
    queryAllParentCategories().then(res => {
      let data = res.data;
      data.forEach(o => o.key = o.id);
      setLoading(false);
      setCategoryList(data);
    })
  }

  const changeCategoryStatus = (item) => {
    console.log(item);
    let status = -1;
    if (item.status === 1) {
      status = 0;
    } else {
      status = 1
    }
    updateCategoryStatus({ id: item.id, status: status }).then(res => {
      if (res.code === 0) getChildCategoryList(childCategoryId);
    })
  }

  const editCategory = (o) => {
    if (o.parentId === 0) o.level = 0;
    else o.level = 1;
    setActionType('edit');
    setInitValues(o);
    setVisible(true);
  }

  const delCategory = (o) => {
    delCategoryList({ id: o.id }).then(res => {
      if (res.code === 0) {
        getChildCategoryList(childCategoryId);
      }
    })
  }

  const modalOk = (values) => {
    if (!values.parentId) values.parentId = 0;
    if (!values.id) values.id = '';
    saveCategoryList(values).then(res => {
      if (res.code === 0) {
        getChildCategoryList(childCategoryId);
        setVisible(false);
      }
    })
  }

  useEffect(() => {
    if (childCategoryVisible)
      getChildCategoryList(childCategoryId)
  }, [childCategoryId, childCategoryVisible])

  return (
    <div>
      <Modal forceRender visible={childCategoryVisible} onCancel={closeModal} footer={[]} title="子分类列表" width="1300px">
        <Table
          loading={loading}
          columns={columns}
          dataSource={childCategoryList}
          bordered
          scroll={{ y: 480 }}
          pagination={false}
        />
        <CategoryModalForm visible={visible} actionType={actionType} categoryList={categoryList} closeModal={() => setVisible(false)} initValues={initValues} modalOk={modalOk} />
      </Modal>

    </div>
  )
}

export default ChildCategoryTableModal;