import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Button, Table, Space, Popconfirm } from 'antd';
import { queryCategoryList, saveCategoryList, delCategoryList, updateCategoryStatus } from '@/api/category';
import { getLabel } from '@/utils/tools';
import { oss } from '@/utils/publicPath';
import ChildCategoryTableModal from './components/ChildCategoryTableModal';
import CategoryModalForm from './components/CategoryModalForm';
import styles from '@/styles/pages/category.module.scss';

const statusDict = ['隐藏', '显示'];

const homeShowDict = ['否', '是'];

const Category = () => {
  var firstValues = { id: '', name: '', image: '', rank: 0, isHomeShow: 1, level: 0, parentId: '', status: 1 };

  const [page, setPage] = useState(0);
  const [size] = useState(20);
  const [total, setTotal] = useState(0);
  const [categoryList, setCategoryList] = useState([]);
  const [actionType, setActionType] = useState('');
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initValues, setInitValues] = useState(firstValues);
  const [childCategoryVisible, setChildCategoryVisible] = useState(false);
  const [childCategoryId, setChildCategoryId] = useState();

  const columns = [
    {
      title: '分类图片',
      dataIndex: 'image',
      width: '150px',
      align: 'center',
      render: value =>
        <div className={styles['dispaly-flex']}>
          <div className={styles['imageContainer']}>
            {value ?
              <img alt="example" src={oss + value} />
              :
              <img alt="" src={oss + 'material/on_error.png'} />
            }
          </div>
        </div>
    },
    {
      title: '分类Logo',
      dataIndex: 'logo',
      width: '150px',
      align: 'center',
      render: value =>
        <div className={styles['dispaly-flex']}>
          {value ?
            <div className={styles['logoContainer']}>
              <img alt="example" src={oss + value} />
            </div>
            :
            <div className={styles['imageContainer']}>
              <img alt="" src={oss + 'material/on_error.png'} />
            </div>
          }
        </div>
    },
    {
      title: '分类名称',
      dataIndex: 'name',
      width: '200px',
      align: 'center'
    },
    {
      title: '分类排序',
      dataIndex: 'rank',
      width: '100px',
      align: 'center',
      sorter: o => o.rank,
    },
    {
      title: '首页显示',
      dataIndex: 'isHomeShow',
      width: '100px',
      align: 'center',
      render: (val) => getLabel(val, homeShowDict)
    },
    {
      title: '状态',
      dataIndex: 'status',
      width: '100px',
      align: 'center',
      render: (val, item) => {
        return (
          <Popconfirm title="确认修改状态?" onConfirm={() => changeCategoryStatus(item)}>
            <Button size="small" className={val === 1 ? styles['hideStatus'] : styles['showStatus']}>{getLabel(val, statusDict)}</Button>
          </Popconfirm >
        )
      }
    },
    {
      title: '操作',
      width: '180px',
      align: 'center',
      render: (o) => {
        return (
          <Space size="middle" className="operationCloumn">
            {
              o.childCategoryNum > 0 ?
                <Button style={{ color: 'white', background: '#FF99CC', border: 'none' }} size="small" onClick={() => { openChildCategoryModal(o) }}>子分类</Button> : ''
            }
            <Button type="primary" size="small" onClick={() => { editCategory(o) }}>编辑</Button>
            <Popconfirm title="确认删除?" onConfirm={() => delCategory(o)}>
              <Button type="primary" size="small" danger>删除</Button>
            </Popconfirm>
          </Space>
        )
      }
    }
  ];

  // const expandable = {
  //   onExpand: (expanded, record) => {
  //     if (expanded) {
  //       queryCategoryChildList({ id: record.id }).then(res => {
  //         let data = res.data;
  //         data.forEach(o => o.key = o.id);
  //         Object.assign(secData, { [record.id]: data });
  //         setSecData(secData);
  //         expandable.expandedRowRender(record);
  //       })
  //     }
  //   },
  //   expandedRowRender: (record, index, indent, expanded) => {
  //     // console.log(secData, 2234);
  //     console.log(secData);
  //     return <Table columns={columns} dataSource={secData[record.id]} pagination={false} />
  //   }
  // }

  const getCategoryList = useCallback(() => {
    setLoading(true);
    queryCategoryList({ page: page, size: size }).then(res => {
      if (res.code === 0) {
        let data = res.data.content;
        setTotal(res.data.totalElements);
        data.forEach(o => o.key = o.id);
        setCategoryList(data);
        setLoading(false);
      }
    })
  }, [page, size])

  const addCategory = () => {
    setActionType('add');
    setVisible(true);
    setInitValues(firstValues);
  }

  const modalOk = (values) => {
    if (!values.parentId) values.parentId = 0;
    if (!values.id) values.id = '';
    saveCategoryList(values).then(res => {
      if (res.code === 0) {
        getCategoryList();
        setVisible(false);
      }
    })
  }

  const delCategory = (o) => {
    delCategoryList({ id: o.id }).then(res => {
      if (res.code === 0) {
        getCategoryList();
      }
    })
  }

  const changeCategoryStatus = (item) => {
    let status = -1;
    if (item.status === 1) {
      status = 0;
    } else {
      status = 1
    }
    updateCategoryStatus({ id: item.id, status: status }).then(res => {
      if (res.code === 0) getCategoryList();
    })
  }

  const editCategory = (o) => {
    if (o.parentId === 0) o.level = 0;
    setActionType('edit');
    setInitValues(o);
    setVisible(true);
  }

  const setDefaultCategory = (values) => {
    firstValues.level = values;
    setInitValues(firstValues);
  }

  const openChildCategoryModal = (o) => {
    setChildCategoryVisible(true);
    setChildCategoryId(o.id);
  }

  const pageChange = (page) => {
    setPage(page - 1);
  }

  useEffect(() => {
    getCategoryList();
  }, [getCategoryList])

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col span={6}><Button type="primary" onClick={addCategory}>新增分类</Button></Col>
      </Row>
      <Table className="components-table-demo-nested" columns={columns} dataSource={categoryList} bordered loading={loading} pagination={{ pageSize: size, defaultPageSize: size, total: total, onChange: pageChange, showSizeChanger: false, showTotal: total => `总数据：${total} 条` }} />
      <ChildCategoryTableModal childCategoryVisible={childCategoryVisible} closeModal={() => setChildCategoryVisible(false)} childCategoryId={childCategoryId} />
      <CategoryModalForm visible={visible} actionType={actionType} categoryList={categoryList} closeModal={() => setVisible(false)} modalOk={modalOk} initValues={initValues} setDefaultCategory={setDefaultCategory} />
    </div>
  )
}

export default Category;
