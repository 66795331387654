import React, { useRef, useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Form, Input, Select, TreeSelect, Button, Radio, Checkbox, Table, message, Popconfirm, Modal, Upload, Tooltip, Space, Switch } from 'antd';
import { SmallTailLayOut, DisplayFlex } from '@/utils/common';
import { getProductCategoryTree, saveProduct, queryProductDetail, queryProductAttributeList, queryProductAttributeValueList, deleteProductAttribute, addProductAttribute, addProductAttributeValue, deleteProductAttributeValue } from '@/api/product';
import MaterialLibraryPro from '@/components/MaterialLibraryPro';
import MaterialLibrary from '@/components/MaterialLibrary';
import UploadBtn from '@/components/UploadBtn';
import { queryAllBrandList } from '@/api/brand';
import { oss, manageRequestApi } from '@/utils/publicPath';
import styles from '@/styles/pages/product.module.scss';
import { ContentUtils } from 'braft-utils';
import BraftEditor from 'braft-editor';
import { PictureOutlined } from '@ant-design/icons/lib';
import 'braft-editor/dist/index.css';
import { QuestionCircleOutlined } from '@ant-design/icons';
import store from '@/Store';

const Option = Select.Option;
const { TreeNode } = TreeSelect;

const BasicInfo = {
  color: '#000',
  fontSize: '14px',
  padding: '0 24px',
  lineHeight: '50px',
  fontWeight: '700',
  background: 'rgb(249, 249, 249)',
  paddingLeft: '30px',
  marginBottom: '20px'
}

const ProductEdit = (props) => {
  let history = useHistory();

  const [categoryList, setCategoryList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [categoryTreeValue, setCategoryTreeValue] = useState();
  const [productMainPic, setProductMainPic] = useState([]);
  const [productImageList, setProductImageList] = useState([]);
  const [productAttributeList, setProductAttributeList] = useState([]);
  const [skuColumn, setSkuColumn] = useState([]);
  const [skuDataSource, setSkuDataSource] = useState([]);
  const [pmsProductAttributeDTOList, setPmsProductAttributeDTOList] = useState([]);
  const [skuListVisible, setSkuListVisible] = useState(false);
  const [skuName, setSkuName] = useState();
  const [skuValueListVisible, setSkuValueListVisible] = useState(false);
  const [skuValue, setSkuValue] = useState();
  const [skuValueList, setSkuValueList] = useState([]);
  const [skuAttributeId, setSkuAttributeId] = useState();
  const [nowIndex, setNowIndex] = useState();
  const [materialVisible, setMaterialVisible] = useState(false);
  const [materialVisible2, setMaterialVisible2] = useState(false);
  const [productSchemeList, setProductSchemeList] = useState([]);
  const [materilLibraryVisible, setMaterilLibraryVisible] = useState(false);
  // const [materialVisible3, setMaterialVisible3] = useState(false);
  const [checkedIds, setCheckedIds] = useState([]);
  const [materialValue, setMaterialValue] = useState(0);
  const [updateIndex, setUpdateIndex] = useState();
  const [cartonFlag, setCartonFlag] = useState(0);

  const [materialForm] = Form.useForm();
  const materialFormRef = useRef();

  const [editorState, setEditorState] = useState(BraftEditor.createEditorState(''));
  // 编辑内容触发
  const handleChange = (editorState) => {
    console.log(editorState.toHTML());
    setEditorState(editorState)
  }
  const uploadProps = {
    action: manageRequestApi + "oss/material",
    withCredentials: true,
    showUploadList: false,
    headers: { Authorization: store.getState().app.tokenHead + store.getState().app.token },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log('loading');
      }
      if (info.file.status === 'done') {
        console.log(info);
        setEditorState(ContentUtils.insertMedias(editorState, [{
          type: 'IMAGE',
          url: oss + info.file.response.data
        }]))

      } else if (info.file.status === 'error') {
        console.log('error')
      }
    }
  }

  const extendControls = ['bold', 'italic', 'underline', 'text-color', 'separator', 'link',
    {
      key: 'antd-uploader',
      type: 'component',
      component: (
        <Upload
          accept='image/*'
          showUploadList={false}
          {...uploadProps}
        >
          <Button className='control-item button upload-button' data-title='插入图片'>
            <PictureOutlined />
          </Button>
        </Upload>
      )
    }]


  const { id } = props.match.params;

  const [form] = Form.useForm();

  const formRef = useRef();

  const skuDataSourceRef = useRef([]);

  const skuValueColumns = [
    {
      title: '属性',
      width: '350px',
      dataIndex: 'value',
      align: 'center'
    },
    {
      title: '操作',
      width: '150px',
      align: 'center',
      render: (o) =>
        <div>
          <Popconfirm title="确认删除?" onConfirm={() => { deleteSkuValue(o) }}>
            <Button type="primary" danger size="small">删除</Button>
          </Popconfirm>
        </div>
    }
  ]

  const skuListColumns = [
    {
      title: '规格',
      width: '350px',
      dataIndex: 'name',
      align: 'center'
    },
    {
      title: '操作',
      width: '150px',
      align: 'center',
      render: (o) =>
        <div>
          <Popconfirm title="确认删除?" onConfirm={() => { deleteSku(o) }}>
            <Button type="primary" danger size="small">删除</Button>
          </Popconfirm>
        </div>
    }
  ]

  const queryCategoryList = useCallback(() => {
    getProductCategoryTree({}).then(res => {
      if (res.code === 0) {
        setCategoryList(res.data);
      }
    })
  }, []);

  const getAllBrand = useCallback(() => {
    queryAllBrandList().then(res => {
      if (res.code === 0) {
        setBrandList(res.data);
      }
    })
  }, [])

  const onChange = value => {
    console.log(value);
    setCategoryTreeValue(value);
  }

  const setMainPic = (values) => {
    let data = {};
    let imageList = [];
    values.forEach((o, i) => {
      data = {}
      data.uid = o.id;
      data.url = oss + o.url;
      data.filename = o.url;
      imageList.push(data);
    })
    setProductMainPic(imageList);
  }

  const setProductPicList = (values) => {
    let productImageListTemp = [...productImageList];
    let imageTemp = {};
    values.forEach(o => {
      imageTemp = {};
      imageTemp.uid = o.id;
      imageTemp.url = oss + o.url;
      imageTemp.filename = o.url;
      productImageListTemp.push(imageTemp);
    })
    console.log(productImageListTemp);
    setProductImageList(productImageListTemp);
  }



  const submitForm = (values) => {
    let imageList = [];
    imageList = productImageList.map(o => o.filename);
    values.image = productMainPic[0].filename;
    values.detail = editorState.toHTML();
    values.cartonFlag = values.cartonFlag ? 1 : 0;
    let skuDataSourceTemp = skuDataSource;
    skuDataSourceTemp.forEach(o => {
      let image = o.image;
      o.image = image[0].filename
      console.log(o.image);
    });
    console.log(skuDataSourceTemp);
    let params = {
      categoryIdList: values.categoryIdList,
      pmsProduct: values,
      imageList: imageList,
      pmsProductSkuList: skuDataSourceTemp,
      pmsProductSchemeList: productSchemeList
    };
    // console.log(productSchemeList);
    console.log(JSON.stringify(params));
    saveProduct(params).then(res => {
      if (res.code === 0) {
        history.goBack(-1);
      }
    })
  }

  const toProductList = () => {
    history.goBack(-1);
  }

  const inputGoodCode = useCallback((e, t, o, i) => {
    e.persist();
    let skuDataSourceTemp = [...skuDataSourceRef.current];
    skuDataSourceTemp[i].jancode = e.target.value;
    skuDataSourceRef.current = skuDataSourceTemp;
    setSkuDataSource(skuDataSourceTemp);
  }, [])

  const inputPrice = useCallback((e, t, o, i) => {
    e.persist();
    let skuDataSourceTemp = [...skuDataSourceRef.current];
    skuDataSourceTemp[i].price = e.target.value;
    skuDataSourceRef.current = skuDataSourceTemp;
    setSkuDataSource(skuDataSourceTemp);
  }, [])

  const inputStock = useCallback((e, t, o, i) => {
    e.persist();
    let skuDataSourceTemp = [...skuDataSourceRef.current];
    skuDataSourceTemp[i].stock = e.target.value;
    skuDataSourceRef.current = skuDataSourceTemp;
    setSkuDataSource(skuDataSourceTemp);
  }, [])

  const setSkuImage = useCallback((i, values) => {
    console.log(i, 'i');
    console.log(values, 'values');
    let skuDataSourceTemp = [...skuDataSourceRef.current];
    skuDataSourceTemp[i].image = [{ uid: 0, url: oss + values[0].url, filename: values[0].url }];
    console.log(skuDataSourceTemp);
    skuDataSourceRef.current = skuDataSourceTemp;
    setSkuDataSource(skuDataSourceTemp);
  }, [])

  const packageSkuColumn = useCallback((productAttributeDTOList) => {
    let skuColumnTemp = {};
    let skuColumns = [];
    console.log(productAttributeDTOList, 'productAttributeDTOList');
    productAttributeDTOList.forEach((o, i) => {
      if (o && o.attributeId !== undefined && o.checkedAttributeId.length !== 0) {
        skuColumnTemp = {};
        skuColumnTemp.attributeId = o.attributeId;
        skuColumnTemp.title = o.attributeName;
        skuColumnTemp.dataIndex = 'item' + i;
        skuColumnTemp.width = "110px";
        skuColumnTemp.align = "center"
        skuColumns.push(skuColumnTemp);
        o.key = o.attributeId;
        if (o.attributeValueDTOList) {
          o.attributeValueDTOList.forEach(p => {
            p.key = p.attributeValueId;
            p.label = p.attributeValueName;
            p.value = p.attributeValueId;
          })
        }
      }
    })

    let paramsColumn = [
      {
        title: <span><span style={{ color: 'red' }}>*</span>货号</span>,
        dataIndex: 'jancode',
        width: '150px',
        align: 'center',
        render: (t, o, i) =>
          <Input defaultValue={o.jancode} onChange={(e) => inputGoodCode(e, t, o, i)}
          // suffix={
          //   <span style={{ color: '#f04134' }}>*</span>
          // }
          />
      },
      {
        title: <span><span style={{ color: 'red' }}>*</span>价格</span>,
        dataIndex: 'price',
        width: '150px',
        align: 'center',
        render: (t, o, i) => <Input defaultValue={o.price} onChange={(e) => inputPrice(e, t, o, i)}
        // prefix={
        //   <span style={{ color: '#f04134' }}>*</span>
        // }
        />
      },
      {
        title: <span><span style={{ color: 'red' }}>*</span>库存</span>,
        dataIndex: 'stock',
        width: '150px',
        align: 'center',
        render: (t, o, i) =>
          <div style={{ display: 'flex' }}>
            {/* <span style={{ color: '#f04134', lineHeight: '32px', marginRight: '10px' }}>*</span> */}
            <Input defaultValue={o.stock} onChange={(e) => inputStock(e, t, o, i)} />
          </div>
      },
      {
        title: <span><span style={{ color: 'red' }}>*</span>图片</span>,
        dataIndex: 'image',
        width: '127px',
        align: 'center',
        render: (t, o, i) =>
          <div className={styles['display-flex-align-center']}>
            {
              o.image && o.image.length !== 0 ?
                <div style={{ position: 'relative', top: '4px', left: '4px' }}>
                  <Upload
                    listType="picture-card"
                    fileList={o.image}
                    onRemove={(file) => skuImageOnRemove(file, i)}
                  ></Upload>
                </div>
                :
                ''
            }
            {
              o.image && o.image.length === 0 ?
                // <>
                //   <div onClick={() => { console.log(1); setMaterialVisible3(true) }}>
                //     <UploadBtn />
                //     <MaterialLibraryPro
                //       visible={materialVisible3}
                //       closeModal={() => setMaterialVisible3(false)}
                //       maxChooseNum={1}
                //       setImages={(values) => setSkuImage(i, values)}
                //     />
                //   </div>
                // </>
                // :
                // ''
                <div className={o.image && o.image.length !== 0 ? styles['materialContainer'] : ''}>
                  <MaterialLibrary maxChooseNum={1} setImages={(values) => setSkuImage(i, values)} />
                </div>
                :
                ''
            }
          </div>
        // <div className={styles['display-flex-align-left']}>
        //   {
        //     o.image && [o.image] && [o.image].length !== 0 ?
        //       <div className={styles['imageContainer']}>
        //         <img alt="example" src={oss + o.image} style={DisplayImages} />
        //       </div>
        //       :
        //       ''
        //   }
        //   <div className={o.image && [o.image] && [o.image].length !== 0 ? styles['materialContainer'] : ''}>
        //     {/* <MaterialLibrary maxChooseNum={1} setImages={setMainPic} /> */}
        //     <MaterialLibrary maxChooseNum={1} setImages={(values) => setSkuImage(i, values)} checkedImages={o.image} />
        //   </div>
        // </div>

      }
    ];
    setSkuColumn(skuColumns.concat(paramsColumn));
    console.log(skuColumns.concat(paramsColumn))
  }, [inputGoodCode, inputPrice, inputStock, setSkuImage])

  const skuImageOnRemove = (file, i) => {
    console.log(file);
    console.log(i);
    let skuDataSourceTemp = [...skuDataSourceRef.current];
    skuDataSourceTemp[i].image = [];
    skuDataSourceRef.current = skuDataSourceTemp;
    setSkuDataSource(skuDataSourceTemp);
  }

  const getProductDetail = useCallback((data) => {
    queryProductDetail({ id: data }).then(res => {
      if (res.code === 0) {
        let pmsProduct = res.data.pmsProduct;
        let categories = res.data.pmsProductCategoryList;

        let treeValues = categories.map(o => o.id);
        pmsProduct.categoryIdList = treeValues;
        form.setFieldsValue(pmsProduct);
        setCartonFlag(pmsProduct.cartonFlag);
        setEditorState(BraftEditor.createEditorState(pmsProduct.detail));
        setProductMainPic([{ uid: 0, url: oss + pmsProduct.image, filename: pmsProduct.image }]);
        let iList = res.data.imageList;
        let imageList = [];
        let imageTemp = {}
        iList.forEach((o, i) => {
          imageTemp = {};
          imageTemp.uid = i;
          imageTemp.url = oss + o;
          imageTemp.filename = o;
          imageList.push(imageTemp);
        })
        setProductImageList(imageList);
        let productAttributeDTOList = res.data.pmsProductAttributeDTOList;
        productAttributeDTOList.forEach(o => {
          o.checkedAttributeId = o.attributeValueDTOList.filter(p => p.isChecked === 1).map(q => q.attributeValueId)
        })
        let skuDataSourceTemp = res.data.pmsProductSkuList;
        console.log(skuDataSourceTemp);
        packageSkuColumn(productAttributeDTOList);
        setPmsProductAttributeDTOList(productAttributeDTOList);
        skuDataSourceTemp.forEach(o => {
          let specDataArr = o.specDataArr;
          let specIdArr = o.specIdArr;
          o.key = o.id;
          JSON.parse(o.specDataArr).forEach((p, i) => {
            o['item' + i] = p.value;
          })
          o.image = [{ uid: 0, url: oss + o.image, filename: o.image }];
          o.specDataArr = JSON.parse(specDataArr);
          o.specIdArr = JSON.parse(specIdArr);
        })
        skuDataSourceRef.current = skuDataSourceTemp;
        setSkuDataSource(skuDataSourceTemp);
        let productSchemeListTemp = res.data.pmsProductSchemeList;
        productSchemeListTemp.forEach((o, i) => {
          if (o.isEnable === 1) setMaterialValue(i);
          o.materialLibraryImageList = o.imageList.map((p, pi) => { return { key: 'img' + pi, uid: pi, url: oss + p, filename: p } })
        })
        console.log(productSchemeListTemp);
        setProductSchemeList(productSchemeListTemp);
      }
    })
  }, [form, packageSkuColumn])

  const getProductAttributeList = () => {
    queryProductAttributeList().then(res => {
      if (res.code === 0) {
        let data = res.data;
        data.forEach(o => o.key = o.id);
        setProductAttributeList(data);
      }
    })
  }

  const addOneSku = () => {

    let skuList = {};
    let pmsProductAttributeDTOListTemp = [...pmsProductAttributeDTOList];
    pmsProductAttributeDTOListTemp.push(skuList);
    setPmsProductAttributeDTOList(pmsProductAttributeDTOListTemp);
  }

  const productAttributeChange = (e, i) => {
    //判断sku是否重复
    let flag = true;
    pmsProductAttributeDTOList.forEach(o => {
      if (o.attributeId === e) {
        flag = false;
      }
    })
    if (!flag) {
      message.error({
        content: 'sku信息重复，请重新选择或删除',
        duration: 5
      })
      return;
    }
    getProductAttributeValueList(e, i);
  }

  const getProductAttributeValueList = (e, i) => {
    queryProductAttributeValueList({ attributeId: e }).then(res => {
      if (res.code === 0) {
        let data = res.data;
        let option = [];
        data.forEach(o => {
          let opTemp = {};
          o.isChecked = 0;
          opTemp.key = o.id;
          opTemp.attributeValueId = o.id;
          opTemp.attributeValueName = o.value;
          opTemp.label = o.value;
          opTemp.value = o.id;
          option.push(opTemp);
        })
        let pmsProductAttributeDTOListTemp = [...pmsProductAttributeDTOList];
        pmsProductAttributeDTOListTemp[i].attributeId = e;
        pmsProductAttributeDTOListTemp[i].attributeName = productAttributeList.filter(o => o.id === e)[0].name;
        pmsProductAttributeDTOListTemp[i].attributeValueDTOList = option;
        pmsProductAttributeDTOListTemp[i].checkedAttributeId = [];
        pmsProductAttributeDTOListTemp[i].key = e;
        setPmsProductAttributeDTOList(pmsProductAttributeDTOListTemp);
        // let count = 0;
        // pmsProductAttributeDTOListTemp.forEach(o => {
        //   if (o.checkedAttributeId.length > 0) {
        //     count += 1;
        //   }
        // })
        // if (i !== 1) {

        // }
        // //组织表头
        packageSkuColumn(pmsProductAttributeDTOListTemp);
        // //组织表数据
        packageSkuDataSource2(pmsProductAttributeDTOListTemp);
      }
    })
  }

  const deleteProductSku = (i) => {
    let pmsProductAttributeDTOListTemp = [...pmsProductAttributeDTOList];
    pmsProductAttributeDTOListTemp.splice(i, 1);
    setPmsProductAttributeDTOList(pmsProductAttributeDTOListTemp);
    // //组织表头
    packageSkuColumn(pmsProductAttributeDTOListTemp);
    // //组织表数据
    packageSkuDataSource2(pmsProductAttributeDTOListTemp);
  }

  const checkeBoxOnchange = (e, i) => {
    let pmsProductAttributeDTOListTemp = [...pmsProductAttributeDTOList];
    pmsProductAttributeDTOListTemp[i].checkedAttributeId = e;
    setPmsProductAttributeDTOList(pmsProductAttributeDTOListTemp);
    // setProductSkuList(pmsProductAttributeDTOListTemp);
    // //组织表头
    packageSkuColumn(pmsProductAttributeDTOListTemp);
    // //组织表数据
    packageSkuDataSource2(pmsProductAttributeDTOListTemp);
  }

  const generateSkuDataSourceByTwoList = (list1, list2) => {
    let skuDataSourceTemp = [];
    list1.checkedAttributeId.forEach(o => {
      list2.checkedAttributeId.forEach(p => {
        let skuDataTemp = {};
        skuDataTemp.key = p;
        skuDataTemp.item0 = list1.attributeValueDTOList.filter(q => q.attributeValueId === o)[0].label;
        skuDataTemp.item1 = list2.attributeValueDTOList.filter(q => q.attributeValueId === p)[0].label;
        skuDataTemp.image = [];
        skuDataTemp.specIdArr = [];
        let specIdArrTemp = {};
        specIdArrTemp.key = list1.attributeId;
        specIdArrTemp.value = o;
        skuDataTemp.specIdArr.push(specIdArrTemp);
        specIdArrTemp = {};
        specIdArrTemp.key = list2.attributeId;
        specIdArrTemp.value = p;
        skuDataTemp.specIdArr.push(specIdArrTemp);
        skuDataSourceTemp.push(skuDataTemp);
      });
    });
    skuDataSourceRef.current = skuDataSourceTemp;
    setSkuDataSource(skuDataSourceTemp);
  }

  const generateSkuDataSourceByOneList = (list) => {
    let skuDataSourceTemp = [];
    list.checkedAttributeId.forEach(o => {
      let skuDataTemp = {};
      skuDataTemp.key = o;
      skuDataTemp.item0 = list.attributeValueDTOList.filter(p => p.attributeValueId === o)[0].label;
      skuDataTemp.item1 = list.attributeValueDTOList.filter(p => p.attributeValueId === o)[0].label;
      skuDataTemp.jancode = '';
      skuDataTemp.image = [];
      skuDataTemp.specIdArr = [];
      let specIdArrTemp = {};
      specIdArrTemp.key = list.attributeId;
      specIdArrTemp.value = o;
      skuDataTemp.specIdArr.push(specIdArrTemp);
      skuDataSourceTemp.push(skuDataTemp);
    });
    skuDataSourceRef.current = skuDataSourceTemp;
    setSkuDataSource(skuDataSourceTemp);

  }

  const packageSkuDataSource2 = (pmsProductAttributeDTOListTemp) => {
    let list1 = pmsProductAttributeDTOListTemp[0];
    let list2 = pmsProductAttributeDTOListTemp[1];
    !list1 ? list1 = {} : list1.key === undefined ? list1 = {} : list1 = pmsProductAttributeDTOListTemp[0];
    !list2 ? list2 = {} : list2.key === undefined ? list2 = {} : list2 = pmsProductAttributeDTOListTemp[1];
    // if (list1) {
    //   if (list1.key === undefined) {
    //     list1 = [];
    //   }
    // } else {
    //   list1 = [];
    // }
    // if (list2) {
    //   if (list2.key === undefined) {
    //     list1 = [];
    //   }
    // } else {
    //   list1 = [];
    // }
    console.log(list1);
    console.log(list2);
    if (list1.checkedAttributeId && list1.checkedAttributeId.length === 0) {
      list1 = {};
    } else if (list2.checkedAttributeId && list2.checkedAttributeId.length === 0) {
      list2 = {};
    }
    if (pmsProductAttributeDTOListTemp.length === 1) {
      if (JSON.stringify(list1) !== "{}") {
        generateSkuDataSourceByOneList(list1);
      } else {
        setSkuDataSource([]);
      }
    } else {
      if (JSON.stringify(list1) !== "{}" && JSON.stringify(list2) !== "{}") {
        console.log(111111);
        generateSkuDataSourceByTwoList(list1, list2);
      } else if (JSON.stringify(list1) !== "{}" && JSON.stringify(list2) === "{}") {
        console.log(222222);
        generateSkuDataSourceByOneList(list1);
      } else if (JSON.stringify(list1) === "{}" && JSON.stringify(list2) !== "{}") {
        console.log(333333);
        generateSkuDataSourceByOneList(list2);
      } else {
        setSkuDataSource([]);
      }
    }

    // if (pmsProductAttributeDTOListTemp.length === 1) {
    //   pmsProductAttributeDTOListTemp[0].checkedAttributeId.forEach(o => {
    //     let skuDataTemp = {};
    //     skuDataTemp.key = o;
    //     skuDataTemp.item0 = pmsProductAttributeDTOListTemp[0].attributeValueDTOList.filter(p => p.attributeValueId === o)[0].label;
    //     skuDataTemp.jancode = '';
    //     skuDataTemp.specIdArr = [];
    //     let specIdArrTemp = {};
    //     specIdArrTemp.key = pmsProductAttributeDTOListTemp[0].attributeId;
    //     specIdArrTemp.value = o;
    //     skuDataTemp.specIdArr.push(specIdArrTemp);
    //     skuDataSourceTemp.push(skuDataTemp);
    //   })
    // } else {
    //   if (pmsProductAttributeDTOListTemp[0].checkedAttributeId === undefined || pmsProductAttributeDTOListTemp[0].checkedAttributeId.length === 0) {
    //     pmsProductAttributeDTOListTemp[1].checkedAttributeId.forEach(o => {
    //       let skuDataTemp = {};
    //       skuDataTemp.key = o;
    //       skuDataTemp.item1 = pmsProductAttributeDTOListTemp[1].attributeValueDTOList.filter(p => p.attributeValueId === o)[0].label;
    //       skuDataTemp.specIdArr = [];
    //       let specIdArrTemp = {};
    //       specIdArrTemp.key = pmsProductAttributeDTOListTemp[1].attributeId;
    //       specIdArrTemp.value = o;
    //       skuDataTemp.specIdArr.push(specIdArrTemp);
    //       skuDataSourceTemp.push(skuDataTemp);
    //     })
    //   } else {
    //     pmsProductAttributeDTOListTemp[0].checkedAttributeId.forEach(o => {
    //       pmsProductAttributeDTOListTemp[1].checkedAttributeId.forEach(p => {
    //         let skuDataTemp = {};
    //         skuDataTemp.key = p;
    //         skuDataTemp.item0 = pmsProductAttributeDTOListTemp[0].attributeValueDTOList.filter(q => q.attributeValueId === o)[0].label;
    //         skuDataTemp.item1 = pmsProductAttributeDTOListTemp[1].attributeValueDTOList.filter(q => q.attributeValueId === p)[0].label;
    //         skuDataTemp.specIdArr = [];
    //         let specIdArrTemp = {};
    //         specIdArrTemp.key = pmsProductAttributeDTOListTemp[0].attributeId;
    //         specIdArrTemp.value = o;
    //         skuDataTemp.specIdArr.push(specIdArrTemp);
    //         specIdArrTemp = {};
    //         specIdArrTemp.key = pmsProductAttributeDTOListTemp[1].attributeId;
    //         specIdArrTemp.value = p;
    //         skuDataTemp.specIdArr.push(specIdArrTemp);
    //         skuDataSourceTemp.push(skuDataTemp);
    //       })
    //     })
    //   }
    // }
    // skuDataSourceRef.current = skuDataSourceTemp;
    // setSkuDataSource(skuDataSourceTemp);
  }

  const skuManage = () => {
    setSkuListVisible(true);
  }

  const deleteSku = (o) => {
    console.log(o);
    deleteProductAttribute({ id: o.id }).then(res => {
      if (res.code === 0) {
        getProductAttributeList();
      } else {
        message.error({ content: res.data.msg, duration: 5 });
      }
    })
  }

  const assignSkuName = e => {
    e.persist();
    setSkuName(e.target.value);
  }

  const addSku = () => {
    console.log(skuName);
    if (skuName) {
      addProductAttribute({ name: skuName }).then(res => {
        if (res.code === 0) {
          getProductAttributeList();
          setSkuName();
        }
      })
    } else {
      message.error({
        content: '请输入正确的规格名称',
        duration: 5
      })
    }
  }

  const manageSkuValue = (o, i) => {
    console.log(o);
    if (JSON.stringify(o) === "{}") {
      message.error({ content: '请先选择规格', duration: 5 });
    } else {
      setSkuValueListVisible(true);
      getSkuValueList(o.attributeId);
      setSkuAttributeId(o.attributeId);
      setNowIndex(i);
    }
  }

  const assignSkuValue = e => {
    console.log(e);
    e.persist();
    setSkuValue(e.target.value);
  }

  const getSkuValueList = (id) => {
    setSkuAttributeId(id);
    queryProductAttributeValueList({ attributeId: id }).then(res => {
      if (res.code === 0) {
        let data = res.data;
        data.forEach(o => o.key = o.id);
        setSkuValueList(data);
      }
    })
  }

  const addSkuValueName = () => {
    addProductAttributeValue({ attributeId: skuAttributeId, value: skuValue }).then(res => {
      if (res.code === 0) {
        getSkuValueList(skuAttributeId);
        getProductAttributeValueList(skuAttributeId, nowIndex);
        setSkuValue();
      }
    })
  }

  const deleteSkuValue = (o) => {
    deleteProductAttributeValue({ id: o.id }).then(res => {
      if (res.code === 0) {
        getSkuValueList(skuAttributeId);
        getProductAttributeValueList(skuAttributeId, nowIndex);
      }
    })
  }

  const productImageListOnRemove = file => {
    let imageList = [...productImageList];
    let index = 0;
    imageList.forEach((o, i) => {
      if (o.uid === file.uid) {
        index = i;
      }
    })
    imageList.splice(index, 1);
    setProductImageList(imageList);
  }

  const productMainPicOnRemove = file => {
    let imageList = [...productMainPic];
    const spliceIndex = (imageList || []).findIndex(o => o.id = file.uid);
    imageList.splice(spliceIndex, 1);
    setProductMainPic(imageList);
  }

  const emptyAssignZero = e => {
    e.persist();
    if (!e.target.value) {
      form.setFieldsValue({ rank: 0 });
    }
  }

  const materialLibraryImageListOnRemove = (file, i) => {
    console.log(file);
    let productSchemeListTemp = [...productSchemeList];
    let index = 0;
    productSchemeListTemp[i].materialLibraryImageList.forEach((o, i) => {
      if (o.uid === file.uid) {
        index = i;
      }
    })
    productSchemeListTemp[i].materialLibraryImageList.splice(index, 1);
    productSchemeListTemp[i].imageList = productSchemeListTemp[i].materialLibraryImageList.map(o => o.filename);
    setProductSchemeList(productSchemeListTemp);
  }

  const assignMaterialLibraryImageList = (values, index) => {
    let productSchemeListTemp = [...productSchemeList];
    let data = {};
    let list = [...productSchemeListTemp[index].materialLibraryImageList];
    values.forEach((o) => {
      data = {}
      data.uid = o.id;
      data.url = oss + o.url;
      data.filename = o.url;
      list.push(data);
    })
    console.log(list);
    productSchemeListTemp[index].materialLibraryImageList = list;
    productSchemeListTemp[index].imageList = list.map(o => o.filename);
    setProductSchemeList(productSchemeListTemp);
  }

  const generateOneMaterialObj = () => {
    let productSchemeListTemp = [...productSchemeList];
    let index = updateIndex;
    let data = materialFormRef.current.getFieldsValue();
    console.log(index);
    if (index !== '' && index !== undefined) {
      productSchemeListTemp.forEach((o, i) => {
        if (i === index) {
          o.name = data.name;
          o.detail = data.detail;
        }
      })
    } else {
      delete data.id;
      data.materialLibraryImageList = [];
      // 每次只有在新增的时候判断 是否有选中的素材库
      // 有则设置isEnable = 0
      // 无则设置isEnable = 1
      let isEnableFlag = false;
      productSchemeListTemp.forEach(o => {
        if (o.isEnable === 1) {
          isEnableFlag = true;
        }
      })
      if (isEnableFlag) {
        data.isEnable = 0;
      } else {
        data.isEnable = 1
      }
      productSchemeListTemp.push(data);
    }
    console.log(data);
    setProductSchemeList(productSchemeListTemp);
    setMaterilLibraryVisible(false);
  }

  const materialOnChange = (e) => {
    e.stopPropagation();//阻止冒泡
    console.log(e.target.value);
    setMaterialValue(e.target.value);
    let productSchemeListTemp = [...productSchemeList];
    productSchemeListTemp.forEach((o, i) => {
      if (e.target.value === i) {
        o.isEnable = 1;
      } else {
        o.isEnable = 0;
      }
    })
    // productSchemeListTemp[e.target.value].isEnable = 1;
    setProductSchemeList(productSchemeListTemp);
  }

  const deleteMaterial = i => {
    let productSchemeListTemp = [...productSchemeList];
    productSchemeListTemp.splice(i, 1);
    setProductSchemeList(productSchemeListTemp);
  }

  useEffect(() => {
    if (id) {
      getProductDetail(id);
    } else {
      form.setFieldsValue({ status: 1, isHot: 0, rank: 0 });
    }
  }, [id, getProductDetail, form])

  useEffect(() => {
    queryCategoryList();
    getAllBrand();
    getProductAttributeList();
  }, [queryCategoryList, getAllBrand])

  return (
    <div style={{ backgroundColor: '#fff', paddingBottom: '30px' }}>
      <div style={BasicInfo}>
        基础信息
      </div>
      <Form {...{ labelCol: { span: 2 }, wrapperCol: { offset: 0, span: 24 } }} ref={formRef} form={form} onFinish={submitForm}>
        <Form.Item hidden label="id" name="id">
          <Input />
        </Form.Item>
        <Form.Item name="categoryIdList" label="商品分类" rules={[{ required: true, message: '请选择商品分类' }]}>
          <TreeSelect
            showSearch
            style={{ width: '380px' }}
            value={categoryTreeValue}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            placeholder="请选择分类"
            allowClear
            multiple
            treeDefaultExpandAll
            treeCheckable={true}
            onChange={onChange}
          >
            {
              categoryList.map(o =>
                <TreeNode key={o.pmsProductCategory.id} value={o.pmsProductCategory.id} title={o.pmsProductCategory.name}>
                  {
                    o.productCategoryNodeList.map(p =>
                      <TreeNode key={p.pmsProductCategory.id} value={p.pmsProductCategory.id} title={p.pmsProductCategory.name} />
                    )
                  }
                </TreeNode>
              )
            }
          </TreeSelect>
        </Form.Item>
        <Form.Item name="brandId" label="商品品牌" rules={[{ required: true, message: '请选择商品品牌' }]}>
          <Select style={{ width: '380px' }}>
            {
              brandList.map(o =>
                <Option key={o.id + 'brands'} value={o.id}>{o.name}</Option>
              )
            }
          </Select>
        </Form.Item>
        <Form.Item label="商品主图">
          <div className={styles['display-flex-align-left']}>
            {
              productMainPic && productMainPic.length !== 0 ?
                <Upload
                  listType="picture-card"
                  fileList={productMainPic}
                  onRemove={productMainPicOnRemove}
                ></Upload>
                :
                ''
            }
            {
              productMainPic && productMainPic.length === 0 ?
                <>
                  <div onClick={() => setMaterialVisible(true)}>
                    <UploadBtn />
                  </div>
                  <MaterialLibraryPro
                    visible={materialVisible}
                    closeModal={() => setMaterialVisible(false)}
                    maxChooseNum={1}
                    setImages={setMainPic}
                  />
                </>
                :
                ''
              // <div className={productMainPic && productMainPic.length !== 0 ? styles['materialContainer'] : ''}>
              //   <MaterialLibrary maxChooseNum={1} setImages={setMainPic} />
              // </div>
              // :
              // ''
            }
          </div>
          <span style={{ color: '#bbb', fontSize: '12px' }}>提示：上传图片大小不超过3MB，支持jpg/jpeg/png格式，建议尺寸：750*750,1:1亦可</span>
        </Form.Item>
        <Form.Item label="商品图集" style={DisplayFlex}>
          <div className={styles['display-flex-align-left']}>
            <div style={{ width: productImageList.length * 112 + 'px' }}>
              {
                productImageList && productImageList.length !== 0 ?
                  <Upload
                    listType="picture-card"
                    fileList={productImageList}
                    onRemove={productImageListOnRemove}
                  ></Upload>
                  :
                  ''
              }
            </div>
            {
              productImageList && productImageList.length < 10 ?
                <>
                  <div onClick={() => { setCheckedIds(productImageList.map(o => o.uid)); setMaterialVisible2(true); }}>
                    <UploadBtn />
                  </div>
                  <MaterialLibraryPro
                    visible={materialVisible2}
                    closeModal={() => setMaterialVisible2(false)}
                    maxChooseNum={10 - productImageList.length}
                    setImages={setProductPicList}
                    checkedIds={checkedIds}
                  />
                </>
                :
                ''
            }
          </div>
          <span style={{ color: '#bbb', fontSize: '12px' }}>提示：上传图片大小不超过3MB，支持jpg/jpeg/png格式，建议尺寸：750*750,1:1亦可</span>
        </Form.Item>
        <Form.Item label="商品名称" name="name" rules={[{ required: true, message: '请输入商品名称' }]}>
          <Input style={{ width: '380px' }} />
        </Form.Item>
        <Form.Item label="商品规格" name="specification">
          <Input style={{ width: '380px' }} />
        </Form.Item>
        <Form.Item label="商品货号" name="jancode" rules={[{ required: true, message: '请输入商品货号' }]}>
          <Input style={{ width: '380px' }}
            suffix={
              <Tooltip placement="topLeft" title="SPU的货号不能重复，SKU的货号不能重复，SPU和SKU之间的货号可以重复">
                <QuestionCircleOutlined />
              </Tooltip>
            }
          />
        </Form.Item>
        <Form.Item label="商品售价" name="price" rules={[{ required: true, message: '请输入商品售价' }]}>
          <Input style={{ width: '380px' }}
            suffix={
              <Tooltip placement="topLeft" title="商品有降价调整时，请前往代理价格调整代理的拿货价，确保代理价格不高于售价">
                <QuestionCircleOutlined />
              </Tooltip>
            }
          />
        </Form.Item>
        <Form.Item label="SPU库存" name="stock">
          <Input style={{ width: '380px' }}
            suffix={
              <Tooltip placement="topLeft" title="当商品添加了SKU时该库存失效，以SKU的库存数为准">
                <QuestionCircleOutlined />
              </Tooltip>
            }
          />
        </Form.Item>
        <Form.Item label="商品排序" name="rank">
          <Input style={{ width: '380px' }} onBlur={emptyAssignZero} />
        </Form.Item>
        <Form.Item label="商品销量" name="sales">
          <Input style={{ width: '380px' }} />
        </Form.Item>
        <Form.Item label="商品状态" name="status">
          <Radio.Group name="status">
            <Radio value={0}>隐藏</Radio>
            <Radio value={1}>显示</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="是否热门商品" name="isHot">
          <Radio.Group name="isHot">
            <Radio value={0}>否</Radio>
            <Radio value={1}>是</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="是否开启箱规" name="cartonFlag">
          <Switch checkedChildren="开启" unCheckedChildren="关闭" checked={cartonFlag === 1 ? true : false} onChange={() => setCartonFlag(cartonFlag === 0 ? 1 : 0)} />
        </Form.Item>
        {
          cartonFlag === 1 ?
            <Form.Item label="箱规" name="carton" rules={[{ required: true, pattern: /^\+?[1-9]\d*$/, message: '箱规必须为大于0的正整数' }]}>
              <Input style={{ width: '380px' }} />
            </Form.Item>
            :
            ''
        }

        {
          cartonFlag === 1 ?
            <Form.Item label="零售上限" name="retailCeiling" rules={[{ required: true, pattern: /^\+?[1-9]\d*$/, message: '零售上限必须为大于0的正整数' }]}>
              <Input style={{ width: '380px' }} />
            </Form.Item>
            :
            ''
        }

        <Form.Item label="素材库">
          <div style={{ marginTop: '5px' }}></div>
          <div style={{ cursor: 'pointer', color: 'rgb(84, 149, 246)' }} onClick={() => { setMaterilLibraryVisible(true); materialForm.setFieldsValue({ id: '', name: '', detail: '' }); setUpdateIndex(''); }}>新增素材库</div>
          <Radio.Group onChange={materialOnChange} value={materialValue} style={{ width: '100%' }}>
            {
              productSchemeList.map((o, i) =>
                <div className={styles['sucaiku_container']} key={i}>
                  <div className={styles['top_radio']}>
                    <div className={styles['left_radio']}>
                      <Radio value={i}></Radio>
                    </div>
                    <div className={styles['right_btn']}>
                      <Space>
                        <Button type="primary" size="small" onClick={() => { setMaterilLibraryVisible(true); materialForm.setFieldsValue({ id: o.id, name: o.name, detail: o.detail }); setUpdateIndex(i); }}>编辑</Button>
                        <div style={{ marginLeft: '20px' }}></div>
                        <Button type="primary" danger size="small" onClick={() => deleteMaterial(i)}>删除</Button>
                      </Space>
                    </div>
                  </div>
                  {/* {
                      o && o.id ? <div className={styles['sucaiku_name']}><Form.Item label='id'>{o.name}</Form.Item></div>
                      :''
                    } */}
                  <div className={styles['sucaiku_name']}><Form.Item label='名称'>{o.name}</Form.Item></div>
                  <div className={styles['sucaiku_detail']}><Form.Item label='描述'>{o.detail}</Form.Item></div>
                  <div className={styles['sucaiku_tuji']}>
                    <Form.Item label='图集'>
                      <div className={styles['display-flex-align-left']}>
                        {
                          o.materialLibraryImageList && o.materialLibraryImageList.length !== 0 ?
                            <div style={{ position: 'relative', top: '4px', left: '4px' }}>
                              <Upload
                                listType="picture-card"
                                fileList={o.materialLibraryImageList}
                                onRemove={(file) => materialLibraryImageListOnRemove(file, i)}
                              ></Upload>
                            </div>
                            :
                            ''
                        }
                        {
                          o.materialLibraryImageList && o.materialLibraryImageList.length < 9 ?
                            <div className={o.materialLibraryImageList && o.materialLibraryImageList.length !== 0 ? styles['materialContainer'] : ''}>
                              <MaterialLibrary maxChooseNum={9 - o.materialLibraryImageList.length} setImages={(values) => assignMaterialLibraryImageList(values, i)} />
                            </div>
                            :
                            ''
                        }
                      </div>
                    </Form.Item>
                  </div>
                </div>
              )
            }
          </Radio.Group>
        </Form.Item>
        <Form.Item label="商品详情" rules={[{ required: true, message: '请输入商品详情' }]}>
          <div style={{ border: '1px solid #ccc', width: '80%' }}>
            <BraftEditor
              contentStyle={{ height: 305 }}
              value={editorState}
              excludeControls={['media', 'emoji']}
              extendControls={extendControls}
              onChange={handleChange}
            />
          </div>
        </Form.Item>
        <div style={BasicInfo}>
          更多设置
        </div>
        <Form.Item label="商品规格">
          <div style={pmsProductAttributeDTOList.length > 0 ? { marginTop: '5px' } : {}}></div>
          {
            pmsProductAttributeDTOList.length >= 2 ?
              '' :
              <span style={{ color: '#5495f6', cursor: 'pointer' }} onClick={addOneSku}>新增自定义规格<span style={{ margin: '20px' }}>|</span></span>
          }
          <span style={{ color: '#5495f6', cursor: 'pointer' }} onClick={skuManage}>规格管理</span>
          {
            pmsProductAttributeDTOList.map((o, i) =>
              <div key={i} style={{ width: '900px', border: '1px solid #d9d9d9', marginTop: '40px', }}>
                <div style={{ display: 'flex', padding: '20px', borderBottom: '1px solid #d9d9d9' }}>
                  <div style={{ width: '50%' }}>
                    <Select style={{ width: '180px' }} value={o.attributeId} onChange={e => productAttributeChange(e, i)}>
                      {
                        productAttributeList.map(p =>
                          <Option key={p.id} value={p.id}>{p.name}</Option>
                        )
                      }
                    </Select>
                  </div>
                  <div style={{ width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', cursor: 'pointer', color: '#5495f6' }}>
                    <span style={{ wcursor: 'pointer', color: '#5495f6', marginRight: '40px' }} onClick={() => manageSkuValue(o, i)}>属性管理</span>
                    <span onClick={() => deleteProductSku(i)}>删除</span>
                  </div>
                </div>
                <div style={{ padding: '20px' }}>
                  <Checkbox.Group value={o.checkedAttributeId} options={o.attributeValueDTOList ? o.attributeValueDTOList : []} onChange={(e) => checkeBoxOnchange(e, i)} />

                </div>
              </div>
            )
          }
          {
            skuDataSource && skuDataSource.length !== 0 ?
              <div style={{ width: '900px', marginTop: '40px' }}>
                <Table columns={skuColumn} dataSource={skuDataSource} bordered pagination={false} />
              </div>
              :
              ''
          }
        </Form.Item>

        <div className={styles['bottom-submit-and-back']}>
          <Button style={{ marginRight: '20px' }} type="primary" htmlType="submit">
            提交
          </Button>
          <Button htmlType="button" onClick={() => { toProductList() }}>
            返回
          </Button>
        </div>

        <Form.Item {...SmallTailLayOut}>

        </Form.Item>
      </Form>
      <Modal
        visible={skuListVisible}
        onCancel={() => { setSkuListVisible(false); setSkuName(); }}
        title="规格管理"
        footer={[]}
        width="600px"
      >
        <div className="top-area">
          <div className="btn-container-only">
            <Input style={{ width: '190px' }} placeholder="请输入规格名称" onBlur={assignSkuName} value={skuName} />
            <Button type="primary" style={{ marginLeft: '20px' }} onClick={() => addSku()}>新增规格</Button>
          </div>
        </div>
        <Table
          columns={skuListColumns}
          dataSource={productAttributeList}
          pagination={false}
          scroll={{ y: 480 }}
          bordered
        />
      </Modal>

      <Modal
        visible={skuValueListVisible}
        onCancel={() => { setSkuValueListVisible(false); setSkuValue(); }}
        title="属性管理"
        footer={[]}
        width="600px"
      >
        <div className="top-area">
          <div className="btn-container-only">
            <Input style={{ width: '190px' }} placeholder="请输属性名称" onBlur={assignSkuValue} value={skuValue} />
            <Button type="primary" style={{ marginLeft: '20px' }} onClick={() => addSkuValueName()}>新增属性</Button>
          </div>
        </div>
        <Table
          columns={skuValueColumns}
          dataSource={skuValueList}
          pagination={false}
          scroll={{ y: 480 }}
          bordered
        />
      </Modal>
      <Modal
        visible={materilLibraryVisible}
        onCancel={() => setMaterilLibraryVisible(false)}
        onOk={() => generateOneMaterialObj()}
        title='编辑素材库'
      >
        <Form {...{ labelCol: { span: 4 }, wrapperCol: { offset: 0, span: 24 } }} form={materialForm} ref={materialFormRef}>
          <Form.Item label='id' hidden name="id">
            <Input />
          </Form.Item>
          <Form.Item label='素材名称' name="name" rules={[{ required: true, message: '请输入素材库名称' }]}>
            <Input />
          </Form.Item>
          <Form.Item label='素材描述' name="detail">
            <Input.TextArea rows={4} />
          </Form.Item>
        </Form>
      </Modal>
    </div >
  )
}

export default ProductEdit;
