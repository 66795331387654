import React, { useEffect, useCallback, useState, useRef } from "react";
import { Button, Table, Space, Form, Input, Radio, Select, Modal, Popconfirm, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { queryUserList } from '@/api/user';
import { changeAgentListStatus, saveAgent, getVertifyCode, queryAgentLevelList } from '@/api/agent';
import { queryUserByName, queryUserByPhone } from '@/api/user';
import { getLabel } from '@/utils/tools';
import { FormLayOut } from '@/utils/common';
import styles from '@/styles/pages/agent.module.scss';
import copy from 'copy-to-clipboard';

const Option = Select.Option;

const firstValues = { id: '', inviterId: '', phoneNumber: '', username: '', level: '', status: 1, remark: '', weChatSubscribe: 0 };

const AgentList = (props) => {

  const history = useHistory();

  const [form] = Form.useForm();

  const formRef = useRef();

  const [page, setPage] = useState(0);
  const [size] = useState(20);
  const [loading, setLoading] = useState(false);
  const [agentList, setAgentList] = useState([]);
  const [total, setTotal] = useState(0);
  const [visible, setVisible] = useState(false);
  const [agentTitle, setAgentTitle] = useState();
  const [actionType, setActionType] = useState();
  const [currentAgentId, setCurrentAgentId] = useState();
  const [initValues] = useState(firstValues);
  const [agentLevelList, setAgentLevelList] = useState([]);
  const [value, setValue] = useState();

  const agentStatus = ['锁定', '正常'];

  const columns = [
    {
      title: '个人信息',
      align: 'center',
      width: '200px',
      render: o => <div><div>{o.username}</div><div>{o.phoneNumber}</div><div>{o.createdDate}</div></div>
    },
    {
      title: '等级信息',
      align: 'center',
      width: '180px',
      render: o =>
        <div>
          <div>代理等级：{o.level}</div>
          <div>代理名称：{o.levelName}</div>
        </div>
    },
    {
      title: '邀请人',
      align: 'center',
      width: '150px',
      render: o =>
        <div>
          <div>{o.inviterName}{o.inviterLevelName ? `(${o.inviterLevelName})` : ''}</div>
        </div>
    },
    {
      title: '数据',
      align: 'center',
      width: '180px',
      render: o => <div><div>当前金额：{o.balance}</div><div>订单业绩：{o.achievement}</div></div>
    },
    {
      title: '状态',
      align: 'center',
      width: '150px',
      render: o =>
        <Popconfirm title="确认修改状态?" onConfirm={() => chagneAgentStatus(o)}>
          <Button size="small" className={o.status === 1 ? styles['hideStatus'] : styles['showStatus']}>{getLabel(o.status, agentStatus)}</Button>
        </Popconfirm >
      // <div>
      //   <Button onClick={() => chagneAgentStatus(o)}>{getLabel(o.status, agentStatus)}
      //   </Button>
      // </div>
    },
    {
      title: '操作',
      align: 'center',
      width: '250px',
      render: (o) => {
        return (
          <Space size="middle" className="operationCloumn">
            <Button style={{ color: 'white', background: '#FF99CC', border: 'none' }} type="primary" size="small" onClick={() => { querySubList(o) }}>下级列表</Button>
            <Button type="primary" size="small" onClick={() => { editAgent(o) }}>编辑</Button>
            <Button type="primary" size="small" danger onClick={() => { generateCode(o) }}>生成验证码</Button>
            {/* <Popconfirm title="确认删除?" onConfirm={() => deleteAgent(o)}>
              <Button>删除</Button>
            </Popconfirm> */}
          </Space>
        )
      }
    }
  ];

  const getAgentList = useCallback((value = '') => {
    setLoading(true);
    queryUserList({ page: page, size: size, id: '', value: value }).then(res => {
      if (res.code === 0) {
        setTotal(res.data.totalElements);
        let data = res.data.content;
        data.forEach(o => o.key = o.id);
        setLoading(false);
        setAgentList(data);
      }
    })
  }, [page, size])

  const getAgentLevelList = () => {
    queryAgentLevelList({ page: 0, size: 10 }).then(res => {
      let data = res.data;
      data.forEach(o => {
        o.key = o.id;
      })
      setAgentLevelList(data);
    })
  };

  const chagneAgentStatus = (o) => {
    if (o.status === 0) {
      o.status = 1
    } else {
      o.status = 0
    }
    setLoading(true);
    changeAgentListStatus({ status: o.status, userId: o.id }).then(res => {
      if (res.code === 0) {
        getAgentList();
        setLoading(false);
      }
    })
  }

  const addNewAgent = () => {
    form.resetFields(['inviterId', 'phoneNumber', 'username', 'level']);
    setVisible(true);
    setAgentTitle('新增代理');
    setActionType('a');
    if (initValues.id) initValues.id = '';
    console.log(initValues);
    form.setFieldsValue(initValues);
  }

  const pageChange = (page) => {
    setPage(page - 1);
  }

  const submitForm = () => {
    let data = formRef.current.getFieldsValue();
    form.validateFields().then(result => {
      saveAgent(data).then(res => {
        if (res.code === 0) {
          getAgentList();
        }
        setVisible(false);
      })
    }).catch()

  }

  const editAgent = (o) => {
    setVisible(true);
    setAgentTitle('编辑代理');
    setActionType('u');
    setCurrentAgentId(o.id);
    form.setFieldsValue(o);
  }

  const generateCode = (o) => {
    if (o.phoneNumber) {
      getVertifyCode({ phone: o.phoneNumber }).then(res => {
        if (res.code === 0) {
          Modal.confirm({
            title: '验证码：一小时后失效',
            content: `验证码为${res.data}`,
            cancelText: '知道了',
            okText: '复制验证码',
            onOk: () => {
              copy(res.data);
            }
          });
        }
      })
    } else {
      Modal.error({ title: '手机号未录入，无法生成验证码', duration: 5 })
    }
  }

  //删除代理功能 暂时删除
  // const deleteAgent = (o) => {
  //   delAgent({ userId: o.id }).then(res => {
  //     if (res.code === 0) {
  //       getAgentList();
  //     }
  //   })
  // }

  const getAgentListByPhone = e => {
    e.persist()
    console.log(e.target.value);
    queryUserByPhone({ phone: e.target.value }).then(res => {
      if (res.code === 0) {
        if (res.data) {
          message.error({
            content: '该手机号码已被其他代理注册，请更换',
            duration: 5
          })
          form.setFieldsValue({ phoneNumber: '' });
        }
      }
    })
  }

  const getAgentListByName = e => {
    e.persist()
    console.log(e.target.value);
    queryUserByName({ name: e.target.value }).then(res => {
      if (res.code === 0) {
        if (res.data) {
          message.error({
            content: '该代理名称已被其他代理注册，请更换',
            duration: 5
          })
          form.setFieldsValue({ username: '' });
        }
      }
    })
  }

  const querySubList = o => {
    history.push(`/admin/agent/agentSubList/${o.id}`)
  }

  const valueOnchange = e => {
    setValue(e.target.value);
  }

  useEffect(() => {
    getAgentList();
    getAgentLevelList();
  }, [getAgentList])

  return (
    <div>
      <div className="top-area">
        <div className="btn-container">
          <Button type="primary" onClick={addNewAgent}>新增代理</Button>
        </div>
        <div className="search-container">
          <Input allowClear style={{ width: '240px', marginRight: '20px' }} placeholder="请输入代理姓名或手机号码" onChange={(e) => valueOnchange(e)} />
          <Button type="primary" onClick={() => getAgentList(value)}>搜索</Button>
        </div>
      </div>
      <Table
        loading={loading}
        columns={columns}
        dataSource={agentList}
        bordered
        pagination={{ pageSize: size, defaultPageSize: size, total: total, showSizeChanger: false, onChange: pageChange, showTotal: total => `总数据：${total} 条` }}
      />
      <Modal visible={visible} title={agentTitle} onOk={submitForm} onCancel={() => setVisible(false)}>
        <Form {...FormLayOut} ref={formRef} form={form}>
          <Form.Item hidden label="id" name="id">
            <Input />
          </Form.Item>
          <Form.Item hidden label="weChatSubscribe" name="weChatSubscribe">
            <Input />
          </Form.Item>
          <Form.Item hidden label="avatar" name="avatar">
            <Input />
          </Form.Item>
          <Form.Item hidden label="nickname" name="nickname">
            <Input />
          </Form.Item>
          <Form.Item hidden label="createdDate" name="createdDate">
            <Input />
          </Form.Item>
          <Form.Item label="邀请人" name="inviterId" rules={[{ required: true, message: '请选择邀请人' }]}>
            <Select>
              <Option key={0} value={0}>总部邀请</Option>
              {
                agentList.map(o => {
                  if (actionType === 'u' && currentAgentId === o.id) {
                    return <Option key={o.id} disabled value={o.id}>{o.username}</Option>
                  } else {
                    return <Option key={o.id} value={o.id}>{o.username}</Option>
                  }
                }
                )
              }
            </Select>
          </Form.Item>
          <Form.Item label="代理手机号" name="phoneNumber" rules={[{ required: true, validator: (rule, value, callback) => {
              let arr = value.split('')
              console.log(arr)
              console.log(!arr[0] === '1')
              if (!value) {
                return callback('请填写代理手机号')
              } else if(arr.length > 0){
                if(arr[0] !== '1') {
                  return callback('手机号码为1开头')
                } else {
                  return callback()
                }
              } else {
                return callback()
              }
            } }]}>
            <Input onBlur={getAgentListByPhone} />
          </Form.Item>
          <Form.Item label="代理姓名" name="username" rules={[{ required: true, message: '请输入代理姓名' }]}>
            <Input onBlur={getAgentListByName} />
          </Form.Item>
          <Form.Item label="代理等级" name="level" rules={[{ required: true, message: '请输入代理等级' }]}>
            <Select>
              {
                agentLevelList.map(o =>
                  <Option key={o.id} value={o.level}>{o.level}({o.name})</Option>
                )
              }
            </Select>
          </Form.Item>
          <Form.Item label="状态" name="status">
            <Radio.Group name="isHomeShow">
              <Radio value={0}>锁定</Radio>
              <Radio value={1}>开启</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="备注" name="remark">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div >
  )
}

export default AgentList;
