import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Table, Button, Modal, Row, Col, Form, Input, Select, Space, Popconfirm, message } from 'antd';
import { queryUserUpgradeRule, saveUserUpgradeRule, deleteRule } from '@/api/agent';
import { FormLayOut } from '@/utils/common';

const AgentRule = () => {

  const [agentRuleList, setAgentRuleList] = useState([]);
  const [visible, setVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState();

  const formRef = useRef();

  const [form] = Form.useForm();

  const columns = [
    {
      title: '规则ID',
      dataIndex: 'id',
      align: 'center',
      width: '120px'
    },
    // {
    //   title: '规则详情',
    //   dataIndex: 'name',
    //   align: 'center'
    // },
    {
      title: '规则类型',
      dataIndex: 'type',
      align: 'center',
      render: type => type === 1 ? '一次性充值' : '累计充值'
    },
    {
      title: '操作',
      align: 'center',
      width: '150px',
      render: o =>
        <Space size="middle" className="operationCloumn">
          <Button type="primary" size="small" onClick={() => editRule(o)}>编辑</Button>
          <Popconfirm title="确认删除?" onConfirm={() => delRule(o)}>
            <Button type="primary" danger size="small">删除</Button>
          </Popconfirm>
        </Space>

    }
  ]

  const getAgentRuleList = useCallback(() => {
    queryUserUpgradeRule().then(res => {
      if (res.code === 0) {
        let data = res.data;
        data.forEach(o => o.key = o.id);
        setAgentRuleList(data);
      }
    })
  }, [])

  const addAgentRUle = () => {
    if (localStorage.getItem('roleId') !== '1') {
      message.warning({
        content: '您没有权限',
        duration: 5
      })
      return;
    }
    setModalTitle('新增代理规则');
    form.setFieldsValue({ id: '', name: '', type: '' });
    setVisible(true);
  }

  const submitRule = () => {
    let data = formRef.current.getFieldsValue();
    if (data.type === 1) {
      data.name = '一次性充值';
    } else {
      data.name = '累计充值';
    }
    saveUserUpgradeRule(data).then(res => {
      if (res.code === 0) {
        getAgentRuleList();
        setVisible(false);
      } else {
        message.warning({
          content: '请更换规则类型后添加',
          duration: 5
        })
      }
    })
  }

  const editRule = o => {
    if (localStorage.getItem('roleId') !== '1') {
      message.warning({
        content: '您没有权限',
        duration: 5
      })
      return;
    }
    setModalTitle('编辑代理规则');
    form.setFieldsValue(o);
    setVisible(true);
  }

  const delRule = o => {
    if (localStorage.getItem('roleId') !== '1') {
      message.warning({
        content: '您没有权限',
        duration: 5
      })
      return;
    }
    deleteRule({ id: o.id }).then(res => {
      if (res.code === 0) {
        getAgentRuleList();
        message.success({
          content: '删除成功',
          duration: 5
        })
      }
    })
  }

  useEffect(() => {
    getAgentRuleList();
  }, [getAgentRuleList])

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col span={6}><Button type="primary" disabled={agentRuleList.length === 2 ? true : false} onClick={addAgentRUle}>新建规则</Button></Col>
      </Row>
      <Table
        columns={columns}
        dataSource={agentRuleList}
        bordered
        pagination={false}
      />
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={() => submitRule()}
        title={modalTitle}
      >
        <Form {...FormLayOut} ref={formRef} form={form}>
          <Form.Item hidden label="id" name="id">
            <Input />
          </Form.Item>
          {/* <Form.Item label="规则详情" name="name">
            <Input.TextArea />
          </Form.Item> */}
          <Form.Item label="规则类型" name="type">
            <Select name="type" disabled={modalTitle === '编辑代理规则' ? true : false}>
              <Select.Option value={1} disabled={agentRuleList.map(o => o.type).indexOf(1) === -1 ? false : true}>一次性充值</Select.Option>
              <Select.Option value={2} disabled={agentRuleList.map(o => o.type).indexOf(2) === -1 ? false : true}>累计充值</Select.Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default AgentRule;