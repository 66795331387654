import request from '@/utils/manageRequest'

export const queryAgentLevelList = (data) => {
  return request({
    url: 'userLevel/list',
    method: 'get',
    params: data
  })
}

export const saveAgentLevel = (data) => {
  return request({
    url: 'userLevel/save',
    method: 'post',
    data
  })
}

export const delAgentLevel = (data) => {
  return request({
    url: 'userLevel/delete',
    method: 'post',
    params: data
  })
}

export const saveAgentLevelUpgradeRule = (data) => {
  return request({
    url: 'userLevel/addUpgradeRule',
    method: 'post',
    params: data
  })
}

export const delAgentLevelUpgradeRule = (data) => {
  return request({
    url: 'userLevel/deleteUpgradeRule',
    method: 'post',
    data
  })
}

export const changeAgentListStatus = (data) => {
  return request({
    url: 'user/changeStatus',
    method: 'post',
    params: data
  })
}

export const saveAgent = (data) => {
  return request({
    url: 'user/save',
    method: 'post',
    data
  })
}

export const delAgent = (data) => {
  return request({
    url: 'user/delete',
    method: 'post',
    params: data
  })
}

export const getVertifyCode = (data) => {
  return request({
    url: 'user/getCode',
    method: 'post',
    params: data
  })
}

export const queryUserUpgradeRule = () => {
  return request({
    url: 'userUpgradeRule',
    method: 'get'
  })
}

export const saveUserUpgradeRule = (data) => {
  return request({
    url: 'userUpgradeRule/super/save',
    method: 'post',
    data
  })
}

export const deleteRule = (data) => {
  return request({
    url: `userUpgradeRule/super/delete?id=${data.id}`,
    method: 'post'
  })
}

export const queryAgentLevelParm = (data) => {
  return request({
    url: 'userLevel/findParamByLevel',
    method: 'get',
    params: data
  })
}

export const queryRebateParm = data => {
  return request({
    url: 'rebate',
    method: 'get',
    params: data
  })
}

export const saveRebateRule = data => {
  return request({
    url: 'rebate/save',
    method: 'post',
    data
  })
}

export const queryListBylevel = data =>
  request({
    url:'user/findListByLevel?levelList=1&levelList=2',
    method:'get'
  })