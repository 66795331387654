import request from '@/utils/manageRequest'

export const queryAccountList = (data) => {
  return request({
    url: 'userFinance/balanceDetail',
    method: 'get',
    params: data
  })
}

export const updateBalance = (data) => {
  return request({
    url: `userFinance/adjustBalance?id=${data.id}&type=${data.type}&money=${data.money}&userId=${data.userId}&images=${data.images}`,
    method: 'post'
  })
}

export const updatePoint = (data) => {
  return request({
    url: 'userFinance/adjustPoint',
    method: 'post',
    params: data
  })
}

export const queryPersonBalance = (data) => {
  return request({
    url: 'userFinance/personalBalanceDetail',
    method: 'get',
    params: data
  })
}

export const queryPersonPoint = (data) => {
  return request({
    url: 'userFinance/personalPointDetail',
    method: 'get',
    params: data
  })
}

export const withdraw = (data) => {
  return request({
    url:`userFinance/withdraw?money=${data.money}&remark=${data.remark}&userId=${data.userId}&images=${data.images}`,
    method: 'post'
  })
} 