import React from "react";
import { connect } from 'react-redux';
import { Button, Result } from "antd";

class Exception500 extends React.Component {

  goBackHome = () => {
    this.props.history.push('/admin/dashboard')
  }

  render() {
    return (
      <Result
        status="500"
        title="500"
        subTitle="Sorry, something went wrong."
        extra={<Button type="primary" onClick={this.goBackHome}>Back Home</Button>}
      />
    );
  }
}

export default connect()(Exception500);
