import React, { Component } from 'react'
// import { Link } from 'react-router-dom';
import { Breadcrumb } from 'antd'
// import { UserOutlined } from '@ant-design/icons';
// import Icon from '@ant-design/icons';
// import { handleJoinPath, filterLayout, getAllRoute } from '@/router'


class BreadcrumbCustom extends Component {

  constructor() {
    super()
    this.state = {
      routers: {}
    }
  }

  componentDidMount() {

  }  

  render () {

    return (
      <div className="customer-bread-curmb">
        <Breadcrumb>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>An Application</Breadcrumb.Item>
        </Breadcrumb>
      </div>
    )
  }
}

export default BreadcrumbCustom