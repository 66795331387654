import React, { useState, useEffect, useCallback } from 'react';
// import { queryAccountList } from '@/api/finance';
import { queryUserList } from '@/api/user';
import { Table, Space, Button } from 'antd';
import { useHistory } from 'react-router-dom';

const PersonAccount = () => {

  let history = useHistory();
  const [page, setPage] = useState(0);
  const [size] = useState(20);
  const [balanceList, setBalanceList] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const toBalanceDetail = (o) => {
    history.push(`/admin/finance/balanceDetail/${o.id}`);
  }

  // const toPointDetail = (o) => {
  //   history.push('/admin/finance/pointDetail/' + o.id, { query: o.id });
  // }

  const columns = [
    {
      title: '个人信息',
      align: 'center',
      width: '200px',
      render: o => <div><div>{o.username}</div><div>{o.phoneNumber}</div><div>{o.createdDate}</div></div>
    },
    {
      title: '等级信息',
      align: 'center',
      width: '180px',
      render: o =>
        <div>
          <div>代理等级：{o.level}</div>
          <div>代理名称：{o.levelName}</div>
        </div>
    },
    {
      title: '邀请人',
      dataIndex: 'inviterName',
      align: 'center',
      width: '150px'
    },
    {
      title: '数据',
      align: 'center',
      width: '180px',
      render: o => <div><div>当前金额：{o.balance}</div><div>订单业绩：{o.achievement}</div><div>可提现金额：{o.rebate}</div></div>
    },
    {
      title: '操作',
      width: '250px',
      align: 'center',
      render: (o) => {
        return (
          <Space size="middle" className="operationCloumn">
            <Button type="primary" size="small" onClick={() => toBalanceDetail(o)}>收支明细</Button>
            {/* <Button type="primary" size="small" danger onClick={() => toPointDetail(o)}>积分明细</Button> */}
          </Space >
        )
      }
    }
  ]

  const getBalanceList = useCallback(() => {
    setLoading(true);
    queryUserList({ page: page, size: size, id: '', value: '' }).then(res => {
      if (res.code === 0) {
        setTotal(res.data.totalElements);
        let data = res.data.content;
        data.forEach(o => o.key = o.id);
        setLoading(false);
        setBalanceList(data);
        setTotal(res.data.totalElements);
      }
    })
  }, [page, size])

  const pageChange = (page) => {
    setPage(page - 1);
  }

  useEffect(() => {
    getBalanceList();
  }, [getBalanceList])

  return (
    <div>
      <Table
        loading={loading}
        columns={columns}
        dataSource={balanceList}
        bordered
        pagination={{ pageSize: size, defaultPageSize: size, total: total, onChange: pageChange, showSizeChanger: false, showTotal: total => `总数据：${total} 条` }}
      />
    </div>
  )

}

export default PersonAccount;