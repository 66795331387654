import React, { Component } from "react";

class Access extends Component {
  render() {
    return (
      <div>
        <div>权限测试页， 只有供应商能看到此页面</div>
      </div>
    );
  }
}

export default Access;
