import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Row, Col, Button, Table, Modal, Form, Input, Upload, Space, Popconfirm, Radio, message } from 'antd';
import { FormLayOut } from '@/utils/common';
import styles from '@/styles/pages/product.module.scss';
import MaterialLibrary from '@/components/MaterialLibrary';
import { oss } from '@/utils/publicPath';
import { queryCarouselList, saveCarousel, deleteCarousel } from '@/api/product';

const Carousel = () => {

  const [page, setPage] = useState(0);
  const [size] = useState(20);
  const [total, setTotal] = useState(0);
  const [bannerList, setBannerList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [opType, setOpType] = useState();

  const formRef = useRef();
  const [form] = Form.useForm();

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      align: 'center',
      width: '80px'
    },
    {
      title: '图片',
      dataIndex: 'image',
      align: 'center',
      width: '150px',
      render: value =>
        <div className={styles['dispaly-flex']}>

          {value ?
            <div className={styles['logoContainer']}>
              <img alt="example" src={oss + value} />
            </div>
            :
            <div className={styles['imageContainer']}>
              <img alt="" src={oss + 'material/on_error.png'} />
            </div>
          }
        </div>
    },
    {
      title: '排序',
      dataIndex: 'rank',
      align: 'center',
      width: '100px'
    },
    {
      title: '状态',
      dataIndex: 'status',
      align: 'center',
      width: '100px',
      render: value => value === 1 ? '显示' : '隐藏'
    }, {
      title: '操作',
      align: 'center',
      width: '200px',
      render: o =>
        < Space size="middle" className="operationCloumn" >
          <Button type="primary" size="small" onClick={() => editCarOursel(o)}>编辑</Button>
          <Popconfirm title="确认删除?" onConfirm={() => delCaroursel(o)}>
            <Button type="primary" danger size="small">删除</Button>
          </Popconfirm>
        </Space >
    }
  ];

  const pageChange = (page) => {
    setPage(page - 1);
  }

  const addCarOursel = () => {
    form.setFieldsValue({ id: '', rank: 0, status: 1 });
    setImageList([]);
    setOpType('新增');
    setVisible(true);
  }

  const editCarOursel = o => {
    form.setFieldsValue(o);
    setImageList([{ uid: 0, url: oss + o.image, filename: o.image }]);
    setOpType('修改');
    setVisible(true);
  }

  const delCaroursel = o => {
    console.log(o);
    deleteCarousel({ id: o.id }).then(res => {
      if (res.code === 0) {
        getCarouselList();
      }
    })
  }

  const submitCarousel = () => {
    let data = formRef.current.getFieldsValue();
    if (imageList && imageList.length !== 0) {
      data.image = imageList[0].filename;
      saveCarousel(data).then(res => {
        if (res.code === 0) {
          getCarouselList();
          setVisible(false);
        }
      })
    } else {
      message.error({
        content: '请选择图片',
        duration: 5
      })
    }

  }

  const imageListOnRemove = file => {
    let imageLists = [...imageList];
    let index = 0;
    imageLists.forEach((o, i) => {
      if (o.uid === file.uid) {
        index = i;
      }
    })
    imageLists.splice(index, 1);
    setImageList(imageLists);
  }

  const setImageLists = values => {
    let imageLists = [...imageList];
    let data = {};
    values.forEach((o, i) => {
      data = {}
      data.uid = o.id;
      data.url = oss + o.url;
      data.filename = o.url;
      imageLists.push(data);
    })
    setImageList(imageLists);
  }

  const emptyAssignZero = e => {
    e.persist();
    if (!e.target.value) {
      form.setFieldsValue({ rank: 0 });
    }
  }

  const getCarouselList = useCallback(() => {
    setLoading(true);
    queryCarouselList({ page: page, size: size }).then(res => {
      if (res.code === 0) {
        let data = res.data.content;
        setTotal(res.data.totalElements);
        data.forEach(o => o.key = o.id);
        setBannerList(data);
        setLoading(false);
      }
    })
  }, [page, size])

  useEffect(() => {
    getCarouselList();
  }, [getCarouselList])



  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col span={6}><Button type="primary" onClick={addCarOursel}>新增轮播图</Button></Col>
      </Row>
      <Table
        columns={columns}
        dataSource={bannerList}
        bordered
        loading={loading}
        pagination={{
          pageSize: size,
          defaultPageSize: size,
          total: total,
          onChange: pageChange,
          showSizeChanger: false,
          showTotal: total => `总数据：${total} 条`
        }}
      />
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={submitCarousel}
        title={opType + "轮播图"}
      >
        <Form {...FormLayOut} ref={formRef} form={form}>
          <Form.Item hidden label="id" name="id">
            <Input />
          </Form.Item>
          <Form.Item label="图片">
            <div className={styles['display-flex-align-left']}>
              <div style={{ width: imageList.length * 112 + 'px' }}>
                {
                  imageList && imageList.length !== 0 ?
                    <Upload
                      listType="picture-card"
                      fileList={imageList}
                      onRemove={imageListOnRemove}
                    ></Upload>
                    :
                    ''
                }
              </div>
              {
                imageList && imageList.length < 1 ?
                  <div style={{ width: '104px', marginLeft: '0px', marginBottom: '10px' }} className={imageList && imageList.length !== 0 ? styles['materialContainer'] : ''}>
                    <MaterialLibrary maxChooseNum={4 - imageList.length} setImages={setImageLists} />
                  </div>
                  :
                  ''
              }
            </div>
            <span style={{ color: '#bbb', fontSize: '12px' }}>提示：上传图片大小不超过3MB，支持jpg/jpeg/png格式，建议尺寸：750*240,3:1亦可</span>
          </Form.Item>
          <Form.Item name="rank" label="排序">
            <Input onBlur={emptyAssignZero} />
          </Form.Item>
          <Form.Item label="状态" name="status">
            <Radio.Group name="status">
              <Radio value={0}>隐藏</Radio>
              <Radio value={1}>显示</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default Carousel;