import React, { useState, useCallback, useEffect, useRef } from 'react';
import { queryExpressCompanyList, queryExpressConpanyDetail, saveExpressCompany, updateExpressCompanyStatus } from '@/api/order';
import { Table, Space, Button, Modal, Form, Input, Radio, Popconfirm } from 'antd';
import { FormLayOut } from '@/utils/common';
import styles from '@/styles/pages/category.module.scss';
import { oss } from '@/utils/publicPath';

const Logistics = () => {

  const formRef = useRef();

  const [form] = Form.useForm();

  const [expressList, setExpressList] = useState([]);
  const [visible, setVisible] = useState(false)

  const columns = [
    {
      title: '快递公司名称',
      dataIndex: 'name',
      width: '150px',
      align: 'center'
    },
    {
      title: '快递公司code',
      dataIndex: 'code',
      width: '150px',
      align: 'center'
    },
    {
      title: '快递公司简介',
      dataIndex: 'sub',
      width: '350px',
      align: 'center'
    },
    {
      title: '快递公司状态',
      width: '150px',
      align: 'center',
      render: o =>
        <Popconfirm title="确认修改状态?" onConfirm={() => changeStatus(o)}>
          <Button size="small" className={o.status === 1 ? styles['hideStatus'] : styles['showStatus']}>
            {o.status === 1 ? '开启' : '关闭'}
          </Button>
        </Popconfirm >
    },
    {
      title: '快递公司类型',
      dataIndex: 'type',
      width: '150px',
      align: 'center',
      render: type => type === 1 ? '物流' : '快递'
    },
    {
      title: '操作',
      align: 'center',
      width: '100px',
      render: o =>
        <Space size="middle" className="operationCloumn">
          <Button type="primary" size="small" onClick={() => { queryExpressCompanyDetail(o) }}>编辑</Button>
        </Space>
    }
  ]

  const getExpressList = useCallback(() => {
    queryExpressCompanyList().then(res => {
      if (res.code === 0) {
        let data = res.data;
        data.forEach(o => o.key = o.id);
        setExpressList(data)
      }
    })
  }, []);

  const queryExpressCompanyDetail = (o) => {
    queryExpressConpanyDetail({ id: o.id }).then(res => {
      if (res.code === 0) {
        form.setFieldsValue(res.data);
        setVisible(true);
      }
    })
  }

  const submitExpressInfo = () => {
    let data = formRef.current.getFieldsValue();
    form.validateFields().then(result => {
      saveExpressCompany(data).then(res => {
        if (res.code === 0) {
          getExpressList();
          setVisible(false);
        }
      })
    }).catch();
  }

  const addExpressCompany = () => {
    form.resetFields(['code', 'name']);
    form.setFieldsValue({ id: '', code: '', name: '', sub: '', status: 1, type: 2 });
    setVisible(true);
  }

  const changeStatus = (o) => {
    let status = 0;
    if (o.status === 0) {
      status = 1;
    } else {
      status = 0;
    }
    updateExpressCompanyStatus({ id: o.id, status: status }).then(res => {
      if (res.code === 0) {
        getExpressList();
      }
    })
  }

  useEffect(() => {
    getExpressList()
  }, [getExpressList]);

  return (
    <div>
      <div className="top-area">
        <div className="btn-container">
          <Button type="primary" onClick={() => addExpressCompany()}>新增快递公司</Button>
          <a href={oss + "expressFile/expressCode.xlsx"}><Button style={{ marginLeft: '20px' }} type="primary">下载code参考表</Button></a>
        </div>
      </div>
      <Table columns={columns} dataSource={expressList} bordered pagination={false} />
      <Modal
        visible={visible}
        title="快递公司信息"
        onCancel={() => setVisible(false)}
        onOk={() => submitExpressInfo()}
        width="600px"
      >
        <Form {...FormLayOut} ref={formRef} form={form}>
          <Form.Item hidden label="id" name="id">
            <Input />
          </Form.Item>
          <Form.Item name="name" label="快递公司名称" rules={[{ required: true, message: '请输入快递公司名称' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="code" label="快递公司code" rules={[{ required: true, message: '请输入快递公司code' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="sub" label="快递公司简介">
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item name="status" label="快递公司状态">
            <Radio.Group name="status">
              <Radio value={0}>关闭</Radio>
              <Radio value={1}>开启</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item name="type" label="快递公司类型">
            <Radio.Group name="type">
              <Radio value={1}>物流</Radio>
              <Radio value={2}>快递</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default Logistics;