import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom'
import Login from '@/views/login/Login'
import { AdminLayout, Exception404 } from '@/components'
import MaterialLibraryPro from '@/components/MaterialLibraryPro';


const App = () => (
  <Switch>
    <Route exact path="/" render={() => <Redirect to="/admin/dashboard" push />} />
    <Route path='/admin' component={AdminLayout} />
    <Route path="/login" component={Login} exact />
    <Route path="/404" component={Exception404} />
    <Route path="/test" component={MaterialLibraryPro} />
    <Route component={Exception404} exact />
  </Switch>
);

export default App;