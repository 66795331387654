import { manageRequestApi } from '@/utils/publicPath';
import axios from "axios";
import store from '@/Store';

export const toExcel = (reqUrl, fileName) => {
  let url = manageRequestApi + reqUrl;
  let token = '';
  if (store.getState().app.token) {
    token = store.getState().app.tokenHead + store.getState().app.token
  }
  axios({
    url: url,
    method: 'GET',
    headers: {
      'Authorization': token
    },
    'responseType': 'blob'
  }).then(res => {
    const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
    //下载文件的名称
    let filename = fileName;
    let downloadElememt = document.createElement('a');
    let href = '';
    if (window.URL) {
      href = window.URL.createObjectURL(blob);
    } else {
      href = window.webkitURL.createObjectURL(blob);
    }
    downloadElememt.href = href;
    downloadElememt.download = filename;
    document.body.appendChild(downloadElememt);
    downloadElememt.click();
    if (window.URL) {
      window.URL.revokeObjectURL(href);
    } else {
      window.webkitURL.revokeObjectURL(href);
    }
  })
}